import React from "react";
import Style from "./Style.module.css";
import moment from "moment";

function CommentCard({ comment, date, user }) {
  return (
    <div className={Style.commentCard}>
      {/* gold horizontal line */}
      <img
        alt="gold horizontal line"
        src={require("../../../assets/Reply ligne.png")}
      />

      {/* card */}
      <div className={Style.card}>
        {/* profile photo */}

        <img
          className={Style.profile}
          id={Style["profile_card"]}
          alt="profile"
          src={user ? user.photo : ""}
          style={{
            position: "relative",
            zIndex: "1",
            marginRight: "85.5%",
            borderRadius: "50%",

            height: "37px",
          }}
        />

        {/* card body */}
        <div className={Style.cardBody}>
          <div className="prayerWallAuthorDate">
            <p
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "18px",
                letterSpacing: "0.05em",
                color: "#1c3d47",
              }}
            >
              {user ? user.firstname + " " + user.lastname : ""}
            </p>
            <p
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "9px",
                lineHeight: "14px",
                letterSpacing: " 0.05em",
                color: " #b78400",
                marginLeft: "1%",
              }}
            >
              {moment(date).fromNow()}
            </p>
          </div>
          {/* the comment */}
          <p>{comment}</p>
        </div>
      </div>
    </div>
  );
}

export default CommentCard;
