import React from 'react';
import Header from './header/Header';
import Body from './body/Body';

function DailyDevotion() {
  return (
    <>
      <Header />
      <Body />
    </>
  );
}

export default DailyDevotion;
