import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import yellowRectange from "../../assets/images/yellow-text-decoration.png";
import axios from "axios";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";

const LatestStories = () => {
  const [stories, setStories] = useState([]);
  useEffect(() => {
    async function getData() {
      try {
        const res = await axios.get("/api/story/top");
        if (res.status === 200) {
          setStories(res.data);
        }
      } catch (error) {
        //console.log(error);
      }
    }
    getData();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        //bgcolor: "red",
        // maxWidth: "99vw",
        // overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: { md: "1vh", xs: 0 },
        }}
      >
        <Divider />
        <TitleText sx={{ fontSize: { md: "25px", sm: "20px", xs: "18px" } }}>
          LATEST STORIES
        </TitleText>
        <Divider />
      </Box>
      {/* <img
        style={{ width: "100%", height: "auto" }}
        alt="onorio"
        src="https://storage.googleapis.com/public_ocm/cover-best-stories-banner.png"
      /> */}
      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundImage: `url(https://storage.googleapis.com/public_ocm/cover-best-stories-banner.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "3vw",
            marginLeft: "3vw",
          }}
        >
          <img
            src={yellowRectange}
            style={{ marginLeft: "10px" }}
            alt="Onorio Cutane Ministries"
          />
          <HeaderText
            sx={{
              color: "#000000",
              fontSize: { md: "28px", sm: "22px", xs: "18px" },
            }}
          >
            BEST STORIES
          </HeaderText>
        </Box>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            //justifyContent: "center",
            //alignItems: "center",
            //width: "80vw",
            alignSelf: "center",
          }}
          container
        >
          {stories.map((story, index) => {
            return (
              // <OneCard
              //   cardImage={event.photo}
              //   cardTitle={event.title}
              //   cardDescription={event.description}
              //   cardId={event._id}
              // />
              <Grid
                // xl={2}
                // lg={3}
                md={4}
                sm={12}
                xs={12}
                item
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    //justifyContent: "space-evenly",
                    flexShrink: 1,
                    margin: "2vw",
                    padding: "5%",
                    color: "#926a05",
                    justifyContent: "space-between",
                    height: { lg: "220px", md: "270px", sm: "" },
                    //width: "",
                  }}
                >
                  <ContentText
                    sx={{
                      fontSize: { md: "10px", sm: "9px", xs: "8px" },
                      color: "#000000",
                    }}
                  >
                    {story.content}
                  </ContentText>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#926a05",
                        fontSize: "14px",
                        justifyItems: "center",
                      }}
                    >
                      {story.nbLikes + " "}
                      <ThumbUpRoundedIcon
                        sx={{ fontSize: "16px", marginTop: "-5px" }}
                      />
                    </Box>
                    <ContentText sx={{ paddingBottom: 0, color: "#926a05" }}>
                      {story && story.user
                        ? story.user.firstname + " " + story.user.lastname
                        : ""}
                    </ContentText>
                  </Box>
                </Box>
                {index < stories.length - 1 ? (
                  <DividerColumn
                    sx={{
                      width: { md: "1px", xs: "60vw" },
                      height: { md: "150px", xs: "0px" },
                    }}
                  />
                ) : null}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
const TitleText = styled(Typography)({
  color: "#926A05",
  fontWeight: "500",
  fontSize: "25px",
  margin: "20px",
  textAlign: "center",
  padding: "2% 0%",
});
const HeaderText = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "600",
  fontSize: "28px",
  textAlign: "left",
  lineHeight: "25px",
  padding: "0% 2%",
});
const ContentText = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "500",
  fontSize: "12px",
  textAlign: "left",
  paddingBottom: "5%",
  textTransform: "capitalize",
});
const Divider = styled(Box)({
  backgroundColor: "#293B56",
  width: "90px",
  height: "1px",
});
const DividerColumn = styled(Box)({
  backgroundColor: "#CDCDCD",
  width: "1px",
  height: "150px",
});
export default LatestStories;
