import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";

import OneYellow from "../../../assets/icons/1-yellow.png";
import TwoYellow from "../../../assets/icons/2-yellow.png";
import ThreeYellow from "../../../assets/icons/3-yellow.png";
import GoalCard from "../GoalCard";

const SecondTab = () => {
  return (
    <RootContainer>
      <Container
        sx={{
          flexDirection: { md: "row", xs: "column" },
          padding: "5%",
        }}
      >
        <Row
          sx={{
            width: {
              md: "40vw",
              xs: "90vw",
            },
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "5vw",
            marginRight: "2vw",
          }}
        >
          <DividerVertical
            sx={{
              height: {
                xl: "26vh",
                lg: "31vh",
                md: "48vh",
                sm: "25vh",
                xs: "45vh",
              },
            }}
          />
          <Column sx={{}}>
            <HeaderText2 sx={{ textAlign: "left" }}>About</HeaderText2>
            <HeaderText sx={{ textAlign: "left" }}>Janifer Cutane</HeaderText>
            <Text>
              Janifer Cutane is a Mozambican gospel artist, born in 1988. She is
              a pastor, a worshipper, and an intercessor. Moreover, she is a
              women’s leader, marriage counselor, and life coach. She is an
              international solo gospel artist, songwriter, and TV Host. In
              2020, she launched her first, touching album titled “Meu Coração,”
              the Portuguese expression for “My Heart.” An album comprising 13
              songs both in audio CD and in Videography.
            </Text>
            <Text>
              Quoting her words, she said, “My late elder sister (who was also
              very gifted in singing) and I would write the songs for the praise
              team of the church in which we used to fellowship. Moreover,
              during most of the gospel concerts in which we would be invited to
              sing, I was chosen as the voice-lead, and whenever I would start
              singing, everyone would be moved by the song. That’s precisely the
              time when I was sure about this gift, which was already in me,
            </Text>
          </Column>
        </Row>
        <Box
          sx={{
            marginRight: { md: "5vw", xs: 0 },
            //marginTop: { md: 0, xs: "1vh" },
            //marginBottom: { md: 0, xs: "1vh" },
            backgroundImage: `url("../../assets/cover_community.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            width: { xs: "90vw", sm: "80vw", md: "50vw", lg: "40vw" },
            height: { xs: "50vh", sm: "45vw", md: "30vw", lg: "25vw" },
          }}
        />
        {/* <Box
          sx={{
            marginRight: { md: "5vw", xs: 0 },
            marginTop: { md: 0, xs: "2vh" },
            //marginBottom: { md: 0, xs: "2vh" },
          }}
        >
          <img
            src="https://storage.googleapis.com/public_ocm/cover-about-page-3.png" 
                       style={{ width: "auto", height: "50vh" }}
          />
        </Box> */}
      </Container>
      <Container
        sx={{
          alignSelf: { md: "flex-end", xs: "flex-start" },
          flexDirection: "row",
          width: { md: "50vw", xs: "90vw" },
          //paddingBottom: "5% 2%",
          padding: "0 5%",
        }}
      >
        <DividerVerticalGrey
          sx={{
            height: {
              xl: "6vh",
              lg: "7vh",
              md: "10vh",
              sm: "8vh",
              xs: "12vh",
            },
          }}
        />
        <Text>
          She is married and is a mother to two beautiful kids. In her free
          time, she likes reading and writing songs. Her passion is helping
          people develop their God-given talents and their knowledge of Jesus
          Christ. She started singing in her teenage years.
        </Text>
      </Container>

      <Container
        sx={{
          flexDirection: "column",
          padding: "5%",
        }}
      >
        <Row sx={{ alignItems: "center" }}>
          <DividerHorizontalGrey />
          <HeaderText2>Goals</HeaderText2>
          <DividerHorizontalGrey />
        </Row>
        <HeaderText>Future Goals</HeaderText>
        <Row sx={{ alignItems: "center", marginTop: "2%" }} container>
          <GoalCard
            iconSource={OneYellow}
            title="Goals"
            content={
              "When asked about her future goals and dreams, here are Pastor Janifer’s words, “My greatest dream is to make Jesus Christ known to the people of the world through music. I want to see people’s lives being transformed and heal the heart and souls of humanity through my God-given anointed worship and praise to the Lord."
            }
          />
          <GoalCard
            iconSource={TwoYellow}
            title="Goals"
            content={
              "My dream that whenever my worship and music are sung, the glory of the Lord be made manifest, and healings and miracles begin to take place in people’s lives. Music is a spiritual medicine to the human soul and whenever praises go up, God’s glory comes down."
            }
          />
          <GoalCard
            iconSource={ThreeYellow}
            title="Goals"
            content={
              "So, in essence, I desire to help fill the earth with the knowledge of the glory of God through music and give honor and praise to the Lord for the grace he has given to me,"
            }
          />
        </Row>
      </Container>
    </RootContainer>
  );
};

export default SecondTab;

const Column = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});
const RootContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  // width: "100vw",
  // height: "50vh",
});
const Container = styled(Grid)({
  justifyContent: "space-between",
  alignItems: "center",
  //padding: "5%",
  display: "flex",
});
const Row = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});
const HeaderText2 = styled(Typography)({
  color: "#B78400",
  fontWeight: "500",
  fontSize: "16px",
  textAlign: "center",
  whiteSpace: "nowrap",
});
const HeaderText = styled(Typography)({
  color: "#1C3D47",
  fontWeight: "500",
  fontSize: "22px",
  lineHeight: "24px",
  textAlign: "center",
  padding: "1% 0",
});
const Text = styled(Typography)({
  color: "#6C6C6C",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "23px",
  textAlign: "left",
  padding: "1% 0%",
});

const DividerVertical = styled(Box)({
  backgroundColor: "#B78400",
  height: "30vh",
  marginRight: "4%",
  width: "10px",
  marginTop: "2vh",
});

const DividerVerticalGrey = styled(Box)({
  backgroundColor: "#1C3D47",
  height: "7vh",
  marginRight: "4%",
  width: "2px",
});

const DividerHorizontalGrey = styled(Box)({
  backgroundColor: "#1C3D47",
  width: "5vw",
  marginRight: "4%",
  marginLeft: "4%",
  height: "1px",
});
