import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Style from "./Style.module.css";

function Article3({ title, content, img }) {
  return (
    <>
      <Container
        style={{
          textAlign: "left",
          verticalAlign: "middle",
          marginBottom: "10%",
        }}
      >
        <Row>
          <Col>
            <div className={Style.articleOneDecOration}>
              <img className={Style.articleThreeImg} src={img} alt='article 3' />
            </div>
          </Col>
          <Col>
            <Row>
              <p className={Style.articleOneTitle}>{title}</p>
            </Row>
            <Row>
              <p className={Style.articleOneContent}>{content}</p>
            </Row>
            <Button
              variant=" "
              style={{
                backgroundColor: "#B78400",
                fontFamily: "Poppins",
                fontStyle: "normal",
                color: "#ffffff",
              }}
            >
              Read More{" >"}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Article3;
