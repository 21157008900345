import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import axios from "axios";
import moment from "moment";
import ScheduleIcon from "@mui/icons-material/Schedule";


function PostSidebar() {
  const [devotionals, setDevotionals] = useState([]);
  async function getData() {
    try {
      const res = await axios.get("/api/devotionals");
      if (res.status === 200) {
        const loop = res.data.data;

        if (Array.isArray(loop)) {
          const popular = loop.slice(0, 3);
          setDevotionals(popular);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="col-xs-12 col-sm-12 col-md-4">
      <div className="sidebar">
        <div
          className={`col-sm-12 col-xs-12 ${style["sidebar-widget"]} ${style["widget-popular-posts"]}`}
        >
          <div className="row">
            <div
              className={`${style["widget-title"]} ${style["widget-popular-posts-title"]}`}
            >
              <h4 className={`${style["title"]}`}>Related Devotionals</h4>
            </div>
            <div className={`${style["widget-body"]} col-sm-12`}>
              <div className="row">
                <ul
                  className={`${style["widget-list"]} ${style["w-popular-list"]}`}
                >
                  {Array.isArray(devotionals) &&
                    devotionals.map((post) => {
                      return (
                        <li key={post._id}>
                          <div className={`${style["left"]}`}>
                            <a href={`/devotional-detail/${post._id}`}>
                              <img
                                src={post.image}
                                className="img-responsive lazyloaded"
                                alt={post.title}
                                width="100"
                                height="75"
                              />
                            </a>
                          </div>
                          <div className={`${style["right"]}`}>
                            <h3 className={`${style["title"]}`}>
                              <a href={`/devotional-detail/${post._id}`}>
                                <p
                                  className={style["summary"]}
                                  // dangerouslySetInnerHTML={{
                                  //   __html:
                                  //     post.detail.length > 80
                                  //       ? post.detail.substring(0, 80)
                                  //       : post.detail,
                                  // }}
                                >
                                  {post.title}
                                </p>
                              </a>
                            </h3>
                            <div className={`${style["post-meta"]}`}>
                              <p className={`${style["post-meta-inner"]}`}>
                                <a href={`/devotional-detail/tag/${post.tag}`}>
                                  #{post.tag}
                                </a>
                                <span>
                                  <ScheduleIcon style={{ fontSize: "16px" }} />
                                  &nbsp;
                                  {moment(post.createdAt).fromNow()}
                                </span>
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostSidebar;
