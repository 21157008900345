import React from "react";
import Style from "./Style.module.css";

function EventsImage({ img, title, description, id, date }) {
  return (
    <div className={Style.card}>
      <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height: "50vh",
          width: "90vw",
        }}
        className={Style.bgImage}
      >
        {/* text on image */}
        <div className={Style.TextOnImage}>
          <p className={Style.TextOnImageTitle}>{title}</p>
          <p className={Style.TextOnImageDescription}>{date}</p>
        </div>
      </div>
      {/* text under image */}
      <div className={Style.underImage}>
        <p className={Style.TextUnderImage}>{description}</p>
        {/* <Link to={"/booking"} style={{ textDecoration: "none" }}>
              <Button
                onClick={() => {
                  window.localStorage.setItem("event", id);
                }}
                variant="outline"
                className={Style.underButton}
              >
                Book now
                <img alt="go" src={require("../../../assets/go.png")} />
              </Button>
            </Link>  */}
      </div>
    </div>
  );
}

export default EventsImage;
