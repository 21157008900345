import React, { useEffect, useState } from "react";
import axios from "axios";
import Style from "./Style.module.css";
import Style2 from "../../../components/dailyDevotion/style.module.css";
import PostItem from "../../../components/dailyDevotion/PostItem";
import PostSidebar from "../../../components/dailyDevotion/PostSidebar";

function Body() {
  const [devotionals, setDevotionals] = useState([]);
  const [latest, setLatest] = useState([]);
  const [today, setToday] = useState([]);

  async function getData() {
    try {
      const res = await axios.get("/api/devotionals");
      if (res.status === 200) {
        setDevotionals(res.data.data);
      }
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const buttonStyle = {
    display: "block",
    margin: "0 auto",
    textAlign: "center",
    backgroundColor: "#293b56",
    color: "white",
    padding: "5px 10px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease"
  };

  return (
    <div className={Style.body}>
      <section id="main" className={Style2["margin-top-50"]}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8">
              <div className={Style2["content"]}>
                <div className={`col-xs-12 col-sm-12 ${Style2["post"]}`}>
                  <div className="row">
                    {Array.isArray(devotionals) &&
                      devotionals.map((item) => {
                        return <PostItem post={item} key={item._id} />;
                      })}
                  </div>
                </div>
              </div>
            </div>
            <PostSidebar title={"Devotional Today"} props={today} />
          </div>

          <div className="col-xs-4 col-sm-4 col-md-4" style={buttonStyle}>
                <a href="/daily-devotion/all" style={buttonStyle}>View All</a>
              </div>
        </div>
      </section>
    </div>
  );
}

export default Body;
