import React, { useEffect, useState } from "react";
import axios from "axios";
import Style from "./style.module.css";
import SaveModal from "./SaveModal";
import EditIcon from "@mui/icons-material/Edit";


function Church({ user, onSave }) {
  const [churchs, setChurchs] = useState([]);
  const [userData, setUserData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    getChurchs();
  }, []);

  useEffect(() => {
    if (!!user) {
      setUserData(user);
      //console.log("user", user);
    }
  }, [user]);

  const getChurchs = async () => {
    try {
      const res = await axios.get("/api/locations");
      setChurchs(res.data.data);
      //console.log("church", res.data.data);
    } catch (error) {
      //console.log("error", error);
    }
  };

  const findChurch = () => {
    const church = churchs.find((church) => church._id === userData.church);
    return church ? church.church_name : "";
  };

  const handleChange = ({ e, state }) => {
    return setUserData((userData) => {
      return { ...userData, [state]: e.target.selectedOptions[0].value };
    });
  };

  const handleChangeInput = ({ e, state }) => {
    return setUserData((userData) => {
      return { ...userData, [state]: e.target.value };
    });
  };

  const handleSave = () => {
    onSave(userData);
    setShow(!show);
  };

  return (
    <div id={Style["form_body_02"]}>
      <div className={Style.form_number}>
        <div>
          <p className={Style.form_number_container}>02</p>
          <div id={Style["form_border_02"]} />
        </div>
      </div>
      <div>
        <p className={Style.form_text}>Church</p>
        <div className={Style.form_container_02}>
          <form className={Style.form_body_container}>
            <section className={Style.form_body_container_section_2}>
              <div className={Style.form_body_container_section_form}>
                <label htmlFor="church" className={Style.form_body_label}>
                  Church :
                </label>
                <select
                  id="church"
                  onChange={(e) => handleChange({ e, state: "church" })}
                  placeholder={userData.church}
                  disabled={!show}
                  className={show ? Style.form_input : Style.form_output}
                  value={findChurch() || ""}
                >
                  <option value="">Church </option>
                  {churchs.map((church) => {
                    return (
                      <option key={church._id} value={church.church_name}>
                        {church.church_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={Style.form_body_container_section_form}>
                <label htmlFor="seniority" className={Style.form_body_label}>
                  Seniority :
                </label>
                <input
                  id="seniority"
                  name="seniority"
                  type="number"
                  onChange={(e) => handleChangeInput({ e, state: "seniority" })}
                  placeholder={userData.seniority}
                  disabled={!show}
                  className={show ? Style.form_input : Style.form_output}
                  style={{ width: "240px" }}
                />
              </div>
            </section>
          </form>
          <div className={Style.form_body_button_container}>
            {!show ? (
              <button
                onClick={() => setShow(true)}
                className={Style.form_body_button}
              >
                Modify
                <EditIcon style={{ marginLeft: "3px" }} />
              </button>
            ) : (
              <SaveModal handleSave={handleSave} setShow={setShow}>
                Save
              </SaveModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Church;
