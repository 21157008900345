import React, { useEffect, useState } from "react";
import Style from "./Style.module.css";
import Card from "../../../components/newsScreen/card";
import axios from "axios";
import moment from "moment";
const apostle = require("../../../assets/apostle.png");
const latestbook = require("../../../assets/latestbook.png");
// const apostle = require("../../../assets/apostle.png");

function Body() {
  const [latest, setLatest] = useState([]);
  async function getData() {
    try {
      const res = await axios.get("/api/news/recent");
      if (res.status === 200) {
        setLatest(res.data);
      }
    } catch (error) {
      //console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  const [pr, setPr] = useState([]);
  async function getPrData() {
    try {
      const res = await axios.get("api/pressrelease");
      if (res.status === 200) {
        setPr(res.data.data);
      }
    } catch (error) {
      //console.log(error);
    }
  }
  useEffect(() => {
    getPrData();
  }, []);

  return (
    <div className={Style.body}>
      <div className={Style.titleContainer}>
        <div className={Style.latestNewsContainer}>
          <p className={Style.latestNews}>Latest News</p>
          <div className={Style.row}>
            <div className={Style.goldDivider} />
            <div className={Style.whiteDivider} />
          </div>
          {<div className={Style.line_2} alt="line"></div>}
        </div>
      </div>
      <div className={Style.latest_cards}>
        <div className={Style.latest_cards_container}>
          {latest &&
            latest.map((l, i) => {
              return (
                <div
                  className={Style.latest_card_container}
                  key={i}
                  id={Style[`latest_card_${i}`]}
                >
                  <div className={Style.latestCard}>
                    <div className={Style.imageBg}>
                      <img
                        alt="news"
                        className={Style.imagelatest}
                        src={l.photo}
                      />
                    </div>
                    <div className={Style.cardTextContainer}>
                      <p className={Style.titleLatest}>{l.title}</p>
                      <p className={Style.dateLatest}>
                        {moment(l.createdAt).format("DD MMMM yyyy")}
                      </p>
                      <p className={Style.contentLatest}>{l.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* <div className={Style.moreNewsContainer}>
        <p className={Style.title}>More News</p>
        <div className={Style.dividerContainer}>
          <div className={Style.dividerGrey} />
          <div className={Style.dividerGold} />
          <div className={Style.dividerGrey} />
        </div>
      </div>

      <div style={{ margin: "5% 5%" }}>
        {pr &&
          pr.map((p, index) => {
            return index % 2 === 0 ? (
              <Card
                gold={true}
                title={p.title}
                img={p.photo}
                content={p.description}
                date={p.createdAt}
              ></Card>
            ) : (
              <Card
                gold={false}
                title={p.title}
                img={p.photo}
                content={p.description}
                date={p.createdAt}
              ></Card>
            );
          })}

        {/* <Card
          gold={true}
          title={""}
          img="https://storage.googleapis.com/public_ocm/batismo-modified.jpg"
          content={
            ""
          }
          date={"09/10/2022"}
        />
      <Card
          gold={true}
          title={""}
          img="https://storage.googleapis.com/public_ocm/BRASIL-modification-now.jpg"
          content={
            ""
          }
          date={"09/10/2022"}
        />
      <Card
          gold={true}
          title={""}
          img="https://storage.googleapis.com/public_ocm/EMPREENDEDORES%20DO%20REINO-modified.jpg"
          content={
            ""
          }
          date={"09/10/2022"}
        />
        <Card
          gold={true}
          title={"O Espírito Santo, o Anjo da presença de Deus"}
          img={require("../../../assets/images/cover-static-news-1.png")}
          content={
            "Deus enviou Moisés para ir libertar o povo de Israel da escravidão no Egipto. Ele não lhe deu armas de fogo, nem munições físicas. Tudo quanto Deus deu a Moisés foi a sua presença. Ele disse-lhe “Certamente, eu serei contigo...” (Êxodo 3:12a).  Oh glória a Deus! Tudo quanto Moisés precisava era simplesmente crer que Deus seria com ele. Moisés creu nessa presença e foi enfrentar Faraó “ Deixa ir o meu povo”. Moisés sabia que Faraó não veria Deus fisicamente, mas que Deus estaria lá com Moisés para apoiá-lo. O Faraó descrito na Bíblia Sagrada é um símbolo do diabo.  Faraó foi muito teimoso, mas quanto mais teimoso ele se fazia, maior o poder de Deus se manifestava para mostrar Faraó que ele não podia prevalecer contra o Deus de Israel."
          }
          date={"02/09/2022"}
        />

        <Card
          gold={false}
          title={"Não Tenha medo"}
          img={require("../../../assets/images/cover-static-news-2.png")}
          content={
            "A vida e às confissões da fé || Como lidar com o medo e a preocupação" +


            "O medo é o maior assassino no mundo." +
            "O medo amplia cenários e situações pequenas." +


            "O medo é sinal de que a vida de Cristo não está em si." +
            "Você só começa a viver quando o medo não mais existir no seu coração." +
            "Onde há medo não há sucesso, pois não há nenhum trabalho não arriscado." +

            "Como se livrar do medo e da preocupação?" +

            "Reconheça a presença e a grandeza do seu Deus;" +

            "Confie na fidelidade de Deus (Lamentações 3:22-24)" +
            "Não esqueça, às misericórdias do Senhor não tem fim." +

            "Descanse na palavra de Deus." +
            "|| Crônicas 32:1-10" +
            "Aprenda a ficar calmo e aquieta-te para ver o livramento do Senhor." +


            "A vida de glória que Deus deu a nós, será fruto da fé, e das confissões de fé." +

            "Hebreus 10:35-36" 
            


          }
          date={"03/08/2022"}
        /> 
      </div> */}
    </div>
  );
}

export default Body;
