import React from "react";
import { Grid, createTheme, ThemeProvider, Box } from "@mui/material";

import Header from "./Header";
import RecentEvents from "./RecentEvents";
import LatestStories from "./LatestStories";
import News from "./News";

const HomeScreen = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grid sx={{ width: "100vw" }}>
        <Header />
        <RecentEvents />
        <LatestStories />
        <News />
      </Grid>
    </ThemeProvider>
  );
};
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
});

export default HomeScreen;
