import React from "react";
import Style from "./Style.module.css";


function Header() {
  return (
    <header className={Style.header}>
      {/* background image onorio photo */}
      <img
        className={Style.onorio}
        alt="pressrelease"
        src={require("../../../assets/cover press release (1).png")}
      />
      <p className={Style.title}>Press release</p>
      <p className={Style.name}>Onório Cutane ministries</p>
    </header>
  );
}

export default Header;
