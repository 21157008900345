import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";

import OneGrey from "../../../assets/icons/1-grey.png";
import TwoGrey from "../../../assets/icons/2-grey.png";
import ThreeGrey from "../../../assets/icons/3-grey.png";
import FourGrey from "../../../assets/icons/4-grey.png";
import FiveGrey from "../../../assets/icons/5-grey.png";
import SixGrey from "../../../assets/icons/6-grey.png";
import MissionCard from "../MissionCard";

const SecondTab = () => {
  return (
    <RootContainer>
      <Container
        sx={{
          flexDirection: { md: "row", xs: "column" },
          padding: "0 5%",
          paddingTop: "5%",
        }}
      >
        <Box
          sx={{
            marginTop: { md: 0, xs: "2vh" },
            marginBottom: { md: 0, xs: "4vh" },
            backgroundImage: `url(https://storage.googleapis.com/public_ocm/cover-about-page-ministry.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            width: { xs: "90vw", sm: "80vw", md: "50vw", lg: "40vw" },
            height: { xs: "50vh", sm: "45vw", md: "30vw", lg: "25vw" },
            display: { md: "flex", xs: "none" },
          }}
        />
        <Row
          sx={{
            width: {
              md: "40vw",
              xs: "90vw",
            },
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "5vw",
            marginRight: "2vw",
            marginTop: { md: 0, xs: "1vh" },
          }}
        >
          <DividerVertical sx={{ height: { md: "20vh", xs: "47vh" } }} />
          <Column>
            <HeaderText sx={{ textAlign: "left" }}>
              Onório Cutane & Ministry
            </HeaderText>
            <Text>By Onório Cutane</Text>
            <Text>
              The Nations for Ministerial Church is a Christian organization of
              the Kingdom of Light on earth, whose vision is reaching the
              nations of the world with the Gospel of the Kingdom of God. It is
              a Christian family composed of men and women of all walks of life
              who have been saved by the blood of Jesus Christ. With more than
              500.000 members worldwide, the church has several cells in all the
              provinces of Mozambique. The Ministry of Apostle Onório is
              international, having satellites in the U.S.A, in Europe,
              specifically in Portugal, Germany, France and Holland; Latin
              America (Brazil) and in the African continent in Cape Verde, Sao
              Tome and Principe, the Republic of South Africa and the Kingdom of
              Swaziland. It is a dynamic and expansive ministry.
            </Text>
          </Column>
        </Row>

        <Box
          sx={{
            //marginTop: { md: 0, xs: "2vh" },
            //marginBottom: { md: 0, xs: "4vh" },
            backgroundImage: `url(https://storage.googleapis.com/public_ocm/cover-about-page-ministry.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            width: { xs: "90vw", sm: "80vw", md: "50vw", lg: "40vw" },
            height: { xs: "45vh", sm: "45vw", md: "30vw", lg: "25vw" },
            display: { md: "none", xs: "flex" },
          }}
        />
      </Container>

      <Container
        sx={{
          flexDirection: "column",
          padding: { sm: "5%", xs: "0 5%" },
          //bgcolor: "red",
        }}
      >
        <Row sx={{ alignItems: "center" }}>
          <DividerHorizontalGrey />
          <HeaderText>Our Vision & Mission </HeaderText>
          <DividerHorizontalGrey />
        </Row>
        <Text>By Onório cutane</Text>
        <Row
          sx={{
            alignItems: "center",
            marginTop: "2%",
            justifyContent: "space-around",
            width: "90vw",
          }}
          container
        >
          <MissionCard
            content={
              "Reaching the nations of the world for Christ by bringing salvation, deliverance, and healing to the fallen and suffering humanity."
            }
            iconSource={OneGrey}
          />
          <MissionCard
            content={
              "To practice moral and material charity at its power and to provide members with Christian spiritual goods and moral values that enable them to live an honest and dignified life."
            }
            iconSource={TwoGrey}
          />
          <MissionCard
            content={
              "Spread moral education and wrestle against the spiritual wickedness that causes the suffering humanity."
            }
            iconSource={ThreeGrey}
          />
          <MissionCard
            content={
              "To study, disseminate and practice Christian Evangelical doctrine, based on the Holy scriptures."
            }
            iconSource={FourGrey}
          />
          <MissionCard
            content={
              "Proclaim the divine word and show the way of salvation in Jesus Christ."
            }
            iconSource={FiveGrey}
          />
          <MissionCard
            content={
              "Establish the Kingdom of God on earth restoring peace and harmony between men and God."
            }
            iconSource={SixGrey}
          />
        </Row>
      </Container>
    </RootContainer>
  );
};

export default SecondTab;

const Column = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});
const RootContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  // width: "100vw",
  // height: "50vh",
});
const Container = styled(Grid)({
  justifyContent: "space-between",
  alignItems: "center",
  //padding: "5%",
  display: "flex",
});
const Row = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});

const HeaderText = styled(Typography)({
  color: "#1C3D47",
  fontWeight: "500",
  fontSize: "22px",
  lineHeight: "24px",
  textAlign: "center",
  padding: "1% 0",
  whiteSpace: "nowrap",
});
const Text = styled(Typography)({
  color: "#6C6C6C",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "23px",
  textAlign: "left",
  padding: "1% 0%",
});

const DividerVertical = styled(Box)({
  backgroundColor: "#B78400",
  height: "30vh",
  marginRight: "4%",
  width: "20px",
  marginTop: "2vh",
});

const DividerHorizontalGrey = styled(Box)({
  backgroundColor: "#1C3D47",
  width: "5vw",
  marginRight: "4%",
  marginLeft: "4%",
  height: "1px",
});
