import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box, Card } from "@mui/material";
const MissionCard = ({ content, iconSource }) => {
  return (
    <Column
      item
      xl={4}
      lg={4}
      md={4}
      sm={5}
      xs={12}
      sx={{
        //marginLeft: { md: "-35px", xs: 0 },
        //width: { md: "50vw", xs: "90vw" },
        //bgcolor: "green",
        padding: "2%",
      }}
    >
      <Column sx={{ alignItems: "center" }}>
        <Box
          sx={{
            alignSelf: "center",
            marginBottom: {
              xl: "-35px",
              lg: "-35px",
              md: "-35px",
              sm: "-50px",
              xs: "-45px",
            },
            //marginRight: "20px",
            zIndex: 1,
            backgroundImage: `url(${iconSource})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            height: { xl: "3vw", lg: "3vw", md: "4vw", sm: "6vw", xs: "11vw" },
            width: { xl: "3vw", lg: "3vw", md: "4vw", sm: "6vw", xs: "11vw" },

            //display: { md: "none", xs: "block" },
          }}
        />
        {/* <Box
          sx={{
            alignSelf: "center",
            marginBottom: { sm: "-35px", xs: "-35px" },
            marginRight: "20px",
            zIndex: 1,
            display: { xs: "none", md: "block" },
          }}
        >
          <img
            src={iconSource}
            style={{
              height: "3vw",
            }}
          />
        </Box> */}
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 1,
            margin: { md: "2vh 2vh", xs: "4vh 0vh" },
            padding: "7%",
            //padding: { lg: "8%", sm: "13%", xs: "10%" },
            height: {
              xl: "12vh",
              lg: "14vh",
              md: "15vh",
              sm: "12vh",
              xs: "auto",
            },
          }}
        >
          <ContentText
            sx={{
              fontSize: { lg: "12px", md: "11px", xs: "10px" },
            }}
          >
            {content}
          </ContentText>
        </Card>
      </Column>
    </Column>
  );
};

export default MissionCard;
const Column = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});

const ContentText = styled(Typography)({
  color: "#666666",
  fontWeight: "500",
  fontSize: "12px",
  textAlign: "left",
});
