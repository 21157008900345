import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import PrayerCard from "./PrayerCard";
import Style from "./Style.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addPrayer } from "../../../js/actions/communityActions";

function PrayerWall({ user, isAuth }) {
  const [prayers, setPrayers] = useState([]);
  const [post, setPost] = useState("");
  const dispatch = useDispatch();

  const handlePost = async () => {
    dispatch(addPrayer(user._id, post));
    setPost("");
  };

  async function getData() {
    try {
      const res = await axios.get("/api/prayers/");
      setPrayers(res.data.data);
    } catch (error) {
      //console.log("error", error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* introduction */}
      <div className={Style.intro}>
        <div className={Style.text}>
          {/* title */}
          <div className={Style.title}>
            <p>Prayer Wall</p>
            <img
              alt="gold horizontal line"
              src={require("../../../assets/Rectangle 64.png")}
            />
          </div>
          {/* author */}
          <p className={Style.author}>By Onório Cutane</p>

          {/* description */}
          <div className={Style.description}>
            <img
              style={{ height: "125px", width: "3px" }}
              alt="gold vertical line"
              src={require("../../../assets/Rectangle 62 (3).png")}
              className={Style.description_img}
            />
            <p>
              Apostle Onório Gabriel Cutane is the Senior Pastor and the
              President of The Nations for Christ Ministerial Church Inc. He is
              a servant of God with a dynamic ministry of teaching, healing, and
              deliverance, which has helped many people get rid of the Kingdom
              of darkness into God’s Kingdom of light and love. He has a team of
              intercessors ready to pray for you. Send your prayer request now.
            </p>
          </div>
        </div>
        <img
          className={Style.prayerImage}
          style={{ width: "40%", height: "auto" }}
          alt="prayer wall"
          src="https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/anniversary.jpeg"
        />
      </div>

      {/* prayers */}
      <div className={Style.prayer}>
        <div className={Style.prayerTitle}>
          <img
            alt="gold horizontal line"
            src={require("../../../assets/Rectangle 64.png")}
          />
          <p>Prayer</p>
          <img
            alt="gold horizontal line"
            src={require("../../../assets/Rectangle 64.png")}
          />
        </div>
        <div className={Style.stories}>
          {prayers.map((prayer, i) => {
            return (
              <PrayerCard
                name={
                  prayer && prayer.user
                    ? prayer.user.firstname + " " + prayer.user.lastname
                    : ""
                }
                date={prayer.date}
                text={prayer.text}
                photo={
                  prayer && prayer.user
                    ? "https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/profile_photo%20.png"
                    : "https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/profile_photo%20.png"
                }
                key={i}
              />
            );
          })}
        </div>

        {/* post prayer */}
        {isAuth ? (
          <>
            <img
              className={Style.profile}
              style={{
                display: "inline-block",
                maxHeight: "50px",
                borderRadius: "50%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                border: "1px solid white",
              }}
              alt="profile"
              src={
                user
                  ? "https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/profile_photo%20.png"
                  : "https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/profile_photo%20.png"
              }
            />
            <div className={Style.card} style={{ whiteSpace: "pre-wrap" }}>
              <div>
                <p className="prayerWallAuthor">
                  {user ? user.firstname + " " + user.lastname : ""}
                </p>
                <textarea
                  className={Style.prayer_textarea}
                  placeholder="Type your prayer request or counseling..."
                  onChange={(e) => {
                    setPost(e.target.value);
                  }}
                  value={post}
                  style={{
                    border: "none",
                    outline: "none",
                    WebkitBoxShadow: "none",
                    boxShadow: "none",
                    resize: "none",
                    width: "30vw",
                    height: "40px",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    overflow: "hidden",
                  }}
                ></textarea>
              </div>
              <Button
                onClick={() => {
                  handlePost();
                }}
                variant="outline"
                className={Style.post}
              >
                post
              </Button>
            </div>
          </>
        ) : (
          <>
            {/* <div className={Style.card}>
              <div>
                <p
                  className="prayerWallText"
                  style={{
                    width: "100%",
                    marginBottom: "20%",
                    marginTop: "-10%",
                    alignContent: "center",
                  }}
                >
                  Type Your First Prayer
                </p>
              </div>
              <Button variant="outline" className={Style.post}>
                post
              </Button>
            </div> */}
            <Link className={Style.signin} to="/login">
              <p>Sign in to send your prayer request or counseling...</p>
              <img alt="go" src={require("../../../assets/go1.png")} />
            </Link>{" "}
          </>
        )}
      </div>
    </>
  );
}

export default PrayerWall;
