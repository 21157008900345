import React from "react";
import MobileNavigationBar from "../mobileNavigationBar/MobileNavigationBar";
import NavigationBar from "../NavigationBar/NavigationBar";
import Style from "../../screens/homeScreen/header/Style.module.css";

function Nav({ logedIn }) {
  return (
    <>
      <div className={Style.desktopMenu}>
        <NavigationBar user={logedIn} />
      </div>

      <div
        className={Style.mobileMenu}
        style={{ position: "absolute", top: 0 }}
      >
        <MobileNavigationBar user={logedIn} />
      </div>
    </>
  );
}

export default Nav;
