import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const News = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    async function getData() {
      try {
        const res = await axios.get("/api/news/recent");
        setNews(res.data);
      } catch (error) {
        //console.log(error);
      }
    }
    getData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: { md: "1vh", xs: 0 },
        }}
      >
        <Divider />
        <TitleText sx={{ fontSize: { md: "25px", sm: "20px", xs: "18px" } }}>
          NEWS
        </TitleText>
        <Divider />
      </Box>

      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { sm: "flex-start", xs: "center" },
          alignItems: "center",
          //width: "80vw",
          alignSelf: "center",
          padding: "0 5%",
        }}
        container
      >
        {news.map((news, index) => {
          return (
            <Grid
              key={news._id}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                padding: { md: "2%", sm: "2%", xs: "5%" },
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  width: { xs: "20vh", sm: "24vh", md: "24vh", lg: "24vh" },
                  height: { md: "40vh", sm: "40vh", xs: "30vh" },
                  padding: "5%",
                  zIndex: 1,
                  backgroundColor: "#293B56",
                }}
              >
                <Box
                  sx={{
                    //alignSelf: "center",

                    backgroundImage: `url(${news.photo})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                    width: { xs: "16vh", sm: "20vh", md: "20vh", lg: "20vh" },
                    height: { md: "20vh", sm: "20vh", xs: "10vh" },
                    backgroundPosition: "center",
                    //marginLeft: { md: "2%", xs: 0 },

                    //paddingTop: "10%",

                    //margin: "2%",
                  }}
                />

                <HeaderText sx={{ marginBottom: "1vh" }}>
                  {news.title}
                </HeaderText>
                <ContentText>{news.description}</ContentText>

                <StyledLink to="/news">
                  <Grid
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.21);",
                      borderRadius: "4px",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      borderWidth: 0,
                      // width: "60px",
                      //height: "38px",
                      marginTop: { md: "1vh", xs: "1vh" },
                      //marginBottom: { md: 0, xs: "1vh" },
                    }}
                  >
                    <Text
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "12px",
                        color: "#926A05",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                        textDecoration: "none",
                        //marginLeft: { md: 0, xs: "5px" },
                      }}
                    >
                      Read More
                    </Text>
                    <ArrowForwardIosIcon
                      sx={{ color: "#926A05", fontSize: "12px" }}
                    />
                  </Grid>
                </StyledLink>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#926A05",
                  height: { sm: "40vh", xs: "30vh" },
                  width: { lg: "15vh", md: "15vh", sm: "15vh", xs: "18vh" },
                  marginTop: "2vh",
                  marginLeft: {
                    lg: "-13vh",
                    md: "-13vh",
                    sm: "-13vh",
                    xs: "-16vh",
                  },
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
const StyledLink = styled(Link)({
  textDecoration: "none",
});
const TitleText = styled(Typography)({
  color: "#926A05",
  fontWeight: "500",
  fontSize: "25px",
  margin: "20px",
  textAlign: "center",
  padding: "2% 0%",
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "10px",
  whiteSpace: "nowrap",
  textAlign: "center",
  textDecoration: "none",
});
const HeaderText = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "600",
  marginTop: "10px",
  fontSize: "15px",
  textAlign: "left",
  lineHeight: "16px",
  textTransform: "capitalize",
});
const ContentText = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "500",
  fontSize: "10px",
  textAlign: "left",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  display: "-webkit-box",
  textOverflow: "ellipsis",
  lineClamp: 3,
});
const Divider = styled(Box)({
  backgroundColor: "#293B56",
  width: "100px",
  height: "1px",
});
export default News;
