import React from "react";
import Style from "./Style.module.css";

function Header() {
  return (
    <div className={Style.NewsHeader}>
      <div className={Style.header}>
        <div className={Style.headerblock}>
          <div className={Style.headerblockGold}></div>
          <p className={Style.headerblockTitle}>
            Find our latest news on our official press page.
          </p>
          <p className={Style.headerblockContent}>
            Be always informed of all the upcoming programs, news and events in
            our ministry.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Header;
