import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "../Commun/Nav";
import Footer from "../footer/Footer";
function WithNav({ logedIn }) {
  return (
    <>
      {" "}
      <Nav logedIn={logedIn} />
      <Outlet />
      <Footer />
    </>
  );
}

export default WithNav;
