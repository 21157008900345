import React, { useEffect, useState } from "react";
import Style from "./Style.module.css";
import style from "./../../../components/dailyDevotion/style.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import axios from "axios";

function Header() {
  const [devotionals, setDevotionals] = useState([]);
  async function getData() {
    try {
      const res = await axios.get("/api/devotionals");
      if (res.status === 200) {
        setDevotionals(res.data.data);
      }
    } catch (error) {
      //console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={Style.NewsHeader}>
      <div className={`row ${Style.header}`}>
        <div className="col-sm-12 col-md-8 justify-center mt-5">
          <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} width={`50%`}  >
            {Array.isArray(devotionals) && devotionals.map((post) => {
              return (

                <div className={`col-sm-12 col-xs-12 ${style['sidebar-widget']}`} style={{ margin: "2rem" }}>
                  <div className="row">

                    <div className={`col-sm-12 ${style['widget-body']}`}>
                      <div className="row">
                        <ul className={`${style['widget-list']} ${style['w-our-picks-list']}`}>
                          <li>
                            <div className={`${style['post-image']}`}>
                              <a href={`/devotional-detail/tag/${post.tag}`}>
                                <span className={`${style['label-post-category']}`}> {post.tag}</span>
                              </a>
                              <a href={`/devotional-detail/tag/${post.tag}`}>
                                <img
                                  src={post.image}
                                  className="img-responsive lazyloaded"
                                  alt={post.title}
                                  width="100%"
                                  height="100%"
                                />
                              </a>
                            </div>
                            <hr />
                            <h4 className={`fw-semibold text-start text-primary`} >
                              <a href={`/devotional-detail/${post.slug}`} className="" style={{ color: "white", marginTop: "10em" }}>
                                {post.title}
                              </a>
                            </h4>

                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
        <div className="col-sm-none d-none d-sm-block col-md-4">
          {/* <div className={Style.headerblock}>
            <div className={Style.headerblockGold}></div>
            <p className={Style.headerblockTitle}>
              Study our daily devotion.
            </p>
            <p className={Style.headerblockContent}>
              Be always informed of all the upcoming programs, news and events in
              our ministry.
            </p>
          </div> */}
        </div>
      </div>

    </div>
  );
}

export default Header;
