import React  from "react";
import Style from "./Style.module.css";
import PostSidebar from "../../../components/dailyDevotion/PostSidebar";
import Style2 from "../../../components/dailyDevotion/style.module.css";

import PostDetails from "../../../components/dailyDevotion/PostDetails";

function DetailBody({devotional, related}) {
  return (
    <div className={Style.body}>
      <div className={Style.titleContainer}>
        <div className={Style.latestNewsContainer}>
          <p className={Style.latestNews}>Daily Devotion</p>
          <div className={Style.row}>
            <div className={Style.goldDivider} />
            <div className={Style.whiteDivider} />
          </div>
          {<div className={Style.line_2} alt="line"></div>}
        </div>
      </div>
      <section id="main" className={Style2["margin-top-50"]}>
        <div className="container">
          <div className="row">
            <PostDetails devotional = {devotional}/>
            
            <PostSidebar title={"Related Devotional"} props={related} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default DetailBody;
