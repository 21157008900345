import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box, Card } from "@mui/material";
const HistoryCard = ({ content, title1, title2, imageSource }) => {
  return (
    <Column
      sx={{
        marginLeft: { md: "-40px", xs: 0 },
        width: { md: "50vw", xs: "90vw" },
      }}
    >
      <Row sx={{ alignItems: "center" }}>
        <Box
          sx={{
            marginRight: "-25px",
            zIndex: 1,
            display: { sm: "none", xs: "block" },
          }}
        >
          <img
            src={imageSource}
            style={{
              width: "10vw",
            }}
            alt="history"
          />
        </Box>

        <Box
          sx={{
            marginRight: "-25px",
            zIndex: 1,
            display: { md: "none", sm: "block", xs: "none" },
          }}
        >
          <img
            src={imageSource}
            style={{
              width: "7vw",
            }}
            alt="history"
          />
        </Box>
        <Box
          sx={{
            marginRight: "-20px",
            zIndex: 1,
            display: { lg: "none", md: "block", xs: "none" },
          }}
        >
          <img
            src={imageSource}
            style={{
              width: "5vw",
            }}
            alt="history"
          />
        </Box>

        <Box
          sx={{
            marginRight: "-20px",
            zIndex: 1,
            display: { lg: "block", xs: "none" },
          }}
        >
          <img
            src={imageSource}
            style={{
              width: "3vw",
            }}
            alt="history"
          />
        </Box>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 1,
            margin: { md: "2vh 0", xs: "2vh 2vh" },
            padding: "5%",
          }}
        >
          <HeaderText>{title1}</HeaderText>
          <Text sx={{ color: "#1C3D47" }}>{title2}</Text>

          <ContentText sx={{ xs: "10px", md: "11px", lg: "12px" }}>
            {content}
          </ContentText>
        </Card>
      </Row>
    </Column>
  );
};

export default HistoryCard;
const Column = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});

const Row = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});

const HeaderText = styled(Typography)({
  color: "#B78400",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "left",
});
const Text = styled(Typography)({
  color: "#6C6C6C",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "23px",
  textAlign: "left",
  padding: "1% 0%",
});

const ContentText = styled(Typography)({
  color: "#696969",
  fontWeight: "500",
  fontSize: "10px",
  textAlign: "left",
  //paddingBottom: "5%",
});
