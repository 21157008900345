import React, { useState, forwardRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import useNotification from "./useNotification";

function Notification() {
  const [open, setOpen] = useState(false);
  const { notif } = useNotification();

  useEffect(() => {
    //console.log("notification");
  }, [notif]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (notif && notif.message) {
      handleClick();
      setTimeout(() => {
        handleClose();
      }, 4000);
    }
  }, [notif]);

  useEffect(() => {
    if (!notif) {
      handleClose();
    }
  }, [notif]);

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        action={action}
        anchorOrigin={
          notif && notif.type === "error"
            ? { vertical: "top", horizontal: "right" }
            : { vertical: "bottom", horizontal: "center" }
        }
      >
        <Alert
          onClose={handleClose}
          severity={notif ? notif.type : "error"}
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "15px",
            }}
          >
            {notif && notif.message}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Notification;
