import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import Slider1 from "../../assets/icons/about-slider-1.png";
import Slider2 from "../../assets/icons/about-slider-2.png";
import Slider3 from "../../assets/icons/about-slider-3.png";
const image = require("../../assets/about.jpg");
const ministries = require("../../assets/ministries.jpg");
const janifer = require("../../assets/janifer.jpg");
const Header = ({ selectedTab, setSelectedTab }) => {
  console.log(selectedTab);
  return (
    <>
      <Container
        sx={{
          height: {
            //xl: "35vw",
            //lg: "35vw",
            md: "35vw",
            sm: "50vw",
            xs: "40vw",
          },
          backgroundImage:
            selectedTab === "janifer"
              ? `url(${janifer})`
              : selectedTab === "ministries"
              ? `url(${ministries})`
              : `url(${image})`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "90vw",
            height: {
              //xl: "35vw",
              //lg: "35vw",
              md: "30vw",
              sm: "45vw",
              xs: "35vw",
            },
            alignItems: "center",
            marginTop: { lg: "5vh", md: "7vh", xs: 0 },
          }}
        >
          <Box />
          <Box>
            {selectedTab === "onorio" && (
              <Box
                sx={{
                  width: { lg: "45vw", md: "55vw", xs: "90vw" },
                  marginBottom: { xl: "6vh", lg: "4vh", xs: 0 },
                  marginLeft: { lg: "15vw", md: "20vw", xs: 0 },
                  marginTop: { lg: 0, md: "2vh", xs: 0 },
                }}
              >
                <HeaderText
                  sx={{
                    fontSize: {
                      lg: "24px",
                      md: "20px",
                      sm: "20px",
                      xs: "16px",
                    },
                  }}
                >
                  About us
                </HeaderText>
                <HeaderText2
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "28px",
                      sm: "28px",
                      xs: "20px",
                    },
                  }}
                >
                  Onório Cutane
                </HeaderText2>
                <Text
                  sx={{
                    fontSize: {
                      lg: "13px",
                      md: "11px",
                      sm: "11px",
                      xs: "10px",
                    },
                    padding: 0,
                    paddingBottom: "2%",
                    paddingTop: "1%",
                    lineHeight: { lg: "24px", md: "16px" },
                  }}
                >
                  Apostle Onório Cutane is a prolific gospel writer and an
                  international minister of the Gospel. He runs a global
                  ministry with churches in Europe, America, and Africa. His
                  passion is to see people free from the plights of the devil
                  and enjoy life eternal life in the kingdom of God.
                </Text>
              </Box>
            )}
            {selectedTab === "janifer" && (
              <Box
                sx={{
                  width: { lg: "45vw", md: "55vw", xs: "90vw" },
                  marginBottom: { xl: "7vh", lg: "5vh", md: "3vh", xs: 0 },
                  marginLeft: "15vw",
                }}
              >
                <HeaderText
                  sx={{
                    fontSize: {
                      lg: "24px",
                      md: "22px",
                      sm: "20px",
                      xs: "16px",
                    },
                  }}
                >
                  About
                </HeaderText>
                <HeaderText2
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "30px",
                      sm: "30px",
                      xs: "20px",
                    },
                  }}
                >
                  Janifer Cutane
                </HeaderText2>
                <Text
                  sx={{
                    fontSize: { lg: "13px", sm: "11px", xs: "10px" },
                    padding: 0,
                    paddingBottom: "2%",
                    paddingTop: "1%",
                    lineHeight: { lg: "24px", md: "16px" },
                  }}
                >
                  Janifer Cutane is a Mozambican gospel artist. She is a pastor,
                  a worshipper, and an intercessor. Moreover, she is a women’s
                  leader, marriage counselor, and life coach.
                </Text>
              </Box>
            )}
            {selectedTab === "ministries" && (
              <Box
                sx={{
                  width: { lg: "45vw", md: "55vw", xs: "90vw" },
                  marginBottom: { xl: "7vh", lg: "7vh", md: "3vh", xs: 0 },
                  marginLeft: { md: "15vw", lg: "12vw" },
                }}
              >
                <HeaderText
                  sx={{
                    fontSize: {
                      lg: "24px",
                      md: "22px",
                      sm: "20px",
                      xs: "16px",
                    },
                  }}
                >
                  About
                </HeaderText>
                <HeaderText2
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "30px",
                      sm: "30px",
                      xs: "20px",
                    },
                  }}
                >
                  Onório Cutane
                </HeaderText2>
                <HeaderText2
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "30px",
                      sm: "30px",
                      xs: "20px",
                    },
                  }}
                >
                  MINISTRIES
                </HeaderText2>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: "50px",
              height: "20vh",
              backgroundImage:
                selectedTab === "onorio"
                  ? `url(${Slider1})`
                  : selectedTab === "janifer"
                  ? `url(${Slider2})`
                  : `url(${Slider3})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              display: { lg: "flex", xs: "none" },
            }}
          />
        </Box>
        <TabRow
          sx={{
            //justifyContent: "space-between",
            width: { md: "75vw", xs: "100vw" },
          }}
          container
        >
          <StyledButton
            item
            xs={4}
            sx={{
              flexGrow: 1,
              borderRadius: "5px 0px 0px 0px",
              borderBottom:
                selectedTab === "onorio" ? "5px solid #B48200" : null,
            }}
            onClick={() => {
              setSelectedTab("onorio");
            }}
          >
            <TabText sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
              Onório Cutane
            </TabText>
          </StyledButton>
          <StyledButton
            item
            xs={4}
            sx={{
              //width: { md: "25vw", xs: "30vw" },
              flexGrow: 1,
              borderBottom:
                selectedTab === "janifer" ? "5px solid #B48200" : null,
            }}
            onClick={() => {
              setSelectedTab("janifer");
            }}
          >
            <TabText
              sx={{
                fontSize: { md: "18px", sm: "16px", xs: "12px" },
              }}
            >
              Janifer Cutane
            </TabText>
          </StyledButton>
          <StyledButton
            item
            xs={4}
            sx={{
              flexGrow: window.screen.width > 370 ? 0.5 : 1,
              borderRadius: "0px 5px 0px 0px",
              borderBottom:
                selectedTab === "ministries" ? "5px solid #B48200" : null,
            }}
            onClick={() => {
              setSelectedTab("ministries");
            }}
          >
            <TabText sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
              Onório Cutane Ministries
            </TabText>
            {/* <Text sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
              Ministries
            </Text> */}
          </StyledButton>
        </TabRow>
      </Container>
    </>
  );
};

export default Header;
const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  height: "70vh",
  backgroundImage: `url(https://storage.googleapis.com/public_ocm/cover-about-page-1.png)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  backgroundPosition: "center",
  justifyContent: "flex-end",
  alignItems: "center",
});
const HeaderText = styled(Typography)({
  color: "#fff",
  fontFamily: "Abril Fatface",
  fontWeight: "400",
  fontSize: "24px",
  //lineHeight: "24px",
  textAlign: "left",
  //marginLeft: "5px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  //padding: "1% 0",
});
const HeaderText2 = styled(Typography)({
  color: "#280801",
  fontFamily: "Abril Fatface",
  fontWeight: "400",
  fontSize: "40px",
  //lineHeight: "48px",
  textAlign: "left",
  //marginLeft: "5px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  //padding: "1% 0",
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "left",
  padding: "3% 0%",
});
const TabRow = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  background:
    "linear-gradient(90.41deg,rgba(194, 194, 194, 0.2) 6.22%,rgba(234, 234, 234, 0.1) 99.85%)",
});
const TabText = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "400",
  fontSize: "18px",

  textAlign: "center",

  whiteSpace: "normal",
});
const StyledButton = styled("button")({
  //borderRadius: "5px 5px 0px 0px",
  background: "transparent",
  border: "1px solid rgba(227, 227, 227, 0.12)",
  "&:hover": {
    //backgroundColor: "grey",
    borderBottom: "5px solid #B48200",
  },
  alignItems: "center",
  height: "70px",
});
