import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import OneIcon from "../../../assets/icons/01.png";
import TwoIcon from "../../../assets/icons/02.png";
import ThreeIcon from "../../../assets/icons/03.png";
import Icon1 from "../../../assets/icons/1.png";
import Icon2 from "../../../assets/icons/2.png";
import Icon3 from "../../../assets/icons/3.png";
import Icon4 from "../../../assets/icons/4.png";
import Icon5 from "../../../assets/icons/5.png";
import Icon6 from "../../../assets/icons/6.png";
import Icon7 from "../../../assets/icons/7.png";
import Icon8 from "../../../assets/icons/8.png";
import Icon9 from "../../../assets/icons/9.png";
import Icon10 from "../../../assets/icons/10.png";
import Icon11 from "../../../assets/icons/11.png";
import Icon12 from "../../../assets/icons/12.png";

import HistoryCard from "../HistoryCard";
import BeliefRow from "../BeliefRow";

const FirstTab = () => {
  return (
    <Container>
      <Grid
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5%",
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <Row
          sx={{
            width: {
              md: "40vw",
              xs: "90vw",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "5vw",
              marginRight: "2vw",
            },
          }}
        >
          <DividerVertical
            sx={{
              height: {
                xl: "26vh",
                lg: "31vh",
                md: "48vh",
                sm: "25vh",
                xs: "45vh",
              },
            }}
          />
          <Column sx={{}}>
            <HeaderText>About</HeaderText>
            <HeaderText2>Apostle Onório Cutane</HeaderText2>
            <Text>
              Apostle Onório Gabriel Cutane, (On’ório Kut’ani) born July 28 th ,
              1987 in Boane district, Maputo Province, to a Mozambican father
              and mother. His father, Gabriel Joel Guinze Cutane (1945 -), was
              born in Macia district,Gaza province, Shangaan tribe. His mother,
              Cristina Norte Matevule Cutane (1950 -), was born in Mahubo,Boane
              district, Maputo province; Ronga tribe. Onório’s parents met in
              Lourenço Marques (current Maputo province) in 1965 and have lived
              officially together since 1966. He is the last born of 8 siblings.
              Onório Cutane is married (2015 -) to Janifer Chimwemwe Da Graça
              Metambo Cutane, and both are proud parents of Prince Onório Cutane
              (son) and Naomi Esther Onório Cutane (daughter). Apostle Onório
              Gabriel Cutane is the Senior Pastor and the President of The
              Nations for Christ Ministerial Church Inc.
            </Text>
          </Column>
        </Row>
        <Box
          sx={{
            marginRight: { md: "5vw", xs: 0 },
            marginTop: { md: 0, xs: "2vh" },
            marginBottom: { md: 0, xs: "2vh" },
            backgroundImage: `url(https://ogcpublications.com/assets/images/about/onios2.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            width: { xs: "90vw", sm: "80vw", md: "60vw", lg: "40vw" },
            height: { xs: "50vh", sm: "45vw", md: "40vw", lg: "25vw" },
          }}
        />
      </Grid>

      <Row>
        <Box sx={{ display: { md: "block", xs: "none" }, alignSelf: "center" }}>
          <img
            alt="about-page-cover"
            src="https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/anniversary.jpeg"
            style={{ width: "35vw", height: "40vw" }}
          />
        </Box>

        <Column
          sx={{
            marginLeft: { md: "10vw", xs: 0 },
            paddingRight: { md: "5vw", xs: 0 },
            justifyContent: { md: "flex-start", xs: "center" },
            alignItems: { md: "flex-start", xs: "center" },
            width: { md: "auto", xs: "100vw" },
          }}
        >
          <Column sx={{ alignItems: { md: "flex-start", xs: "center" } }}>
            <Row
              sx={{
                alignItems: "center",
                justifyContent: { md: "flex-start", xs: "center" },
              }}
            >
              <HeaderText2>History & Ministry</HeaderText2>
              <DividerHorizontal />
            </Row>
            <Text
              sx={{
                fontSize: "11px",
              }}
            >
              By Onório cutane
            </Text>
          </Column>

          <HistoryCard
            imageSource={OneIcon}
            title1={"How it Started"}
            title2={" Onório ‘s Church"}
            content={
              "His ministry began in a humble and ordinary place in Boane with less than ten believers but empowered with a divine [Onório Cutane testimony on supernatural experience] calling. Today, Apostle Onório’s ministry has reached and touched tens  of thousands  of people worldwide from all walks of life and religious persuasion who have witnessed the prophetic ministry and the divine healing that has vindicated the ministry of Apostle Onório Cutane."
            }
          />
          <HistoryCard
            imageSource={TwoIcon}
            title1={"Ministry"}
            title2={" Onório ‘s Church"}
            content={
              "His message has been transcending, inclusive, and non-denominational, where love, hope, deliverance, and salvation in Christ have been the cornerstone of his teaching ministry. The gospel of the Kingdom of Heaven is the bedrock of his calling  divine mandate. [Onório Cutane testimony on supernatural experience]."
            }
          />
          <HistoryCard
            imageSource={ThreeIcon}
            title1={"Creative Art & Literary Work"}
            title2={" Onório ‘s Church"}
            content={
              "He is a bestseller Christian author with ten published books in multiple languages and an avid gospel music writer and composer with over 80 songs. Both books and songs are now available worldwide on most platforms."
            }
          />
        </Column>
      </Row>
      <Grid
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "5vh",
          display: { xs: "none", md: "flex" },
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <DividerHorizontal sx={{ display: { md: "block", xs: "none" } }} />
        <Column
          sx={{
            marginLeft: { md: "4vw", xs: "-36px" },
            marginRight: { md: "4vw", xs: 0 },
          }}
        >
          <HeaderText2
            sx={{
              fontSize: "18px",
              padding: { md: "1% 0", xs: "0" },
            }}
          >
            Links of Books & Songs :
          </HeaderText2>
          <Text
            sx={{
              fontSize: "11px",
            }}
          >
            By Onório cutane
          </Text>
        </Column>
        <Column>
          <a
            target="_blank"
            href="https://ogcpublications.com/books/ogc"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <LinkText>List of books by Onório Cutane</LinkText>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ogcpublications.com/musicbyjanifer"
            style={{ textDecoration: "none" }}
          >
            <LinkText>Songs and Music by Janifer Cutane</LinkText>
          </a>
          {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ogcpublications.com/podcastsbyogc"
                style={{ textDecoration: "none" }}
              >
                <LinkText>Podcasts by Onório Cutane</LinkText>
              </a> */}
        </Column>
      </Grid>
      <Row
        sx={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: "5vh",
          marginBottom: { md: "5vh", xs: "2vh" },
        }}
      >
        <DividerHorizontal sx={{ marginLeft: "0", marginRight: "4%" }} />
        <HeaderText
          sx={{
            color: "#1C3D47",
            fontSize: "30px",
            textAlign: { md: "left", xs: "center" },
          }}
        >
          Onório Cutane's Beliefs
        </HeaderText>
        <DividerHorizontal />
      </Row>
      {/* Believes Desktop */}
      <Column container sx={{ display: { md: "flex", xs: "none" } }}>
        <BeliefRow
          imageSource={Icon1}
          direction
          content={
            "The belief in one almighty and one Lord God. Personified in the Most Holy Trinity, one and indivisible (Deuteronomy 6:5; Matthew 28:19; Mark 12:30 and John 17:3);"
          }
        />
        <DividerVerticalGrey />
        <BeliefRow
          imageSource={Icon2}
          direction={false}
          content={
            "The belief in the inspiration of the Holy Bible as a form of Revelation from God to men for the solution of their problems (2 Timothy3:16; II Peter1:20-21; Romans 15:4);"
          }
        />
        <DividerVerticalGrey />
        <BeliefRow
          imageSource={Icon3}
          direction
          content={
            "The belief in the virgin birth of our Lord Jesus Christ in his death, resurrection from the dead, and victorious ascension into heaven (Isaiah 7:14: Luke 1:27-35: Romans 8:34);"
          }
        />
        <DividerVerticalGrey sx={{ marginTop: "-1vh", marginBottom: "-2vh" }} />
        <BeliefRow
          imageSource={Icon4}
          direction={false}
          content={
            "The belief in man’s sinfulness and that his salvation can only be achieved through faith in the expiratory work of the Lord Jesus and repentance giving way to times of refreshment by the presence of Jesus Christ (Romans 3:23; Acts 3-19);"
          }
        />
        <DividerVerticalGrey sx={{ marginTop: "-1vh", marginBottom: "-2vh" }} />
        <BeliefRow
          imageSource={Icon5}
          direction
          content={
            "The belief in the need for the new birth by faith in Christ Jesus and by the power of action of the Holy Spirit and the word of God to make a man worthy of the Kingdom of heaven (John 3:3-8: Galatians 6:15; 2 Corinthians 5:17);"
          }
        />
        <DividerVerticalGrey sx={{ marginTop: "-1vh", marginBottom: "-2vh" }} />
        <BeliefRow
          imageSource={Icon6}
          direction={false}
          content={
            "Belief in the forgiveness of sins, salvation in Christ, and faith in the sacrifice in Jesus Christ for the suffering humanity (John 3:36; Acts 10:13)"
          }
        />
        <DividerVerticalGrey sx={{ marginTop: "-1vh", marginBottom: "-2vh" }} />
        <BeliefRow
          imageSource={Icon7}
          direction
          content={
            "Faith in baptism in the waters as a sign of purification of the soul and covenant of the believer with God, an act done through immersion in the waters, in the name of Jesus Christ (Matthew 28-19 Romans 6:1-6; Acts 1-5;)."
          }
        />
        <DividerVerticalGrey sx={{ marginTop: "-1vh", marginBottom: "-2vh" }} />
        <BeliefRow
          imageSource={Icon8}
          direction={false}
          content={
            "Belief in the Baptism of the Holy Spirit with evidence of speaking in tongues and human character change, aligning him in the perfect will of God;"
          }
        />
        <DividerVerticalGrey />
        <BeliefRow
          imageSource={Icon9}
          direction
          content={
            "Belief in the resurrection of the dead and the final Judgement for the sinner;"
          }
        />
        <DividerVerticalGrey />
        <BeliefRow
          imageSource={Icon10}
          direction={false}
          content={
            "Belief in the soon second coming of our Lord Jesus Christ and eternal reward for the believers;"
          }
        />
        <DividerVerticalGrey />
        <BeliefRow
          imageSource={Icon11}
          direction
          content={
            "Belief in the New Heavenly Jerusalem, the eternal home of the saints;"
          }
        />
        <DividerVerticalGrey />
        <BeliefRow
          imageSource={Icon12}
          direction={false}
          content={
            "Belief in the prosperity of the spirit, the soul, and the body (3 John 2)."
          }
        />
      </Column>
      {/* Believes Mobile */}
      <Column
        container
        sx={{
          display: { md: "none", xs: "flex", padding: "5%" },
          justifyContent: "center",
        }}
      >
        <BeliefRow
          imageSource={Icon1}
          direction
          withoutSpace
          content={
            "The belief in one almighty and one Lord God. Personified in the Most Holy Trinity, one and indivisible (Deuteronomy 6:5; Matthew 28:19; Mark 12:30 and John 17:3);"
          }
        />

        <BeliefRow
          imageSource={Icon2}
          direction
          withoutSpace
          content={
            "The belief in the inspiration of the Holy Bible as a form of Revelation from God to men for the solution of their problems (2 Timothy3:16; II Peter1:20-21; Romans 15:4);"
          }
        />

        <BeliefRow
          imageSource={Icon3}
          direction
          withoutSpace
          content={
            "The belief in the virgin birth of our Lord Jesus Christ in his death, resurrection from the dead, and victorious ascension into heaven (Isaiah 7:14: Luke 1:27-35: Romans 8:34);"
          }
        />

        <BeliefRow
          imageSource={Icon4}
          direction
          withoutSpace
          content={
            "The belief in man’s sinfulness and that his salvation can only be achieved through faith in the expiratory work of the Lord Jesus and repentance giving way to times of refreshment by the presence of Jesus Christ (Romans 3:23; Acts 3-19);"
          }
        />

        <BeliefRow
          imageSource={Icon5}
          direction
          withoutSpace
          content={
            "The belief in the need for the new birth by faith in Christ Jesus and by the power of action of the Holy Spirit and the word of God to make a man worthy of the Kingdom of heaven (John 3:3-8: Galatians 6:15; 2 Corinthians 5:17);"
          }
        />

        <BeliefRow
          imageSource={Icon6}
          direction
          withoutSpace
          content={
            "Belief in the forgiveness of sins, salvation in Christ, and faith in the sacrifice in Jesus Christ for the suffering humanity (John 3:36; Acts 10:13)"
          }
        />

        <BeliefRow
          imageSource={Icon7}
          direction
          withoutSpace
          content={
            "Faith in baptism in the waters as a sign of purification of the soul and covenant of the believer with God, an act done through immersion in the waters, in the name of Jesus Christ (Matthew 28-19 Romans 6:1-6; Acts 1-5;)."
          }
        />

        <BeliefRow
          imageSource={Icon8}
          direction
          withoutSpace
          content={
            "Belief in the Baptism of the Holy Spirit with evidence of speaking in tongues and human character change, aligning him in the perfect will of God;"
          }
        />

        <BeliefRow
          imageSource={Icon9}
          direction
          withoutSpace
          content={
            "Belief in the resurrection of the dead and the final Judgement for the sinner;"
          }
        />

        <BeliefRow
          imageSource={Icon10}
          direction
          withoutSpace
          content={
            "Belief in the soon second coming of our Lord Jesus Christ and eternal reward for the believers;"
          }
        />

        <BeliefRow
          imageSource={Icon11}
          direction
          withoutSpace
          content={
            "Belief in the New Heavenly Jerusalem, the eternal home of the saints;"
          }
        />

        <BeliefRow
          imageSource={Icon12}
          direction
          withoutSpace
          content={
            "Belief in the prosperity of the spirit, the soul, and the body (3 John 2)."
          }
        />
      </Column>
      <Grid
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: { md: "7vh", xs: "5vh" },
          display: { xs: "flex", md: "none" },
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <DividerHorizontal sx={{ display: { md: "block", xs: "none" } }} />
        <Column
          sx={{
            marginLeft: { md: "4vw", xs: "-36px" },
            marginRight: { md: "4vw", xs: 0 },
          }}
        >
          <HeaderText2
            sx={{
              fontSize: "18px",
              padding: { md: "1% 0", xs: "0" },
            }}
          >
            Links of Books & Songs :
          </HeaderText2>
          <Text
            sx={{
              fontSize: "11px",
            }}
          >
            By Onório cutane
          </Text>
        </Column>
        <Column>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ogcpublications.com/books/ogc"
            style={{ textDecoration: "none" }}
          >
            <LinkText>List of books by Onório Cutane</LinkText>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ogcpublications.com/musicbyjanifer"
            style={{ textDecoration: "none" }}
          >
            <LinkText>Songs and Music by Janifer Cutane</LinkText>
          </a>
          {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ogcpublications.com/podcastsbyogc"
                style={{ textDecoration: "none" }}
              >
                <LinkText>Podcasts by Onório Cutane</LinkText>
              </a> */}
        </Column>
      </Grid>
    </Container>
  );
};

export default FirstTab;

const Column = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});
const Container = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});
const Row = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});
const HeaderText2 = styled(Typography)({
  color: "#000",
  fontWeight: "500",
  fontSize: "22px",
  textAlign: "left",
  whiteSpace: "nowrap",
  padding: "1% 0",
});
const HeaderText = styled(Typography)({
  color: "#B78400",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "left",
});
const Text = styled(Typography)({
  color: "#6C6C6C",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "23px",
  textAlign: "left",
  padding: "1% 0%",
});
const LinkText = styled(Typography)({
  color: "#6C4E00",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "23px",
  textAlign: "left",
  padding: "1% 0%",
  textDecoration: "underline",
  whiteSpace: "nowrap",
});
const DividerVertical = styled(Box)({
  backgroundColor: "#B78400",
  height: "20vh",
  marginRight: "4%",
  width: "20px",
});
const DividerHorizontal = styled(Box)({
  backgroundColor: "#B78400",
  height: "2px",
  marginLeft: "4%",
  width: "8vh",
});

const DividerVerticalGrey = styled(Box)({
  backgroundColor: "#BBBBBB",
  height: "5vh",
  width: "1px",
  alignSelf: "center",
});
