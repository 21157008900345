import React, { useState } from "react";
import Style from "./Style.module.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import CommentCard from "./CommentCard";
import axios from "axios";
import { addComment } from "../../../js/actions/communityActions";
import { useDispatch } from "react-redux";

function ChatCard({ user, story, comments, date, nb, likes, id, isAuth }) {
  const [likethis, setLikethis] = useState(false);
  const [nbLike, setnbLike] = useState(nb);
  const [showComment, setShowComment] = useState(false);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const handlePost = async () => {
    dispatch(addComment(id, content));
    setContent("");
  };

  const addLike = async () => {
    try {
      const res = await axios.get("/api/story/like/" + id, {
        withCredentials: true,
      });
      if (res.status === 200) {
        setLikethis(!likethis);
        setnbLike(nbLike + res.data);
      }
    } catch (error) {
      //console.log("error", error);
    }
  };

  return (
    <div className={Style.chatCards}>
      {/* story publication */}
      <div className={Style.storyCard}>
        {/* gold horizontal line */}
        {/* <img
          alt="gold horizontal line"
          src={require("../../../assets/Rectangle 127.png")}
        /> */}

        {/* card */}
        <div className={Style.card}>
          {/* profile photo */}

          <img
            alt="profile"
            src={
              user
                ? "https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/profile_photo%20.png"
                : ""
            }
            style={{
              position: "relative",
              zIndex: "1",
              borderRadius: "50%",
              WebkitBoxShadow: "inherit",
            }}
            className={Style.cardImg}
          />

          {/* card body */}
          <div className={Style.cardBody}>
            <div className="prayerWallAuthorDate">
              <p
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0.05em",
                  color: "#1c3d47",
                }}
              >
                {user ? user.firstname + " " + user.lastname : ""}
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "9px",
                  lineHeight: "14px",
                  letterSpacing: " 0.05em",
                  color: " #b78400",
                  marginLeft: "1%",
                }}
              >
                {moment(date).fromNow()}
              </p>
            </div>
            {/* the story */}
            <p>{story}</p>

            <div className={Style.reactButtons}>
              {/* like button */}
              <span
                style={{
                  display: "flex",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "9px",
                  lineHeight: "14px",
                  letterSpacing: " 0.05em",
                  color: " #b78400",
                  marginRight: "2%",
                  marginTop: "5px",
                }}
              >
                {nbLike + " Likes"}
              </span>
              {isAuth ? (
                <>
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={() => addLike()}
                    href="#"
                  >
                    {likethis ? (
                      <img
                        alt="like"
                        src={require("../../../assets/like.png")}
                      />
                    ) : (
                      <img
                        alt="like"
                        src={require("../../../assets/like (1).png")}
                      />
                    )}
                  </button>
                  <Button
                    variant=""
                    onClick={() => {
                      setShowComment(!showComment);
                    }}
                    className={Style.commentButton}
                  >
                    <img
                      alt="comment"
                      src={require("../../../assets/Comment.png")}
                    />
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={Style.addCommentContainer}>
        {showComment && isAuth && (
          <div className={Style.addComment_to_Story}>
            <textarea
              className={Style.add_comment_card}
              placeholder="Comment on this story"
              onChange={(e) => {
                setContent(e.target.value);
              }}
              value={content}
              style={{
                border: "none",
                outline: "none",
                WebkitBoxShadow: "none",
                boxShadow: "none",
                resize: "none",
                width: "30vw",
                height: "40px",
                fontSize: "12px",
                fontFamily: "Poppins",
                overflow: "hidden",
              }}
            />
            <Button
              onClick={() => {
                handlePost();
              }}
              variant=""
            >
              <img
                alt="post"
                src={require("../../../assets/send Button.png")}
              />
            </Button>
          </div>
        )}
      </div>
      {/* comments */}
      {comments.map((comment) => {
        return (
          <CommentCard
            key={comment._id}
            comment={comment.text}
            user={comment.user}
            date={comment.date}
          />
        );
      })}
    </div>
  );
}

export default ChatCard;
