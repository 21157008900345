import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  TextField,
  InputLabel,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  Slider,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import ocmLogo from "../../assets/icons/ocm-logo.svg";
import ocmLogoBlack from "../../assets/icons/ocm-logo-black.svg";
import csc from "country-state-city";
import { useDispatch } from "react-redux";
import { register } from "../../js/actions/userActions";
import Notification from "../../components/Notification";

const formSchema = yup.object().shape({
  gender: yup.string().required("Required"),
  countryOfOrigin: yup.string().required("Required"),
  country: yup.string().required("Required"),
  city: yup.string().required("Required"),
  church: yup.string().required("Required"),
  seniority: yup.number().required("Required"),
  civil_status: yup.string().required("Required"),
  source: yup.string().max(300),
});
const NextSignupScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location.state) {
      navigate("/signup/0");
    }
  }, []);

  const handleOnSubmit = (values, actions) => {
    delete location.state.confirmPassword;
    const body = { ...values, ...location.state };
    //console.log("user", body);
    dispatch(register(body, navigate));
    actions.setSubmitting(false);
    actions.resetForm();
    delete location.state;
  };

  const countries = csc.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const [selectedCountry, setselectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [churchs, setChurchs] = useState([]);
  useEffect(() => {
    const getChurchs = async () => {
      try {
        const res = await axios.get("/api/locations");
        setChurchs(res.data.data);
      } catch (error) {
        //console.log(error);
      }
    };
    getChurchs();
  }, []);

  useEffect(() => {
    const getstates = async () => {
      const id = countries.find((element) => element.name === selectedCountry);

      if (id) {
        const updatedstates = csc
          .getStatesOfCountry(id.id)
          .map((state) => ({ label: state.name, value: state.id, ...state }));
        setStates(updatedstates);
      }
    };

    getstates();
  }, [selectedCountry]);
  const formik = useFormik({
    initialValues: {
      gender: "",
      countryOfOrigin: "",
      country: "",
      city: "",
      church: "",
      seniority: 50,
      civil_status: "",
      source: "",
    },
    validationSchema: formSchema,
    //enableReinitialize: true,
    onSubmit: handleOnSubmit,
  });

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Notification />
      <ThemeProvider theme={theme}>
        <Grid sx={{ display: "flex", flexDirection: "row" }}>
          <Grid
            style={{
              backgroundImage: `url(https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/generous.svg)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              //height: "100vh",
              width: "50vw",
              flexDirection: "column",
              justifyContent: "space-between",
              //alignItems: "center",
              padding: "5vh",
            }}
            sx={{ display: { md: "flex", xs: "none" } }}
          >
            <Grid
              //item
              //xs={1}
              sx={{ alignSelf: "flex-start" }}
            >
              <img src={ocmLogo} alt="ocm-logo" />
            </Grid>
            {/* Welcome */}
            <Grid
              //container
              //item
              //xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                //marginLeft: "5vw",
                alignSelf: "center",
                //alignSelf: "center",
                //backgroundColor: "blue",
                //alignItems: "flex-end",
                //justifyContent: "flex-end",
              }}
            >
              <Text
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontSize: "40px",
                  lineHeight: "36px",
                  textAlign: "center",
                  marginBottom: "3vh",
                }}
              >
                Welcome Back !
              </Text>
              <Text
                sx={{
                  width: "35vw",
                  marginBottom: "3vh",
                  lineHeight: "26px",
                  fontSize: "20px",
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                Nations for Christ.
                <br />
                Reaching the nations of the world with the gospel of love, peace
                and hope. Demonstrating the love and the power of God to
                humanity.
              </Text>
            </Grid>
            <Grid
              //item
              //xs={5}
              style={{ flexDirection: "row" }}
              sx={{
                display: "flex",
                alignSelf: "flex-end",
                //alignItems: "flex-end",
                //justifyContent: "flex-end",
                //width: "90vw",
                //backgroundColor: "red",
              }}
            >
              <Text>Already have an account ?&nbsp;</Text>
              <Link to="/login">
                <Grid style={{ flexDirection: "row" }} sx={{ display: "flex" }}>
                  <Text sx={{ textDecoration: "underline", color: "#926900" }}>
                    Log in&nbsp;
                  </Text>
                  <Text sx={{ textDecoration: "underline" }}>here</Text>
                </Grid>
              </Link>
            </Grid>
          </Grid>
          <Grid
            sx={{
              padding: "5vh",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              height: { md: "100vh", xs: "100%" },
              width: { md: "50vw", xs: "100vw" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* back to home */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { md: "row-reverse", xs: "row" },
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  //width: "10%",
                  alignSelf: "flex-start",
                  display: { md: "none", xs: "flex" },
                }}
              >
                <img
                  src={ocmLogoBlack}
                  alt="ocm-logo"
                  // height={"100%"}
                  width={"50%"}
                />
              </Grid>
              <Link to="/">
                <Box
                  style={{ flexDirection: "row" }}
                  sx={{
                    display: "flex",
                    alignSelf: "flex-end",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowBackIosIcon sx={{ color: "black", fontSize: 15 }} />
                  <Text
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "16px",
                      color: "black",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    Back To Home
                  </Text>
                </Box>
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "5vh",
              }}
            >
              <Text
                sx={{
                  fontSize: { md: "20px", xs: "15px" },
                  lineHeight: "30px",
                  background:
                    "linear-gradient(95.52deg, #00454b 8.71%, #b78400 100%)",
                  marginBottom: "2vh",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Sign up
              </Text>
              <Text
                sx={{
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#1C3D47",
                }}
              >
                Create your account and Became a member of the Nations
              </Text>
              <Text
                sx={{
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#1C3D47",
                }}
              >
                For Christ Ministerial Church
              </Text>
            </Box>
            {/* form */}
            <form onSubmit={formik.handleSubmit}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  alignItems: "center",
                  justifyContent: "center",
                  //bgcolor: "red",
                  marginTop: "2vh",
                }}
              >
                <Box
                  sx={{
                    //marginLeft: { md: "5vw", xs: "0vw" },
                    justifyContent: "center",
                    alignItems: "center",
                    justifyItems: "center",
                    alignContent: "center",
                    //bgcolor: "yellow",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    marginRight: { md: "0vw", xs: "0vw" },
                  }}
                >
                  <FormControl fullWidth>
                    {/* <Text sx={{ color: "#000210" }}>Gender</Text> */}
                    <StyledInputLabel id="gender-label">
                      Gender
                    </StyledInputLabel>
                    <StyledSelect
                      id="gender-select"
                      name="gender"
                      value={formik.values.gender}
                      label="Gender"
                      //margin="dense"
                      labelId="gender-label"
                      variant="outlined"
                      onChange={formik.handleChange}
                      placeholder="Enter Your Gender"
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      // helperText={
                      //   formik.touched.gender && formik.errors.gender
                      // }
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "15px",
                        //marginTop: "5px",
                        textAlign: "left",
                      }}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </StyledSelect>
                  </FormControl>
                  {/* <Text sx={{ color: "#000210" }}>Civil status</Text> */}
                  <FormControl fullWidth>
                    <StyledInputLabel
                      id="civil_status-label"
                      sx={{ fontSize: "12px" }}
                    >
                      Civil status
                    </StyledInputLabel>
                    <StyledSelect
                      id="civil_status"
                      name="civil_status"
                      labelId="civil_status-label"
                      label="Civil status"
                      value={formik.values.civil_status}
                      variant="outlined"
                      onChange={formik.handleChange}
                      placeholder="Enter Your Civil status"
                      error={
                        formik.touched.civil_status &&
                        Boolean(formik.errors.civil_status)
                      }
                      // helperText={
                      //   formik.touched.civil_status &&
                      //   formik.errors.civil_status
                      // }
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "15px",
                        //marginTop: "5px",
                        textAlign: "left",
                      }}
                    >
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"Divorced"}>Divorced</MenuItem>
                      <MenuItem value={"Widow"}>Widow</MenuItem>
                    </StyledSelect>
                  </FormControl>
                  <FormControl fullWidth>
                    <StyledInputLabel id="church-label">
                      Church
                    </StyledInputLabel>
                    <StyledSelect
                      id="church"
                      name="church"
                      labelId="church-label"
                      label="Church"
                      value={formik.values.church}
                      variant="outlined"
                      onChange={formik.handleChange}
                      placeholder="Enter Your Church"
                      error={
                        formik.touched.church && Boolean(formik.errors.church)
                      }
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "15px",
                        textAlign: "left",
                      }}
                    >
                      {churchs.map((church) => {
                        return (
                          <MenuItem key={church._id} value={church._id}>
                            {church.church_name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    //marginRight: { md: "5vw", xs: "0vw" },
                    //bgcolor: "green",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: { md: "1vw", xs: "0vw" },
                  }}
                >
                  {/* <Text sx={{ color: "#000210" }}>Country of Origin</Text> */}
                  <FormControl fullWidth>
                    <StyledInputLabel id="countryOfOrigin-label">
                      Country of Origin
                    </StyledInputLabel>
                    <StyledSelect
                      id="countryOfOrigin"
                      name="countryOfOrigin"
                      label="Country of Origin"
                      labelId="countryOfOrigin-label"
                      value={formik.values.countryOfOrigin}
                      variant="outlined"
                      onChange={formik.handleChange}
                      placeholder="Enter Your Country of Origin"
                      error={
                        formik.touched.countryOfOrigin &&
                        Boolean(formik.errors.countryOfOrigin)
                      }
                      // helperText={
                      //   formik.touched.countryOfOrigin &&
                      //   formik.errors.countryOfOrigin
                      // }
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "15px",
                        //marginTop: "5px",
                        textAlign: "left",
                      }}
                    >
                      {updatedCountries.map((country) => {
                        return (
                          <MenuItem key={country.id} value={country.name}>
                            {country.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                  {/* <Text sx={{ color: "#000210" }}>Country of Residence</Text> */}
                  <FormControl fullWidth>
                    <StyledInputLabel id="country-label">
                      Country of Residence
                    </StyledInputLabel>
                    <StyledSelect
                      id="country"
                      name="country"
                      labelId="country-label"
                      label="Country of Residence"
                      value={formik.values.country}
                      variant="outlined"
                      //onChange={formik.handleChange}
                      onChange={(e) => {
                        setselectedCountry(e.target.value);
                        formik.setFieldValue("country", e.target.value);
                      }}
                      placeholder="Enter Your Country of Residence"
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      // helperText={
                      //   formik.touched.country && formik.errors.country
                      // }
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "15px",
                        //marginTop: "5px",
                        textAlign: "left",
                      }}
                    >
                      {updatedCountries.map((country) => {
                        return (
                          <MenuItem key={country.id} value={country.name}>
                            {country.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                  {/* <Text sx={{ color: "#000210" }}>City</Text> */}
                  <FormControl fullWidth>
                    <StyledInputLabel id="city-label">City</StyledInputLabel>
                    <StyledSelect
                      id="city"
                      name="city"
                      labelId="city-label"
                      label="City"
                      value={formik.values.city}
                      variant="outlined"
                      onChange={formik.handleChange}
                      placeholder="Enter Your City"
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      //helperText={formik.touched.city && formik.errors.city}
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "15px",
                        //marginTop: "5px",
                        textAlign: "left",
                      }}
                    >
                      {selectedCountry === "" ? (
                        <MenuItem value={""}>
                          Select Your Country Of Residence First
                        </MenuItem>
                      ) : (
                        <MenuItem value={"Choose"}>Select Your City</MenuItem>
                      )}

                      {states.map((state) => {
                        return (
                          <MenuItem key={state.id} value={state.name}>
                            {state.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>

                  {/* <Text sx={{ color: "#000210" }}>Church</Text> */}
                </Box>
              </Grid>
              <Box
                sx={{
                  width: { xl: "32vw", lg: "30vw", md: "37vw", xs: "70vw" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignSelf: "center",
                  marginLeft: "1vw",
                }}
              >
                <Text
                  sx={{
                    color: "#000210",
                    width: { xl: "32vw", lg: "30vw", md: "37vw", xs: "70vw" },
                  }}
                >
                  Seniority: How many years have you been a member of our church
                </Text>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { md: "row", xs: "column" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: { xl: "30vw", lg: "31vw", md: "37vw", xs: "70vw" },
                    marginTop: "10px",
                  }}
                >
                  <Slider
                    defaultValue={50}
                    valueLabelDisplay="auto"
                    step={1}
                    min={1}
                    max={100}
                    id="seniority"
                    name="seniority"
                    sx={{ width: { md: "20vw", xs: "70vw" } }}
                    value={formik.values.seniority}
                    onChange={formik.handleChange}
                  />
                  <StyledTextField
                    id="seniority"
                    name="seniority"
                    margin="dense"
                    placeholder="Enter Your Seniority"
                    type={"number"}
                    hiddenLabel
                    variant="outlined"
                    size="normal"
                    sx={{
                      width: { lg: "5vw", md: "8vw", xs: "70vw" },
                      marginBottom: "10px",
                    }}
                    value={formik.values.seniority}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.seniority &&
                      Boolean(formik.errors.seniority)
                    }
                    helperText={
                      formik.touched.seniority && formik.errors.seniority
                    }
                  />
                </Box>
                <Text sx={{ color: "#000210" }}>
                  How did you know about Onório Cutane
                </Text>
                <StyledTextField
                  multiline
                  rows={3}
                  id="source"
                  name="source"
                  margin="dense"
                  hiddenLabel
                  variant="outlined"
                  size="normal"
                  sx={{
                    width: { lg: "31vw", md: "37vw", xs: "70vw" },
                    marginBottom: "10px",
                    fontSize: "12px",
                  }}
                  value={formik.values.source}
                  onChange={formik.handleChange}
                  error={formik.touched.source && Boolean(formik.errors.source)}
                  helperText={formik.touched.source && formik.errors.source}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* back button */}
                <ConfirmButton
                  onClick={() => navigate("/signup/0")}
                  sx={{
                    width: { md: "15vw", xs: "50vw" },
                    marginTop: "5vh",
                    marginRight: { md: "1vw", xs: "0vw" },
                  }}
                >
                  <ArrowBackIosIcon sx={{ color: "white", fontSize: 15 }} />
                  Back
                </ConfirmButton>
                {/* Sign up button */}
                <ConfirmButton
                  type="submit"
                  sx={{
                    width: { md: "15vw", xs: "50vw" },
                    marginTop: "5vh",
                    //marginLeft: "2vw",
                    marginLeft: { md: "1vw", xs: "0vw" },
                  }}
                >
                  Sign Up
                </ConfirmButton>
              </Box>
            </form>
            <Grid
              sx={{
                display: { md: "none", xs: "flex" },
                flexDirection: "row",
                alignSelf: "center",
                marginTop: "5vh",
              }}
            >
              <Text sx={{ color: "black" }}>
                Already have an account ?&nbsp;
              </Text>
              <Link to="/login">
                <Grid style={{ flexDirection: "row" }} sx={{ display: "flex" }}>
                  <Text
                    sx={{
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    Login here
                  </Text>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default NextSignupScreen;

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "left",
});
const StyledInputLabel = styled(InputLabel)({
  fontSize: "12px",
  fontFamily: "Poppins",
});
const StyledSelect = styled(Select)({
  backgroundColor: "#fff",
  fontSize: "12px",
  // menu: {
  //   fontSize: "12px",
  //   color: "gray",
  //   // backgroundColor: "#fff",
  // },

  // "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
});
const StyledTextField = styled(TextField)({
  backgroundColor: "#fff",
  fontSize: "12px",
  input: {
    fontSize: "12px",
    // backgroundColor: "#fff",
  },
  "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
});
const ConfirmButton = styled(Button)({
  background: "linear-gradient(92.1deg, #293b56 34.28%, #694c00 101.52%)",
  borderRadius: "8px",
  color: "white",
  textTransform: "capitalize",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "#969696",
  },
});
