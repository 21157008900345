import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Style from "./Style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../js/actions/userActions";

function NavigationBar() {
  const user = useSelector((state) => state.userReducer.user);
  const isAuth = useSelector((state) => state.userReducer.isAuth);
  const dispatch = useDispatch();

  return (
    <Navbar className={Style.navbar}>
      <Container fluid style={{ marginRight: "10px" }}>
        <Navbar.Brand>
          <Link to="/">
            <img
              alt="onorio logo"
              src={require("../../assets/onorio-logo-white 1.png")}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse className={Style.nav__collapse}>
          <Nav navbarScroll className={Style.nav}>
            {/* <div id="active-Link" className={Style.active__navLink__Home}></div> */}
            <Link to="/" className={Style.navbar__navLink__link} id="/">
              <span className={Style.navbar__navLink}>HOME</span>
            </Link>
            <Link to="/about" className={Style.navbar__navLink__link}>
              <span className={`${Style.navbar__navLink} `} id="/about">
                ABOUT
              </span>
            </Link>
            <Link to="/community" className={Style.navbar__navLink__link}>
              <span className={Style.navbar__navLink} id="/community">
                COMMUNITY
              </span>
            </Link>
            <Link to="/events" className={Style.navbar__navLink__link}>
              <span className={`${Style.navbar__navLink}`} id="/events">
                EVENTS
              </span>
            </Link>
            <Link to="/news" className={Style.navbar__navLink__link}>
              <span className={`${Style.navbar__navLink}`} id="/news">
                NEWS
              </span>
            </Link>
            {/*
             <Link to="/daily-devotion" className={Style.navbar__navLink__link}>
               <span className={`${Style.navbar__navLink}`} id="/daily-devotion">
                 DAILY DEVOTION
               </span>
             </Link>
            */}
            <Link to="/offering" className={Style.navbar__navLink__link}>
              <span className={`${Style.navbar__navLink}`} id="/offering">
                OFFERING
              </span>
            </Link>
            <div id="google_translate_element"></div>
            {/* <div style={{ margin: "auto", alignItems: "centre" }}>
              <NavDropdown
                title="EN"
                id={Style.navbarScrollingDropdown}
                style={{ alignItems: "center" }}
              >
                <NavDropdown.Item className={Style.flags} to="#action7">
                  <span>English</span>
                  <img alt="usa flag" src={require("../../assets/usa 2.png")} />
                </NavDropdown.Item>
                <NavDropdown.Item className={Style.flags} to="#action8">
                  <span>Portuguese</span>
                  <img
                    alt="usa flag"
                    src={require("../../assets/Flag_of_Portugal 2.png")}
                  />
                </NavDropdown.Item>
                <NavDropdown.Item className={Style.flags} to="#action9">
                  <span>French</span>
                  <img
                    alt="usa flag"
                    src={require("../../assets/2417841-illustrationle-du-drapeau-france-gratuit-vectoriel 2.png")}
                  />
                </NavDropdown.Item>
                <NavDropdown.Item className={Style.flags} to="#action10">
                  <span>Spanish</span>
                  <img
                    alt="usa flag"
                    src={require("../../assets/32987102b310ea00dfeb5d4a25f58a3d 1.png")}
                  />
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </NavDropdown>
            </div> */}
            <div className="d-flex ">
              {isAuth && user ? (
                <>
                  <NavDropdown
                    id={Style.navbarScrollingDropdown}
                    className={Style.press}
                    eventkey={1}
                    style={{ WebkitAppearance: "none" }}
                    title={
                      <>
                        {user ? user.firstname + " " + user.lastname : ""}
                        <img
                          src={
                            user
                              ? "https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/profile_photo%20.png"
                              : ""
                          }
                          alt="profile"
                          style={{
                            // position: "absolute",
                            alignSelf: "end",
                            marginTop: "-3%",
                            marginLeft: "5%",
                            height: "35px",
                            borderRadius: "50%",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            border: "1px solid white",
                          }}
                        />
                      </>
                    }
                  >
                    <NavDropdown.Item eventkey={1.1}>
                      <Link
                        to="/profile"
                        style={{
                          color: "black",
                          alignItems: "center",
                          margin: "auto",
                          textDecoration: "none",
                        }}
                      >
                        <div>Profile</div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item />
                    <NavDropdown.Item
                      eventkey={1.3}
                      onClick={() => {
                        dispatch(logout());
                      }}
                    >
                      <div>
                        <i className="fa fa-sign-out"></i> Logout
                      </div>
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      alignItems: "center",
                    }}
                  >
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      <span className={Style.navbar__navLink}>LOGIN</span>
                    </Link>
                    <Link to="/signup/0" style={{ textDecoration: "none" }}>
                      <span
                        className={`${Style.navbar__navLink} ${Style.navbar__navLink__signUp}`}
                      >
                        SIGN UP
                      </span>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
