import React, { useEffect, useState } from "react";
import Header from "./header/Header";
import style from "./../../components/dailyDevotion/style.module.css";
import axios from "axios";
import moment from "moment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import he from "he";

function DailyDevotionAll() {
  const [devotionals, setDevotionals] = useState([]);
  async function getData() {
    try {
      const res = await axios.get("/api/devotionals");
      console.log(res.data.data);
      setDevotionals(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Header />
      <Container>
        <div className="">
          <div className="ml-4 mr-4">
            <div className={``}>
              <div className="row">
                <div
                  className={`${style["widget-title"]} ${style["widget-popular-posts-title"]}`}
                >
                  <h4
                    className={`${style["title"]}`}
                    style={{ margin: "3rem" }}
                  >
                    All devotionals
                  </h4>
                </div>
                <div className={`${style["widget-body"]}`}>
                  <Row>
                    {devotionals.map((post) => {
                      return (
                        <Col
                          xs={12}
                          md={6}
                          lg={6}
                          key={post._id}
                          className="mb-4"
                        >
                          <Stack direction="horizontal" gap={3}>
                            <div className={`${style["left"]}`}>
                              <a href={`/devotional-detail/${post._id}`}>
                                <img
                                  src={post.image}
                                  className="img-responsive lazyloaded"
                                  alt={post.title}
                                  width="100"
                                  height="75"
                                />
                              </a>
                            </div>
                            <div className={`${style["right"]}`}>
                              <h6 className={`${style["title"]}`}>
                                <a href={`/devotional-detail/${post._id}`}>
                                  <p
                                    className={style["summary"]}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.detail.length > 80
                                          ? he
                                              .decode(post.detail)
                                              .substring(0, 80)
                                          : he.decode(post.detail),
                                    }}
                                  ></p>
                                </a>
                              </h6>
                              <div className={`${style["post-meta"]}`}>
                                <p className={`${style["post-meta-inner"]}`}>
                                  <a href={`/daily-devotion/tag/${post.tag}`}>
                                    # {post.tag}
                                  </a>
                                  <span>
                                    <ScheduleIcon
                                      style={{ fontSize: "16px" }}
                                    />
                                    &nbsp;
                                    {moment(post.createdAt).fromNow()}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Stack>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default DailyDevotionAll;
