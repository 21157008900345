import React from "react";
import Style from "./Style.module.css";

function Header() {
  return (
    <div
      role="img"
      aria-label="event"
      className={Style.OfferingHeader}
      style={{
        backgroundImage: `url(https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/cover-donation-type.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div />
      <div className={Style.OfferingBody}>
        <div className={Style.OfferingDescription}>
          <div className={Style.OfferingDescription_text_container}>
            <p className={Style.recentOffering}>DONATE & HELP</p>
            <p className={Style.OfferingText}>
              Your kind donation will help feed the hungry and relieve the pain
              and suffering of the most underpriliged people in rural areas and
              in the suburds. it will help us put a smile on the faces of
              thousands of families and children in Africa.
            </p>
          </div>
        </div>
      </div>
      <div className={Style.OfferingBanner}>
        <p className={Style.OfferingBannerText}>Why Give?</p>
      </div>
    </div>
  );
}

export default Header;
