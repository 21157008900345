import React, { useEffect, useState } from "react";
import Header from './header/Header';
import Body from './body/DetailBody';
import { useParams } from 'react-router-dom';
import axios from "axios";

function DailyDevotion() {
  const { id } = useParams();
  const [devotional, setDevotional] = useState([]);
  const [related, setRelated] = useState([]);
  async function getData() {
    try {
      const res = await axios.get(`/api/devotionals/${id}`);
      if (res.status === 200) {
        setDevotional(res.data.data);
        getRelatedDevotional(res.data.data.tag)
      }
    } catch (error) {
      //console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  async function getRelatedDevotional(tag) {
    try {
      const res = await axios.get(`/api/devotionals/tags/${tag}`);
      if (res.status === 200) {
        setRelated(res.data.data);
      }
    } catch (error) {
      //console.log(error);
    }
  }
  return (
    <>
      <Header />
      <Body devotional = {devotional} related={related}/>
    </>
  );
}

export default DailyDevotion;
