import React from "react";
import FirstTab from "./tabs/FirstTab";
import SecondTab from "./tabs/SecondTab";
import ThirdTab from "./tabs/ThirdTab";
const Body = ({ selectedTab }) => {
  return (
    <>
      {selectedTab === "onorio" && <FirstTab />}
      {selectedTab === "janifer" && <SecondTab />}
      {selectedTab === "ministries" && <ThirdTab />}
    </>
  );
};

export default Body;
