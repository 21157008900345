import React from "react";
import Header from "./header/Header";
import OfferingBody from "./body/OfferingBody";

function Offering() {
  return (
    <>
      <Header />
      <OfferingBody />
    </>
  );
}

export default Offering;
