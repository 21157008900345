import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Gallery from "@mui/icons-material/AddPhotoAlternateOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Style from "./style.module.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSelector } from "react-redux";

function modal() {
  const [field, setField] = useState();
  const hiddenFileInput = useRef(null);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [uproot, setUproot] = useState(false);
  const user = useSelector((state) => state.userReducer.user);

  const handleShow = () => {
    setShow(true);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
    setEdit(true);
  };

  const handleChange = (event) => {
    setField(event.target.files[0]);
  };

  const editPhoto = async (photo) => {
    try {
      const data = new FormData();
      data.append("photo", photo);
      await axios.put("/api/user/updatePhoto/" + user._id, data);
    } catch (error) {
      //console.log("error", error);
    }
  };

  const handleDelete = () => {
    if (window.confirm("you will delete this image!!")) {
      setUproot(true);
      //console.log("delete done");
    }
  };

  const deletePhoto = async () => {
    try {
      await axios.put("/api/user/deletePhoto/" + user._id);
    } catch (error) {
      //console.log("error", error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setEdit(false);
    setUproot(false);
  };

  const handleSave = () => {
    setShow(false);
    if (edit) {
      editPhoto(field);
    } else if (uproot) {
      deletePhoto();
    }
  };

  return (
    <>
      <div
        role="button"
        onClick={handleShow}
        className={Style.modale_button_container}
      >
        <img
          alt="profile pic"
          src={user ? user.photo : ""}
          className={Style.profile_picture}
        />
        <div className={Style.profile_picture_iconEdit}>
          <span style={{ cursor: "pointer" }}>
            <EditOutlinedIcon style={{ fill: "white" }} />
          </span>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="modal-content" style={{ borderRadius: "15px" }}>
          <div
            style={{
              backgroundColor: "#926A05",
              border: " none",
              borderRadius: "5px",
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title className={Style.modal_titre}>
                Profile Photo
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ margin: "20px" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt="profile pic"
                    src={user ? user.photo : ""}
                    className={Style.modal_body_img}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ display: "block" }}>
              <footer className={Style.buttons_container}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "40%",
                  }}
                >
                  <div onClick={handleClick} role="button">
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                    <p className={Style.modal_footer_titre}>Upload picture</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Gallery style={{ fill: "white" }} />
                    </div>
                  </div>
                  <div onClick={handleDelete} role="button">
                    <p className={Style.modal_footer_titre}>Delete</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <DeleteIcon style={{ fill: "white" }} />
                    </div>
                  </div>
                </div>
                <button onClick={handleSave} id={Style["saveModalButton"]}>
                  Save Changes
                </button>
              </footer>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default modal;
