import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AboutScreen from "./screens/aboutScreen/AboutScreen";
import LoginScreen from "./screens/authenticationScreens/LoginScreen";
import NextSignupScreen from "./screens/authenticationScreens/NextSignupScreen";
import SignupScreen from "./screens/authenticationScreens/SignupScreen";
import BookingScreen from "./screens/bookingScreen/BookingScreen";
import CommunityScreen from "./screens/communityScreen/CommunityScreen";
import EventsScreen from "./screens/eventsScreen/EventsScreen";
import HomeScreen from "./screens/homeScreen/HomeScreen";
import PaymentScreen from "./screens/paymentScreen/PaymentScreen";
import Offering from "./screens/offeringScreen/Offering";
import Press from "./screens/pressScreen/pressScreen";
import News from "./screens/newsScreen/News";
import DailyDevotion from "./screens/dailyDevotionScreen/DailyDevotion";
import DailyDevotionByTag from "./screens/dailyDevotionScreen/DailyDevotionByTag";
import DailyDevotionAll from "./screens/dailyDevotionScreen/DailyDevotionAll";
import DailyDevotionDetail from "./screens/dailyDevotionScreen/DailyDevotionDetail";
import Profile from "./screens/profileScreen/Profile";
import WithoutNav from "./components/NavigationBar/WithoutNav";
import WithNav from "./components/NavigationBar/WithNav";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, current } from "./js/actions/userActions";
import { clearCommunity } from "./js/actions/communityActions";
import Policies from "./screens/policiesScreen/PoliciesScreen";

function App() {
  const isAuth = useSelector((state) => state.userReducer.isAuth);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearErrors());
    dispatch(clearCommunity());
    dispatch(current());
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route element={<WithoutNav />}>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/signup/:id" element={<SignupScreen />} />
          <Route path="/nextSignup" element={<NextSignupScreen />} />
        </Route>
        <Route element={<WithNav />}>
          <Route exact path="/" element={<HomeScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="/community" element={<CommunityScreen />} />
          <Route path="/events" element={<EventsScreen />} />

          <Route path="/booking" element={<BookingScreen />} />
          {/* <Route path="/payment" element={<PaymentScreen />} /> */}
          <Route
            path="/profile"
            element={
              isAuth ? <Profile /> : <Navigate to="/login" replace={true} />
            }
          />
          <Route path="/offering" element={<Offering />} />
          <Route path="/news" element={<News />} />
          <Route path="/daily-devotion" element={<DailyDevotion />} />
          <Route
            path="/devotion-detail/:id"
            element={<DailyDevotionDetail />}
          />
          <Route path="/press" element={<Press />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/daily-devotion" element={<DailyDevotion />} />
          <Route
            path="/devotional-detail/tag/:tag"
            element={<DailyDevotionByTag />}
          />
          <Route path="/daily-devotion/all" element={<DailyDevotionAll />} />
          <Route
            path="/devotional-detail/:id"
            element={<DailyDevotionDetail />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
