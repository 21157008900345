import axios from "axios";
import {
  LOAD_COMMUNITY,
  ADD_COMMUNITY,
  FAIL_COMMUNITY,
  CLEAR_ERROR,
} from "../constants/ActionTypes";

export const addPrayer = (id, post) => async (dispatch) => {
  dispatch({ type: LOAD_COMMUNITY });
  try {
    await axios.post(
      "/api/prayers/add/" + id,
      {
        text: post,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return dispatch({ type: ADD_COMMUNITY });
  } catch (error) {
    return dispatch({ type: FAIL_COMMUNITY });
  }
};

export const addStory = (id, post) => async (dispatch) => {
  dispatch({ type: LOAD_COMMUNITY });
  try {
    await axios.post(
      "/api/story/add/" + id,
      {
        content: post,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return dispatch({ type: ADD_COMMUNITY });
  } catch (error) {
    return dispatch({ type: FAIL_COMMUNITY });
  }
};

export const addComment = (id, content) => async (dispatch) => {
  dispatch({ type: LOAD_COMMUNITY });
  const user = localStorage.getItem('token')
  try {
    await axios.post(
      "/api/comments/add",
      {
        text: content,
        story: id,
        user: user
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return dispatch({ type: ADD_COMMUNITY });
  } catch (error) {
    return dispatch({ type: FAIL_COMMUNITY });
  }
};

export const clearCommunity = () => {
  return { type: CLEAR_ERROR };
};
