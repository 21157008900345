import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import "../../styles/HomeStyles.css";
import {
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
  Button,
} from "@mui/material";
import sendIcon from "../../assets/images/send.png";
import podcastIcon from "../../assets/icons/podcast.svg";
import bookmark from "../../assets/star.png";
import bookIcon from "../../assets/icons/book.svg";
import livestreamIcon from "../../assets/icons/livestream.svg";
import musicIcon from "../../assets/icons/music.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const image = require("../../assets/hrr.jpg");
const newhero = require("../../assets/newhero.jpg");

const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="home" style={{ backgroundImage: `url(${newhero})` }}>
        <div className="headerContainer">
          <h1 className="year">2024</h1>
          <h1>
            THE YEAR OF HIGH <br></br> FLIGHTS
          </h1>
          <p>The year of the gathering of Eagles</p>
          {/* <Link to="/menu">
            <button>ORDER NOW</button>
          </Link> */}
        </div>
      </div>
      <h5
        style={{
          textAlign: "center",
          backgroundColor: "transparent",
          padding: "10px 0px",
        }}
      >
        <img
          alt="podcast"
          src={bookmark}
          style={{
            width: "20px",
            height: "20px",

            margin: "5px",
          }}
        />
        Reaching the nations of the world for Christ.{" "}
        <img
          alt="podcast"
          src={bookmark}
          style={{
            width: "20px",
            height: "20px",

            margin: "5px",
          }}
        />
        Demonstrating God's love and power for humanity.{" "}
        <img
          alt="podcast"
          src={bookmark}
          style={{
            width: "20px",
            height: "20px",

            margin: "5px",
          }}
        />
        Expanding Gods Kingdom on Earth.
      </h5>
      <Box
        sx={{
          backgroundColor: "#293B56",
          padding: { md: "50px", xs: "5px" },
          color: "white",
          height: { md: "50px", xs: "50px" },
          display: "flex",
          justifyContent: "center",
          gap: "4px",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Row>
          <Col>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ogcpublications.com/podcastsbyogc"
              style={{ textDecoration: "none" }}
            >
              <RowSpaced>
                <img alt="podcast" src={podcastIcon} />
                <HeaderIconsText
                  sx={{ fontSize: { lg: "16px", md: "14px", xs: "13px" } }}
                >
                  Podcast
                </HeaderIconsText>
              </RowSpaced>
            </a>
          </Col>
          <Col>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://kingdom24.tv/livestreaming"
              style={{ textDecoration: "none" }}
            >
              <RowSpaced sx={{ display: { md: "flex", xs: "none" } }}>
                <img
                  //className={Style.icon}
                  alt="live stream"
                  src={livestreamIcon}
                />
                <HeaderIconsText
                  sx={{ fontSize: { lg: "16px", md: "14px", xs: "13px" } }}
                >
                  Live Stream
                </HeaderIconsText>
              </RowSpaced>
              <RowSpaced sx={{ display: { md: "none", xs: "flex" } }}>
                <img
                  //className={Style.icon}
                  alt="live stream"
                  src={livestreamIcon}
                />
                <HeaderIconsText
                  sx={{ fontSize: { lg: "16px", md: "14px", xs: "13px" } }}
                >
                  Live
                </HeaderIconsText>
              </RowSpaced>
            </a>
          </Col>
          <Col>
            {" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ogcpublications.com/books/ogc"
              style={{ textDecoration: "none" }}
            >
              <RowSpaced>
                <img
                  //className={Style.icon}
                  alt="books"
                  src={bookIcon}
                />
                <HeaderIconsText
                  sx={{ fontSize: { lg: "16px", md: "14px", xs: "13px" } }}
                >
                  Books
                </HeaderIconsText>
              </RowSpaced>
            </a>
          </Col>
          <Col>
            {" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ogcpublications.com/musicbyjanifer"
              style={{ textDecoration: "none" }}
            >
              <RowSpaced>
                <img
                  // className={Style.icon}
                  alt="music"
                  src={musicIcon}
                />
                <HeaderIconsText
                  sx={{ fontSize: { lg: "16px", md: "14px", xs: "13px" } }}
                >
                  Music
                </HeaderIconsText>
              </RowSpaced>
            </a>
          </Col>
        </Row>
      </Box>
      <div style={{ backgroundImage: `url(${newhero})` }}>
        <h1></h1>
      </div>
    </ThemeProvider>
  );
};
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
});
const StyledLink = styled(Link)({
  textDecoration: "none",
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "left",
});
const HeaderIconsText = styled(Typography)({
  color: "#E0D9D9",
  fontWeight: "400",
  fontSize: { lg: "16px", md: "14px", xs: "13px" },
  lineHeight: "70px",
  textAlign: "left",
  marginLeft: "5px",
  whiteSpace: "nowrap",
});
// const Row = styled(Box)({
//   display: "flex",
//   flexDirection: "row",
// });

const RowSpaced = styled(Box)({
  display: "flex",
  flexDirection: "row",
  //justifyContent: "flex-start",
});
const StyledButton = styled(Button)({
  background: "rgba(255, 255, 255, 0.26)",
  borderRadius: "100px",
  color: "#b78400",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "12px",
  padding: "13px 17px 13px 20px",
  borderWidth: "0px;",
  display: "flex;",
  justifyContent: "space-between;",
  alignItems: "center;",
  "&:hover": {
    backgroundColor: "#969696",
  },
});
const Divider = styled(Box)({
  backgroundColor: "#CDCDCD",
  width: "1px",
  height: "80px",
});
export default Header;
