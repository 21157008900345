import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Header from "./header/Header";
import Style from "./Style.module.css";

function BookingScreen() {
  const [quantity, setQuantity] = useState(1);
  const path = window.localStorage.getItem("event");

  const handleSubmit = async () => {
    if (window.localStorage.getItem("user") !== null) {
      const res = await axios.post(
        "/api/payment/create-checkout-session",
        {
          items: [
            {
              quantity: quantity,
              price: e.price_Id,
            },
          ],
          user: JSON.parse(window.localStorage.getItem("user"))._id,
          event: path,
        }
      );
      window.location.replace(res.data);
    } else {
      window.location.assign("/login");
    }
  };

  const [e, setEvents] = useState({});
  useEffect(() => {
    async function getData() {
      try {
        const res = await axios.get(
          "/api/events/search/" + path
        );
        setEvents(res.data.data);
      } catch (error) {
        //console.log(error);
      }
    }
    getData();
  }, []);
  return (
    <>
      {/* header */}
      <Header />

      {/* body */}
      <div className={Style.body}>
        <div className={Style.startMiddle}>
          {/* start */}
          <div className={Style.start}>
            <img alt="one" src={require("../../assets/01 (12).png")} />
            <img
              className={Style.startLine}
              alt="line"
              src={require("../../assets/Rectangle 176.png")}
            />
          </div>

          {/* middle = image side*/}
          <div className={Style.middle}>
            <p>EVENTS</p>
            <img alt="conference" src={e.photo} />
          </div>
        </div>
        {/* end = input side */}
        <div className={Style.end}>
          {/* title */}
          <p className={Style.endTitle}>{e.title}</p>

          {/* date and time */}
          <div className={Style.dateTime}>
            <img
              alt="calendar"
              src={require("../../assets/date-svgrepo-com 1.png")}
            />
            <p className={Style.date}>
              {moment(e.event_date).format("DD MMMM yyyy")}
            </p>
            <img
              alt="clock"
              src={require("../../assets/time-svgrepo-com 1.png")}
            />
            <p className={Style.time}>
              {moment(e.event_date).format("hh:mm A")}
            </p>
          </div>

          {/* description */}
          <p className={Style.endText}>{e.description}</p>

          {/* number of tickets and price */}
          <div className={Style.endInput}>
            <div className={Style.number}>
              <p>Number of Tickets</p>
              <input
                type="number"
                defaultValue={1}
                max={e.numberOfSeats}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                placeholder="Enter your Number of tickets"
              />
            </div>
            <div className={Style.price}>
              <p>Price</p>
              <input
                type="text"
                disabled="true"
                min="00.00$"
                value={"Price                          " + e.seat_price + "$"}
              />
            </div>
          </div>

          {/* "buy button" */}

          <Button
            variant=""
            className={Style.endLink}
            onClick={() => handleSubmit()}
          >
            <p>Buy</p>
            <img alt="chariot" src={require("../../assets/Group (3).png")} />
          </Button>
        </div>
      </div>
    </>
  );
}

export default BookingScreen;
