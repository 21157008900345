import React from "react";
import style from "./style.module.css";
import moment from "moment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import he from 'he';

function PostItem({ post }) {
  function convertTimestampToFormattedDate(timestamp) {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
  }
  return (
    <div
      className={`${style["post-item-horizontal"]} ${style["margin-top-15"]} `}
    >
      <div className={style["item-image"]}>
        <a href="#">
          <span className={style["label-post-category"]}>
            {convertTimestampToFormattedDate(post.timestamp)}
          </span>
        </a>
        <a href={`/devotional-detail/${post._id}`}>
          <img
            src={post.image}
            className={`img-responsive lazyloaded`}
            width="100%"
            height="160px"
            no-script-url
          />
        </a>
      </div>
      <div className={style["item-content"]}>
        <h3 className={style["title"]}>
          <a href={`/devotional-detail/${post._id}`}>{post.title}</a>
        </h3>
        <div className={style["post-meta"]}>
          <p className={style["post-meta-inner"]}>
            <a href={`/devotional-detail/tag/${post.tag}`}>#{post.tag}</a>
            <span>
              <ScheduleIcon style={{ fontSize: "16px" }} />
              &nbsp; {moment(post.created_at).format("dddd")}
            </span>
          </p>
        </div>
        <p
          className={style["summary"]}
          dangerouslySetInnerHTML={{
            __html:
              post.detail.length > 80
                ? he.decode(post.detail).substring(3, 153).trim()
                : he.decode(post.detail),
          }}
        ></p>
        <div className={style["post-buttons"]}>
          <a
            href={`/devotional-detail/${post._id}`}
            className={`${style["pull-right"]} ${style["read-more"]}`}
          >
            Read More{" "}
            <i
              className={`${style["icon-arrow-right"]} ${style["read-more-i"]}`}
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default PostItem;
