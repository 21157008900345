import React from 'react';
import Modale from '../modal/Modal';
import Style from './Style.module.css';
import cover_Profile from '../../../assets/cover_Profile.png';

function header() {
  
  return (
    <div>
      <div
        className={Style.header_profile}
        style={{
          backgroundImage: `url(${cover_Profile})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Modale />
      </div>
    </div>
  );
}

export default header;
