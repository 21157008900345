import React from "react";
import EventsImage from "./EventsImage";
import Style from "./Style.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Autoplay, Pagination, Navigation, Lazy, FreeMode } from "swiper";
// import { Twitter, Facebook } from 'react-social-sharing'

function Events({ events }) {
  return (
    <div>
      <div className={Style.desktopPage}>
        <div className={Style.row}>
          <div className={Style.verticalContainer}>
            <p className={Style.verticalTitle}>KINGDOM TELEVISION</p>
          </div>
        </div>
        <div className={Style.row}>
          <div className={Style.varietyeventscontainer}>
            <div className={Style.varietyevents}>
              <p>VARIETY EVENTS</p>
              <img
                alt="gold horizontal line"
                src="https://storage.googleapis.com/public_ocm/Rectangle%20282.png"
              />
              {/* <hr style={{ color: "black", height: "2px" }} /> */}
            </div>
          </div>
        </div>

        <div className={Style.swipercontainer}>
          <Swiper
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              600: {
                slidesPerView: 2,
                spaceBetween: 570,
              },
              700: {
                slidesPerView: 2,
                spaceBetween: 570,
              },
              800: {
                slidesPerView: 2,
                spaceBetween: 570,
              },
              900: {
                slidesPerView: 2,
                spaceBetween: 870,
                initialSlide: 0,
                freeMode: true,
                slidesOffsetAfter: 50,
              },
              1000: {
                slidesPerView: 2,
                spaceBetween: 760,
                initialSlide: 1,
                freeMode: true,
              },
              1100: {
                slidesPerView: 2,
                spaceBetween: 660,
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: 570,
              },
              1300: {
                slidesPerView: 2,
                spaceBetween: 470,
              },
              1400: {
                slidesPerView: 2,
                spaceBetween: 370,
              },
              1500: {
                slidesPerView: 2,
                spaceBetween: 270,
                centeredSlides: true,
                initialSlide: 1,
              },
              1600: {
                slidesPerView: 2,
                spaceBetween: 170,
                centeredSlides: true,
                initialSlide: 1,
              },
              1700: {
                slidesPerView: 2,
                spaceBetween: 60,
                centeredSlides: true,
                initialSlide: 1,
              },
              1800: {
                slidesPerView: 2,
                spaceBetween: 5,
                centeredSlides: true,
                initialSlide: 1,
              },
              1900: {
                slidesPerView: 3,
                spaceBetween: 790,
                centeredSlides: true,
                initialSlide: 1,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation, Lazy, FreeMode]}
            className="mySwiper"
          >
            {/* //dimensions width = 848 height = 899 */}
            {events.map((e, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    alt="event OCM"
                    height="800vh"
                    width="880vw"
                    src={e.photo}
                    sizes="(min-width: 1550px) 480px,80px"
                  />
                  {/* <div className={Style.eventDescription}>
                    <div style={{ float: "left", width: "85%" }}>
                      {e.description}
                    </div>
                    <div style={{ float: "right", width: "15%" }}> */}
                  {/* https://twitter.com/intent/tweet/?text=&url=https%3A%2F%2Fwww.onoriocutane.org%2Fevents */}
                  {/* https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fonoriocutane.org%2Fevents */}
                  {/* <Twitter solidcircle small link="https://www.onoriocutane.org/events" />
                      <Facebook solid small link="https://onoriocutane.org/events" /> */}
                  {/* </div>
                  </div> */}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className={Style.mobilePage}>
        <div className={Style.eventsScreen}>
          <div className={Style.row}>
            <div className={Style.titleContainer}>
              <p className={Style.eventsTitle}>Events</p>
              <div className={Style.dividerContainer}>
                <div className={Style.dividerGrey} />
                <div className={Style.dividerGold} />
                <div className={Style.dividerGrey} />
              </div>
            </div>
            <div className={Style.space} />
          </div>

          {/* news events title */}
          <div className={Style.eventsContainer}>
            {events.map((e) => {
              return (
                <EventsImage
                  id={e._id}
                  title={e.title}
                  description={e.description}
                  img={e.photo}
                  date={e.event_date}
                  key={e._id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
