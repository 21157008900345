import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";

const Header = ({ selectedTab, setSelectedTab }) => {
  return (
    <Container
      sx={{ height: { lg: "70vh", md: "60vh", sm: "30vh", xs: "28vh" } }}
    >
      <TabRow
        sx={{
          width: { md: "75vw", xs: "100vw" },
        }}
        container
      >
        <StyledButton
          item
          xs={4}
          sx={{
            flexGrow: 1,
            borderRadius: "5px 0px 0px 0px",
            borderBottom: selectedTab === "prayer" ? "5px solid #B48200" : null,
          }}
          onClick={() => {
            setSelectedTab("prayer");
          }}
        >
          <TabText sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
            Prayer Wall
          </TabText>
        </StyledButton>
        <StyledButton
          item
          xs={4}
          sx={{
            //width: { md: "25vw", xs: "30vw" },
            //flexGrow: window.screen.width > 370 ? 0.5 : 1,
            flexGrow: 1,
            borderBottom: selectedTab === "story" ? "5px solid #B48200" : null,
          }}
          onClick={() => {
            setSelectedTab("story");
          }}
        >
          <TabText sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
            Share Your Story
          </TabText>
        </StyledButton>
        <StyledButton
          item
          xs={4}
          sx={{
            flexGrow: 1,
            borderRadius: "0px 5px 0px 0px",
            borderBottom:
              selectedTab === "location" ? "5px solid #B48200" : null,
          }}
          onClick={() => {
            setSelectedTab("location");
          }}
        >
          <TabText sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
            Locations
          </TabText>
        </StyledButton>
      </TabRow>
    </Container>
  );
};

export default Header;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  height: "70vh",
  backgroundImage: `url(https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/devotional.jpg)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  justifyContent: "flex-end",
  alignItems: "center",
});
const TabRow = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  background:
    "linear-gradient(90.41deg,rgba(194, 194, 194, 0.2) 6.22%,rgba(234, 234, 234, 0.1) 99.85%)",
});
const TabText = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "400",
  fontSize: "18px",
  textAlign: "center",
  whiteSpace: "normal",
  fontFamily: "Poppins",
});
const StyledButton = styled("button")({
  //borderRadius: "5px 5px 0px 0px",
  background: "transparent",
  border: "1px solid rgba(227, 227, 227, 0.12)",
  "&:hover": {
    //backgroundColor: "grey",
    borderBottom: "5px solid #B48200",
  },
  alignItems: "center",
  height: "70px",
});
