import { ADD_COMMUNITY, CLEAR_ERROR, FAIL_COMMUNITY, LOAD_COMMUNITY } from "../constants/ActionTypes";

const initialState = {
  addCommunity: false,
  loadCommunity: false,
  failCommunity: false,
};

const communityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_COMMUNITY:
      return { ...state, addCommunity: true, loadCommunity: false };
    case LOAD_COMMUNITY:
      return { ...state, addCommunity: false, loadCommunity: true };
    case FAIL_COMMUNITY:
      return { ...state, failCommunity: true, loadCommunity: false };
    case CLEAR_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default communityReducer;
