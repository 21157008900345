import React, { useState } from "react";
import { Grid, createTheme, ThemeProvider, Box } from "@mui/material";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import Body from "./Body";
const AboutScreen = () => {
  const [selectedTab, setSelectedTab] = useState("onorio");
  return (
    <ThemeProvider theme={theme}>
      <Grid sx={{ width: "100vw", display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Header selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <HeaderMobile
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Box>

        <Body selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </Grid>
    </ThemeProvider>
  );
};

export default AboutScreen;
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
});
