import moment from "moment";
import React from "react";

function PrayerCard({ name, date, text, photo }) {
  return (
    <>
      <img
        className="prayerWallProfile"
        style={{
          position: "relative",
          zIndex: "1",
          marginRight: "85.5%",
          borderRadius: "50%",
          height: "48px",
        }}
        alt="profile"
        src={photo}
      />
      <div className="prayerWallCard">
        <div className="prayerWallAuthorDate">
          <p className="prayerWallAuthor">{name}</p>
          <p className="prayerWallDate">{moment(date).fromNow()}</p>
        </div>
        <p className="prayerWallText">{text}</p>
      </div>
    </>
  );
}

export default PrayerCard;
