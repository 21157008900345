import {
  CLEAR_ERROR,
  CURRENT_USER,
  FAIL_USER,
  LOAD_USER,
  LOGIN_USER,
  LOGOUT_USER,
} from "../constants/ActionTypes";

const initialState = {
  user: {
    firstname: "",
    lastname: "",
  },
  error: null,
  isLoad: false,
  isAuth: false,
};
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_USER:
      return { ...state, isLoad: true };
    case LOGIN_USER:
      localStorage.setItem("token", payload._id);
      return { ...state, isLoad: false, user: payload, isAuth: true };
    case FAIL_USER:
      return { ...state, isLoad: false, error: payload, isAuth: false };
    case CURRENT_USER:
      return {
        ...state,
        user: payload,
        isLoad: false,
        isAuth: true,
      };
    case LOGOUT_USER:
      localStorage.removeItem("token");
      return initialState;
    case CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};
export default userReducer;
