import Style from "./Style.module.css";
import React from "react";

function Card({ title, address, contact, photo, email }) {
  return (
    <div className={Style.card}>
      <>
        {/* church image */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img alt="church" src={photo} style={{}} />
        </div>

        {/* card body */}
        <div className={Style.cardBody}>
          {/* card title */}
          <div className={Style.cardTitle}> {title}</div>

          {/* card text */}
          <div className={Style.cardText}>
            {/* address */}
            <div className={Style.address}>
              <p className={Style.addressTitle}>Address:</p>
              <div className={Style.addressText}>
                <p>{address}</p>
              </div>
            </div>

            {/* main contact */}
            <div className={Style.contact}>
              <p className={Style.contactTitle}>Main Contact:</p>
              {contact &&
                contact.map((el, i) => (
                  <p className={Style.contactText} key={i}>
                    {el}
                  </p>
                ))}
            </div>
          </div>

          {/* email */}
          <div className={Style.legacy} style={{ marginLeft: '10px'}}>
            <p className={Style.legacyTitle}>Email</p>
            <p className={Style.legacyText}>{email}</p>
          </div>
        </div>
      </>
    </div>
  );
}

export default Card;
