import Style from "./Style.module.css";
import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

function Header() {
  return (
    <div className={Style.eventsHeader}>
      <div className={Style.eventsDescription}>
        <div className={Style.eventsTitle}>
          <p className={Style.recentEvents}>Recent Events</p>
          <p className={Style.kingdomTV}>Kingdom Tv</p>
        </div>
        <p className={Style.eventsText}>
          This year Kingdom Television nework celebrated its second anniversay
          with so many testemonies of lives changed by the message of love,
          faith and hope aired on KINGDOM TV. It was an unforgettabel moment.
        </p>
        <a
          href={`https://www.kingdom24.tv`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SendButton
            onClick={() => {}}
            sx={{
              width: { md: "15vw", xs: "40vw", sm: "30vw" },
              height: "35px",
              //padding: "5%",
              alignSelf: "left",
            }}
          >
            <Text sx={{ textAlign: "center" }}>Visit</Text>
          </SendButton>
        </a>
      </div>
    </div>
  );
}

export default Header;

const SendButton = styled("button")({
  background: "transparent",
  borderRadius: "2px",
  //borderColor: "#CDCDCD",
  border: "1px solid #b78400",
  //borderWidth: "0.5px",
  "&:hover": {
    backgroundColor: "#b78400",
  },
  textAlign: "center",
});

const Text = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "18px",
  textAlign: "left",
  padding: "3% 0%",
});
