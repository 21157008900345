// community screen
export const ADD_COMMUNITY = "ADD_COMMUNITY";
export const LOAD_COMMUNITY = "LOAD_COMMUNITY";
export const FAIL_COMMUNITY = "FAIL_COMMUNITY";

// user screen

export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER="LOGOUT_USER";
export const LOAD_USER = "LOAD_USER";
export const FAIL_USER = "FAIL_USER";
export const CURRENT_USER="CURRENT_USER";


export const CLEAR_ERROR="CLEAR_ERROR";