import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ChatCard from "./ChatCard";
import Style from "./Style.module.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addStory } from "../../../js/actions/communityActions";

function ShareYourStory({ user, isAuth }) {
  const [story, setStory] = useState([]);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const handlePost = async () => {
    dispatch(addStory(user._id, content));
    setContent("");
  };

  async function getData() {
    try {
      const res = await axios.get("/api/story/");
      setStory(res.data);
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={Style.shareYourStory}>
      {/* title*/}
      <div className={Style.title}>
        <p className={Style.share}>Share</p>
        <p className={Style.story}>Your Story and Testimonies.</p>
      </div>
      {/* chat */}
      <div className={Style.chat}>
        {story.map((s) => {
          return (
            <ChatCard
              key={s._id}
              id={s._id}
              user={s.user}
              story={s.content}
              comments={s.comments}
              date={s.createdAt}
              nb={s.nbLikes}
              likes={s.userLikesThis}
              isAuth={isAuth}
            />
          );
        })}
      </div>
      {/* post your story card */}
      {isAuth ? (
        <div className={Style.inputContainer}>
          <p className={Style.postStoryTitle}>Share your story</p>
          <div className={Style.postStory}>
            <textarea
              className={Style.card}
              placeholder="Share your story and testimonies..."
              onChange={(e) => {
                setContent(e.target.value);
              }}
              value={content}
              style={{
                border: "none",
                outline: "none",
                WebkitBoxShadow: "none",
                boxShadow: "none",
                resize: "none",
                width: "30vw",
                height: "40px",
                fontSize: "12px",
                fontFamily: "Poppins",
                overflow: "hidden",
              }}
            />
            <Button
              onClick={() => {
                handlePost();
              }}
              variant=""
            >
              <img
                alt="post"
                src={require("../../../assets/send Button.png")}
              />
            </Button>
          </div>
        </div>
      ) : (
        <>
          {/* <p className={Style.postStoryTitle}>Share your story</p>
          <div className={Style.postStory}>
            <p>Please Tell us your stor here</p>
            <Button>
              <img
                alt="post"
                src={require("../../../assets/send Button.png")}
              />
            </Button>
          </div> */}
          <div className={Style.signInContainer}>
            <Link className={Style.signin} to="/login">
              <p>Sign in to share your story and testimonies.</p>
              <img alt="go" src={require("../../../assets/go1.png")} />
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default ShareYourStory;
