import axios from "axios";
import React, { useEffect, useState } from "react";
import SaveModal from "./SaveModal";
import Style from "./style.module.css";
import EditIcon from "@mui/icons-material/Edit";


function Security({ user }) {
  const [userData, setUserData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!!user) {
      setUserData({
        email: user.email,
      });
    }
  }, [user]);

  const handleChange = (e) => {
    return setUserData((userData) => {
      return { ...userData, [e.target.name]: e.target.value };
    });
  };

  const handleSave = () => {
    editSecurity();
    setShow(!show);
  };

  const editSecurity = async () => {
    try {
      await axios.put(
         "/api/user/updateSecurity/" + user._id,
        userData
      );
    } catch (error) {
      //console.log("error", error);
    }
  };

  return (
    <div className={Style.Security_body}>
      <div id={Style["form_body_02"]}>
        <div className={Style.form_number}>
          <div>
            <p className={Style.form_number_container}>03</p>
            <div id={Style["form_border_02"]} />
          </div>
        </div>
        <div>
          <p className={Style.form_text}>Security</p>
          <div className={Style.form_container_02}>
            <form className={Style.form_body_container}>
              <section className={Style.form_body_container_section_2}>
                <div className={Style.form_body_container_section_form}>
                  <label htmlFor="email" className={Style.form_body_label}>
                    Email :
                  </label>
                  <input
                    id="emailS"
                    name="email"
                    type="text"
                    onChange={handleChange}
                    placeholder="Enter your Email"
                    disabled={!show}
                    className={show ? Style.form_input : Style.form_output}
                    style={{ width: "auto" }}
                  />
                </div>
                <div className={Style.form_body_container_section_form}>
                  <label htmlFor="password" className={Style.form_body_label}>
                    Password:
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    placeholder="Enter your Password"
                    disabled={!show}
                    className={show ? Style.form_input : Style.form_output}
                    style={{ width: "240px" }}
                  />
                </div>
              </section>
            </form>
            <div className={Style.form_body_button_container}>
              {!show ? (
                <button
                  onClick={() => setShow(true)}
                  className={Style.form_body_button}
                >
                  Modify
                  <EditIcon style={{ marginLeft: "3px" }} />
                </button>
              ) : (
                <SaveModal handleSave={handleSave} setShow={setShow}>
                  Save
                </SaveModal>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Security;
