import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box, Card, CardMedia } from "@mui/material";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const RecentEvents = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    async function getData() {
      try {
        const res = await axios.get("/api/events/");
        const array = [];
        array.push(...res.data.data);
        //array.push(...res.data);
        setEvents(array);
      } catch (error) {
        //console.log(error);
      }
    }
    getData();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: { md: "1vh", xs: 0 },
        }}
      >
        <Divider />
        <TitleText sx={{ fontSize: { md: "25px", sm: "20px", xs: "18px" } }}>
          RECENT EVENTS
        </TitleText>
        <Divider />
      </Box>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          //justifyContent: "center",
          //alignItems: "center",
          width: "80vw",
          alignSelf: "center",
        }}
        container
      >
        {events.map((event, key) => {
          return (
            <Grid xl={3} lg={3} md={4} sm={6} xs={12} item key={key}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flexShrink: 1,
                  //margin: "2vw",
                  margin: "20px 2vw",
                  padding: "0 5%",
                  paddingTop: "5%",
                  paddingBottom: "4%",
                  height: {
                    // xl: "425px",
                    lg: "25vh",
                    md: "22vh",
                    sm: "23vh",
                    xs: "25vh",
                  },
                  //width: "",
                }}
                //id={event.id}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardMedia
                    component={"img"}
                    image={event.photo}
                    sx={{ width: "100%", height: "16vh" }}
                    lg={{ width: "100%", height: "40vh" }}
                    md={{ width: "100%", height: "12vh" }}
                    sm={{ width: "100%", height: "9vh" }}
                    alt="event image"
                  />
                </Box>
                <StyledLink
                  to="/events"
                  sx={{
                    alignSelf: {
                      md: "flex-end",
                      xs: "center",
                      marginTop: "10px",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      backgroundColor: "#805C00",
                      borderRadius: "5px",
                      padding: "3px",
                      width: "fit-content",
                      //height: "38px",
                      //marginTop: { md: 0, xs: "1vh" },
                      //marginBottom: { md: 0, xs: "1vh" },
                      //marginRight: { md: 0, xs: "1vh" },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      //margin: "5%",
                    }}
                  >
                    <Text>See More</Text>
                    <ArrowForwardIosIcon
                      sx={{
                        alignSelf: "center",
                        color: "white",
                        textAlign: "center",
                        fontSize: { md: "10px", xs: "14px" },
                      }}
                    />
                  </Grid>
                </StyledLink>
              </Card>
            </Grid>
          );
        })}
      </Grid> */}
    </Box>
  );
};
const StyledLink = styled(Link)({
  textDecoration: "none",
});
const TitleText = styled(Typography)({
  color: "#926A05",
  fontWeight: "500",
  fontSize: "25px",
  margin: "20px",
  textAlign: "center",
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "10px",
  whiteSpace: "nowrap",
  textAlign: "center",
  textDecoration: "none",
});
const Divider = styled(Box)({
  backgroundColor: "#293B56",
  width: "90px",
  height: "1px",
});
export default RecentEvents;
