import React, { useState } from "react";
import Header from "./Header";
import { useSelector } from "react-redux";
import PrayerWall from "../../components/communityScreen/prayerWall/PrayerWall";
import ShareYourStory from "../../components/communityScreen/shareYourStory/ShareYourStory";
import Locations from "../../components/communityScreen/locations/Locations";
import Style from "./Style.module.css";
import Notification from "../../components/Notification";
function CommunityScreen() {
  const [selectedTab, setSelectedTab] = useState("prayer");
  const user = useSelector((state) => state.userReducer.user);
  const isAuth = useSelector((state) => state.userReducer.isAuth);

  return (
    <>
      {/* header */}
      <Header selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <Notification />
      {selectedTab === "prayer" ? (
        <div className={Style.prayer}>
          <PrayerWall user={user} isAuth={isAuth} />
        </div>
      ) : selectedTab === "story" ? (
        <div className={Style.story}>
          <ShareYourStory user={user} isAuth={isAuth} />
        </div>
      ) : (
        <div>
          <Locations user={user} />
        </div>
      )}
    </>
  );
}

export default CommunityScreen;
