import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

function OneCard({ img, children, url, handleClick, clicked, id }) {
  const [color, setColor] = useState("#B78400");
  return (
    <Card style={{ width: "18rem", margin: "3vh 0vw", height: "405px" }}>
      <Card.Img variant="top" src={img} />
      <Card.Body>
        <Card.Text
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "11px",
            lineHeight: "19px",
            textAlign: "left",
            color: "#5F5F5F",
          }}
          id={id}
        >
          {children}
        </Card.Text>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant=""
            style={{
              color: "#FFFFFF",
              backgroundColor: clicked !== url ? "#1C3D47" : color,
              fontSize: "12px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              width: "113px",
              height: "41px",
            }}
            onClick={() => {
              handleClick(url);
            }}
            onMouseEnter={() => {
              setColor("#B78400");
            }}
          >
            {url}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default OneCard;
