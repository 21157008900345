import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "axios";
import qs from "qs";
import {
  TextField,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Icon,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as Yup from "yup";
import { useFormik } from "formik";
import ocmLogo from "../../assets/icons/ocm-logo.svg";
import ocmLogoBlack from "../../assets/icons/ocm-logo-black.svg";
import checkedIcon from "../../assets/icons/checked.png";
import uncheckedIcon from "../../assets/icons/checkBox.png";
import googleIcon from "../../assets/icons/google-icon.svg";
import facebookIcon from "../../assets/icons/facebook-icon.svg";
import Notification from "../../components/Notification";
import Modale from "./Modale";
import { useDispatch } from "react-redux";
import { login } from "../../js/actions/userActions";

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const errors = useSelector(state => state.userReducer.errors)

  const formSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleOnSubmit = async (values, actions) => {
    // console.log('valuse', values)

    dispatch(login(values, navigate));
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: formSchema,
    onSubmit: handleOnSubmit,
  });

  //GOOGLE AUTHENTICATION STARTS
  // const googleAuth = (token) => {
  //   const formData = {
  //     token,
  //   };
  //   const url = `${process.env.REACT_APP_BASE_URL}/googleAuth`;
  //   try {
  //     const options = {
  //       method: "POST",
  //       headers: { "content-type": "application/x-www-form-urlencoded" },
  //       data: qs.stringify(formData),
  //       url,
  //     };
  //     axios(options)
  //       .then(async (result) => {
  //         localStorage.setItem("token", result.data.token);
  //         window.location.href = "/";
  //       })
  //       .catch(function (error) {
  //         console.log("Error Msg ax:", error);
  //       });
  //   } catch (error) {
  //     console.log("Error Msg:", error);
  //   }
  // };

  // const handleCallbackResponse = (response) => {
  //   googleAuth(response.credential);
  // };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     callback: handleCallbackResponse,
  //     context: "signup",
  //   });

  //   google.accounts.id.renderButton(document.getElementById("signInDiv"), {
  //     theme: "outline",
  //     size: "large",
  //   });
  // }, []);

  //GOOGLE AUTHENTICATION ENDS

  return (
    <div style={{ height: "100vh", width: "100%", backgroundColor: "#F5F5F5" }}>
      <Notification />
      <ThemeProvider theme={theme}>
        <Grid
          sx={{
            backgroundColor: "#F5F5F5",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid
            container
            item
            xs={0}
            md={6}
            style={{
              backgroundImage: `url(https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/generous.svg)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              //height: { md: "94vh", xs: "100vh" },
              flexDirection: "column",
              justifyContent: "space-between",
              //alignItems: "center",
              padding: "5vh",
            }}
            sx={{ display: { md: "flex", xs: "none" } }}
          >
            <Grid
              //item
              //xs={1}
              sx={{ alignSelf: "flex-start" }}
            >
              <img src={ocmLogo} alt="ocm-logo" />
            </Grid>
            {/* Welcome */}
            <Grid
              //container
              //item
              //xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                //marginLeft: "5vw",
                alignSelf: "center",
                //alignSelf: "center",
                //backgroundColor: "blue",
                //alignItems: "flex-end",
                //justifyContent: "flex-end",

                //,
              }}
            >
              <Text
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontSize: "40px",
                  lineHeight: "36px",
                  textAlign: "center",
                  marginBottom: "3vh",
                }}
              >
                Welcome Back !
              </Text>
              <Text
                sx={{
                  width: "35vw",
                  marginBottom: "3vh",
                  lineHeight: "26px",
                  fontSize: "20px",
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                Nations for Christ.
                <br />
                Reaching the nations of the world with the gospel of love, peace
                and hope. Demonstrating the love and the power of God to
                humanity.
              </Text>
            </Grid>

            <Grid
              //item
              //xs={5}
              style={{ flexDirection: "row" }}
              sx={{
                display: "flex",
                alignSelf: "flex-end",
                //alignItems: "flex-end",
                //justifyContent: "flex-end",
                //width: "90vw",
                //backgroundColor: "red",
              }}
            >
              <Text>Don't have an account ?&nbsp;</Text>
              <Link to="/signup/0">
                <Grid style={{ flexDirection: "row" }} sx={{ display: "flex" }}>
                  <Text sx={{ textDecoration: "underline", color: "#926900" }}>
                    Sign up&nbsp;
                  </Text>
                  <Text sx={{ textDecoration: "underline" }}>here</Text>
                </Grid>
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            direction="column"
            sx={{
              padding: "5vh",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              display: "flex",
              height: { md: "100vh", xs: "100%" },
            }}
          >
            {/* back to home */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { md: "row-reverse", xs: "row" },
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  //width: "10%",
                  alignSelf: "flex-start",
                  display: { md: "none", xs: "flex" },
                }}
              >
                <img
                  src={ocmLogoBlack}
                  alt="ocm-logo"
                  // height={"100%"}
                  width={"50%"}
                />
              </Grid>
              <Link to="/">
                <Box
                  style={{ flexDirection: "row" }}
                  sx={{
                    display: "flex",
                    alignSelf: "flex-end",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowBackIosIcon sx={{ color: "black", fontSize: 15 }} />
                  <Text
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "16px",
                      color: "black",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    Back To Home
                  </Text>
                </Box>
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "5vh",
              }}
            >
              <Text
                sx={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  background:
                    "linear-gradient(95.52deg, #00454b 8.71%, #b78400 100%)",
                  marginBottom: "2vh",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Log In
              </Text>
              <Text
                sx={{
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#1C3D47",
                }}
              >
                Login to your account
              </Text>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    //alignItems: "center",
                    marginTop: "5vh",
                  }}
                >
                  <Text sx={{ color: "#000210" }}>Email</Text>
                  <StyledTextField
                    fullWidth
                    id="email"
                    name="email"
                    margin="dense"
                    type={"email"}
                    placeholder="Enter Your Email"
                    hiddenLabel
                    variant="outlined"
                    size="normal"
                    sx={{ width: { md: "30vw", xs: "70vw" } }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <Text sx={{ color: "#000210" }}>Password</Text>
                  <StyledTextField
                    fullWidth
                    id="password"
                    name="password"
                    margin="dense"
                    type="password"
                    placeholder="Enter Your Password"
                    hiddenLabel
                    variant="outlined"
                    size="normal"
                    sx={{ width: { md: "30vw", xs: "70vw" } }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>

                {/* forget password */}
                <Box>
                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: { md: "space-between", xs: "center" },
                      //bgcolor: "red",
                      width: { md: "30vw", xs: "70vw" },
                      marginTop: { md: "0px", xs: "10px" },
                    }}
                  >
                    <FormControlLabel
                      sx={{ display: { md: "flex", xs: "none" } }}
                      control={
                        <Checkbox
                          defaultChecked
                          icon={<img alt="unchecked" src={uncheckedIcon} />}
                          checkedIcon={<img alt="checked" src={checkedIcon} />}
                        />
                      }
                      label={
                        <Text
                          sx={{
                            color: "#000C2B",
                            fontSize: "10px",
                          }}
                        >
                          Keep me logged in
                        </Text>
                      }
                    />
                    <Modale />
                  </FormGroup>
                </Box>
                {/* login button */}
                <ConfirmButton
                  type="submit"
                  sx={{
                    width: { md: "20vw", xs: "50vw" },
                    marginTop: "5vh",
                    alignSelf: "center",
                  }}
                >
                  Log In
                </ConfirmButton>
              </Box>
            </form>
            {/* "or" line */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "5vh",
                alignItems: "center",
              }}
            >
              <Divider />
              <Text sx={{ color: "black", marginX: "1vw" }}>Or</Text>
              <Divider />
            </Box> */}
            {/* social buttons */}
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: { md: "30vw", xs: "60vw" },
                marginTop: "5vh",
              }}
            >
              {/* <SocialButton
                sx={{
                  textTransform: "revert",
                  justifyContent: "center",
                  alignItems: "center",
                  width: { md: "14vw", xs: "15vw" },
                }}
                onClick={() =>
                  window.location.replace(
                    "https://api.onoriocutane.org/auth/google"
                  )
                }
              >
                <Icon sx={{ height: "100%" }}>
                  <img
                    alt="google"
                    src={googleIcon}
                    height={"100%"}
                    width={"100%"}
                  />
                </Icon>
                <Text
                  sx={{
                    color: "gray",
                    marginLeft: "5px",
                    display: { md: "flex", xs: "none" },
                  }}
                >
                  Connect with Google
                </Text>
              </SocialButton> */}
              {/* <div id="signInDiv"></div> */}
              {/* <SocialButton
                sx={{
                  textTransform: "revert",
                  width: { md: "14vw", xs: "15vw" },
                }}
                onClick={() =>
                  window.location.replace(
                    "https://api.onoriocutane.org/auth/facebook"
                  )
                }
              >
                <Icon sx={{ height: "100%" }}>
                  <img
                    alt="facebook"
                    src={facebookIcon}
                    height={"100%"}
                    width={"100%"}
                  />
                </Icon>
                <Text
                  sx={{
                    color: "gray",
                    marginLeft: "5px",
                    display: { md: "flex", xs: "none" },
                  }}
                >
                  Connect with Facebook
                </Text>
              </SocialButton> */}
            </Grid>
            <Grid
              sx={{
                display: { md: "none", xs: "flex" },
                flexDirection: "row",
                alignSelf: "center",
                marginTop: "5vh",
              }}
            >
              <Text sx={{ color: "black" }}>Don't have an account ?&nbsp;</Text>
              <Link to="/signup/0">
                <Grid style={{ flexDirection: "row" }} sx={{ display: "flex" }}>
                  <Text
                    sx={{
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    Sign up here
                  </Text>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default LoginScreen;

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "left",
});
const StyledTextField = styled(TextField)({
  //backgroundColor: "#fff",
  fontSize: "12px",
  input: {
    fontSize: "12px",
    backgroundColor: "#fff",
  },
  "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
});
const ConfirmButton = styled(Button)({
  background: "linear-gradient(92.1deg, #293b56 34.28%, #694c00 101.52%)",
  borderRadius: "8px",
  color: "white",
  textTransform: "capitalize",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "#969696",
  },
});
const Divider = styled(Box)({
  backgroundColor: "#5E4300",
  width: "100px",
  height: "1px",
});

const SocialButton = styled(Button)({
  backgroundColor: "white",
  //width: { md: "10vw", xs: "15vw" },
  //height: { md: "13vw", xs: "25vw" },
  color: "#676767",
  border: "none",
  fontSize: "14px",
  fontWeight: "510",
  boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.3)",
  borderRadius: "2px",
  display: "flex",
  direction: "row",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});
