import React from "react";
import Style from "./Style.module.css";

function Header() {
  return (
    <div className={Style.bookingHeader}>
      <p className={Style.title}>Booking Now !</p>
      <img alt="gold line" src={require("../../../assets/ligne.png")} />
      <p className={Style.text}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen.
      </p>
    </div>
  );
}

export default Header;
