import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import styled from "styled-components";
import Style from "./Style.module.css";

function Modale() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = async () => {
    try {
      await axios.put("/api/user/forgotpassword", { email });
      setShow(false);
    } catch (error) {
      //console.log("eror", error);
    }
  };

  return (
    <>
      <Button
        sx={{
          color: "#000C2B",
          textDecoration: "underline",
          fontSize: "10px",
          textTransform: "capitalize",
        }}
        onClick={handleShow}
      >
        Forget Your Password ?
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Resetting Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className={Style.modal_body}>
          <Text htmlFor="forgettingPassword" className="">
            Please insert your email :
          </Text>
          <StyledTextField
            id="forgettingPassword"
            name="forgettingPassword"
            type="text"
            onChange={handleChange}
            placeholder="email"
            className={Style.Modal_body_input}
            sx={{ marginTop: "20px", width: "95%" }}
          />
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Send Email
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Modale;

const StyledTextField = styled(TextField)({
  //backgroundColor: "#fff",
  fontSize: "12px",
  marginLeft: "5px",
  // width: '200px',
  input: {
    fontSize: "12px",
    backgroundColor: "#fff",
  },
  "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
});

const Text = styled(Typography)({
  color: "black",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "18px",
  textAlign: "left",
});
