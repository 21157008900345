import React from 'react';
import style from './style.module.css';
import DOMPurify from 'dompurify';
import he from 'he';

function PostDetails({ devotional }) {
  function convertTimestampToFormattedDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
  }

  const sanitizedHTML = he.decode(DOMPurify.sanitize(devotional.detail));
  const currentUrl = window.location.href;

  return (
    <div className="col-sm-12 col-md-8">
      <div className="content">
        <div className={style['post-content']}>
          <div className={style['post-title']}>
            <h1 className={style.title}>{devotional.title}</h1>
          </div>
          <div className={style['post-meta']}>
            <span>
              <i className="icon-clock"></i>&nbsp;&nbsp;{convertTimestampToFormattedDate(devotional.timestamp)}
            </span>
          </div>
          <div className={style['post-image']}>
  <img
    src={devotional.image}
    className="img-responsive center-image"
    alt={devotional.title}
  />
</div> 
          <br />
          <br />
          <div className={style['post-summary']} dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
          
          <div className={style['post-tags']}>
            <h3 className={style['tags-title']}>Tags</h3>
            <ul className={style['tag-list']}>
              <li>
                  <a href={`/devotional-detail/tag/${devotional.tag}`}>#{devotional.tag}</a>
              </li>
            </ul>
          </div>

          <div className={style['post-share']}>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className={`${style['btn-share']} ${style['share']} ${style['facebook']}`}
            >
              <i className="fa fa-facebook"></i>
              <span className={style['hidden-sm']}>Facebook</span>
            </a>
            <a
              href={`https://twitter.com/share?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent('Truist Financial Bank')}`}
              target="_blank"
              rel="noopener noreferrer"
              className={`${style['btn-share']} ${style['share']} ${style['twitter']}`}
            >
              <i className="icon-twitter"></i>
              <span className="hidden-sm">Twitter</span>
            </a>
            <a
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent('Onorio Cutane Ministeries- ' + currentUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className={`${style['btn-share']} ${style['share']} ${style['whatsapp']}`}
            >
              <i className="icon-whatsapp"></i>
              <span className="hidden-sm">Whatsapp</span>
            </a>
            <a
              href={`http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className={`${style['btn-share']} ${style['share']} ${style['linkedin']}`}
            >
              <i className="fa fa-linkedin"></i>
              <span className="hidden-sm">Linkedin</span>
            </a>
          </div>

          <div className="col-sm-12 col-xs-12">
            <div className="row">
              <div className={style['bn-bottom-post']}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostDetails;
