import React, { useEffect, useState, useRef } from "react";
import countryState from "country-state-city";
import moment from "moment";
import Style from "./style.module.css";
import SaveModal from "./SaveModal";
import EditIcon from '@mui/icons-material/Edit';

const getCityId = (country) => {
  return countryState.getAllCountries().find((el) => {
    return el.name === country;
  });
};

const countries = countryState.getAllCountries().map((country) => ({
  name: country.name,
  id: country.id,
}));

function BasicInfo({ user, onSave }) {
  let birthday = useRef();
  const [cities, setCities] = useState([]);
  const [userData, setUserData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!!user) {
      setUserData(user);
    }
  }, [user]);

  const getDate = () => {
    const date = moment(user.birth_date)
      .calendar()
      .split("/")
      .reverse();
    let a = date[1];
    let b = date[2];
    date[2] = a;
    date[1] = b;
    const dateBirth = date.join("-");
    return dateBirth;
  };

  useEffect(() => {
    getCities();
  }, [userData.country]);

  const getCities = () => {
    const { id } = getCityId(userData.country) || "1";
    const data = countryState.getStatesOfCountry(id);
    setCities(data);
  };

  const handleChange = (e) => {
    return setUserData((userData) => {
      return { ...userData, [e.target.name]: e.target.value };
    });
  };

  const handleChangeCR = (e) => {
    const { id } = getCityId(e.target.selectedOptions[0].value) || "1";
    const data = countryState.getStatesOfCountry(id);
    setCities(data);
    return setUserData((userData) => {
      return { ...userData, country: e.target.selectedOptions[0].value };
    });
  };

  const handleChangeState = ({ e, state }) => {
    return setUserData((userData) => {
      return { ...userData, [state]: e.target.selectedOptions[0].value };
    });
  };

  const handleSave = () => {
    onSave(userData);
    setShow(!show);
  };

  return (
    <section id={Style["form_Body_01"]}>
      <div className={Style.form_number}>
        <div>
          <p className={Style.form_number_container}>01</p>
          <div className={Style.form_border} />
        </div>
      </div>
      <div>
        <p className={Style.form_text}>Basic Info</p>
      <div className={Style.form_container}>
        <form className={Style.form_body_container}>
          <section className={Style.form_body_container_section}>
            <div className={Style.form_body_container_section_form}>
              <label htmlFor="firstname" className={Style.form_body_label}>
                First Name :
              </label>
              <input
                id="firstname"
                name="firstname"
                type="text"
                onChange={handleChange}
                placeholder={userData.firstname}
                disabled={!show}
                className={show ? Style.form_input : Style.form_output}
              />
            </div>
            <div className={Style.form_body_container_section_form}>
              <label htmlFor="lastname" className={Style.form_body_label}>
                Last Name :
              </label>
              <input
                id="lastname"
                name="lastname"
                type="text"
                onChange={handleChange}
                placeholder={userData.lastname}
                disabled={!show}
                className={show ? Style.form_input : Style.form_output}
              />
            </div>
            <div className={Style.form_body_container_section_form}>
              <label htmlFor="birth_date" className={Style.form_body_label}>
                Birth Date :
              </label>
              <input
                id="birth_date"
                  name="birth_date"
                  ref={birthday}
                  placeholder={getDate()}
                  onFocus={() => (birthday.current.type = "date")}
                  onBlur={() => (birthday.current.type = "text")}
                  onChange={handleChange}
                  disabled={!show}
                  className={show ? Style.form_input : Style.form_output}
                //value={getDate()}
              />
            </div>
            <div className={Style.form_body_container_section_form}>
              <label htmlFor="gender" className={Style.form_body_label}>
                Gender :
              </label>
              <select
                id="gender"
                onChange={(e) => handleChangeState({ e, state: "gender" })}
                disabled={!show}
                className={show ? Style.form_input : Style.form_output}
                value={userData.gender}
              >
                <option value="">Select your gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </section>
          <section className={Style.form_body_container_section}>
            <div className={Style.form_body_container_section_form}>
              <label htmlFor="civil_status" className={Style.form_body_label}>
                Civil Status :
              </label>
              <select
                id="civil_status"
                onChange={(e) =>
                  handleChangeState({ e, state: "civil_status" })
                }
                disabled={!show}
                className={show ? Style.form_input : Style.form_output}
                value={userData.civil_status||""}
              >
                <option value="">Select your civil status</option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
                <option value="Widow">Widow</option>
              </select>
            </div>
            <div className={Style.form_body_container_section_form}>
              <label
                htmlFor="countryOfOrigin"
                className={Style.form_body_label}
              >
                Country of Origin :
              </label>
              <select
                id="countryOfOrigin"
                onChange={(e) =>
                  handleChangeState({ e, state: "countryOfOrigin" })
                }
                disabled={!show}
                className={show ? Style.form_input : Style.form_output}
                value={userData.countryOfOrigin||""}
              >
                <option value="">Select your country of Origin</option>
                {countries.map((country) => {
                  return (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={Style.form_body_container_section_form}>
              <label htmlFor="country" className={Style.form_body_label}>
                Country of Residence :
              </label>
              <select
                id="country"
                onChange={handleChangeCR}
                placeholder={userData.country}
                disabled={!show}
                className={show ? Style.form_input : Style.form_output}
                value={userData.country||""}
              >
                <option value="">Select your country of Residence</option>
                {countries.map((country) => {
                  return (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={Style.form_body_container_section_form}>
              <label htmlFor="city" className={Style.form_body_label}>
                City :
              </label>
              <select
                id="city"
                onChange={(e) => handleChangeState({ e, state: "city" })}
                placeholder={userData.city}
                disabled={!show}
                className={show ? Style.form_input : Style.form_output}
                value={userData.city||""}
              >
                <option value="">Select your country of origin</option>
                {cities.map((city) => {
                  return (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </section>
        </form>
        <div className={Style.form_body_button_container}>
          {!show ? (
            <button
              onClick={() => setShow(true)}
              className={Style.form_body_button}
            >
              Modify
              <EditIcon style={{marginLeft:'3px'}} />
            </button>
          ) : (
            <SaveModal handleSave={handleSave} setShow={setShow}>
              Save
            </SaveModal>
          )}
        </div>
        </div>
      </div>
    </section>
  );
}

export default BasicInfo;
