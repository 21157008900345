import React, { useEffect, useState } from "react";
import Header from "../../components/eventsScreen/header/Header";
import Events from "../../components/eventsScreen/events/Events";
import axios from "axios";

function EventsScreen() {
  const [e, setEvents] = useState([]);

  async function getData() {
    try {
      const res = await axios.get("/api/events");
      setEvents(res.data.data);
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);
  //console.log(e);

  return (
    <>
      {/* header */}
      <Header />

      {/* body */}
      <Events events={e} />
    </>
  );
}

export default EventsScreen;
