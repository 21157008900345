import axios from "axios";
import {
  CLEAR_ERROR,
  CURRENT_USER,
  FAIL_USER,
  LOAD_USER,
  LOGIN_USER,
  LOGOUT_USER,
} from "../constants/ActionTypes";

//Login
export const login = (user, navigate) => async (dispatch) => {
  dispatch({ type: LOAD_USER });
  try {
    const response = await axios.post("/api/user/login", user, {
      withCredentials: true,
    });
    dispatch({ type: LOGIN_USER, payload: response.data });
    navigate("/");
  } catch (error) {
    dispatch({ type: FAIL_USER, payload: error });
  }
};

//Register
export const register = (user, navigate) => async (dispatch) => {
  dispatch({ type: LOAD_USER });
  try {
    await axios.post("/api/user/signup", user);
    dispatch(login({ email: user.email, password: user.password }, navigate));
  } catch (error) {
    dispatch({ type: FAIL_USER, payload: error });
  }
};

//currently
export const current = () => async (dispatch) => {
  dispatch({ type: LOAD_USER });
  try {
    // const response = await axios.get("/api/user/sess", {
    //   withCredentials: true,
    // });
    const id = localStorage.getItem('token')
    const response = await axios.get(`/api/user/search/${id}`)
    if (response.data) {
      return dispatch({ type: CURRENT_USER, payload: response.data.data });
    }
    return dispatch({ type: FAIL_USER, payload: null });
  } catch (error) {
    return dispatch({ type: FAIL_USER, payload: null });
  }
};

//Logout
export const logout = () => async (dispatch) => {
  dispatch({ type: LOAD_USER });
  try {
    await axios.delete("/api/user/logout", {
      withCredentials: true,
    });
    dispatch({ type: LOGOUT_USER });
  } catch (error) {
    dispatch({ type: FAIL_USER, payload: error });
  }
};

//update user
export const updateUser = (data) => async (dispatch) => {
  try {
    const res = await axios.put("/api/user/updateBasic/" + data._id, data, {
      withCredentials: true,
    });
    dispatch({type: CURRENT_USER, payload: res.data});
  } catch (error) {
    dispatch({ type: FAIL_USER, payload: error.response.data });
  }
};

export const clearErrors = () => {
  return { type: CLEAR_ERROR };
};
