import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AiOutlineMenu } from "react-icons/ai";
import Home from "@mui/icons-material/Home";
import Info from "@mui/icons-material/Info";
import Group from "@mui/icons-material/Group";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { AiFillGift } from "react-icons/ai";
import { Link } from "react-router-dom";
import style from "./Style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../js/actions/userActions";

function MobileNavidationBar() {
  const [show, setShow] = React.useState(false);
  const [openPress, setOpenPress] = React.useState(false);
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.userReducer.isAuth);

  const handleClickPress = () => {
    setOpenPress(!openPress);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShow(open);
  };

  const list = () => (
    <Box
      sx={{ backgroundColor: "#293B56", paddingTop: "5px" }}
      role="presentation"
      aria-labelledby="nested-list-subheader"
      className="mobileNavBar"
    >
      {/* Home Link */}
      <Link
        to="/"
        className={style.mobileNavBar__link}
        onClick={toggleDrawer(false)}
      >
        <ListItemButton>
          <ListItemIcon>
            <Home style={{ color: "white" }} />
          </ListItemIcon>

          <ListItemText primary="Home" />
        </ListItemButton>
      </Link>
      {/* About link */}
      <Link
        to="/about"
        className={style.mobileNavBar__link}
        onClick={toggleDrawer(false)}
      >
        <ListItemButton>
          <ListItemIcon>
            <Info style={{ color: "white" }} />
          </ListItemIcon>

          <ListItemText primary="About" />
        </ListItemButton>
      </Link>
      {/* Community Link */}
      <Link
        to="/community"
        className={style.mobileNavBar__link}
        onClick={toggleDrawer(false)}
      >
        <ListItemButton>
          <ListItemIcon>
            <Group style={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Community" />
        </ListItemButton>
      </Link>
      {/* Events Link */}
      <Link
        to="/events"
        className={style.mobileNavBar__link}
        onClick={toggleDrawer(false)}
      >
        <ListItemButton>
          <ListItemIcon>
            <EventAvailableOutlinedIcon style={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Events" />
        </ListItemButton>
      </Link>
      {/* Press dropdown */}

      <Link
        to="/news"
        className={style.mobileNavBar__link}
        onClick={toggleDrawer(false)}
      >
        <ListItemButton
          onClick={handleClickPress}
          className={style.mobileNavBar__link}
        >
          <ListItemIcon>
            <NewspaperRoundedIcon style={{ color: "white" }} />
          </ListItemIcon>

          <ListItemText primary="News" />
        </ListItemButton>
      </Link>
      {/* Daily Devotion dropdown */}
      <Link
        to="/daily-devotion"
        className={style.mobileNavBar__link}
        onClick={toggleDrawer(false)}
      >
        <ListItemButton
          onClick={handleClickPress}
          className={style.mobileNavBar__link}
        >
          <ListItemIcon>
            <VolunteerActivismIcon style={{ color: "white" }} />
          </ListItemIcon>

          <ListItemText primary="Daily Devotion" />
        </ListItemButton>
      </Link>
      {/* Offering link */}
      <Link
        to="/offering"
        className={style.mobileNavBar__link}
        onClick={toggleDrawer(false)}
      >
        <ListItemButton>
          <ListItemIcon>
            <AiFillGift size={25} style={{ color: "white" }} />
          </ListItemIcon>

          <ListItemText primary="Offering" />
        </ListItemButton>
      </Link>
      {/* translation button */}
      {/* <ListItemButton
        onClick={handleClickTranslation}
        className={style.mobileNavBar__link}
      >
        <ListItemIcon>
          <SiGoogletranslate size={25} style={{ color: "white" }} />
        </ListItemIcon>
        <ListItemText primary="EN" />
        {openTranslation ? (
          <ExpandLess style={{ color: "white" }} />
        ) : (
          <ExpandMore style={{ color: "white" }} />
        )}
      </ListItemButton>
      <Collapse in={openTranslation} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} className={style.mobileNavBar__link}>
            <ListItemIcon>
              <img alt="usa flag" src={require("../../assets/usa 2.png")} />
            </ListItemIcon>
            <ListItemText primary="English" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} className={style.mobileNavBar__link}>
            <ListItemIcon>
              <img
                alt="usa flag"
                src={require("../../assets/Flag_of_Portugal 2.png")}
              />
            </ListItemIcon>
            <ListItemText primary="Portugues" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} className={style.mobileNavBar__link}>
            <ListItemIcon>
              <img
                alt="usa flag"
                src={require("../../assets/2417841-illustrationle-du-drapeau-france-gratuit-vectoriel 2.png")}
              />
            </ListItemIcon>
            <ListItemText primary="French" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} className={style.mobileNavBar__link}>
            <ListItemIcon>
              <img
                alt="usa flag"
                src={require("../../assets/32987102b310ea00dfeb5d4a25f58a3d 1.png")}
              />
            </ListItemIcon>
            <ListItemText primary="Spanish" />
          </ListItemButton>
        </List>
      </Collapse> */}

      {/* Devide the navbar */}
      <Divider />

      {isAuth ? (
        <>
          {/* profile */}
          <ListItemButton>
            <ListItemIcon>
              <AccountCircleIcon style={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/profile" className={style.mobileNavBar__link}>
              <ListItemText primary="Profile" />
            </Link>
          </ListItemButton>
          {/* logout */}
          <ListItemButton>
            <ListItemIcon>
              <LogoutRoundedIcon style={{ color: "white" }} />
            </ListItemIcon>
            <button
              onClick={() => {
                dispatch(logout());
              }}
              className={style.mobileNavBar__link}
              style={{
                border: "none",
                textDecoration: "none",
                backgroundColor: "transparent",
                margin: "0px",
                position: "relative",
                left: "-7px",
              }}
            >
              <ListItemText primary="Logout" />
            </button>
          </ListItemButton>
        </>
      ) : (
        <>
          {/* login */}
          <ListItemButton>
            <ListItemIcon>
              <LoginRoundedIcon style={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/login" className={style.mobileNavBar__link}>
              <ListItemText primary="Login" />
            </Link>
          </ListItemButton>

          {/* signup */}
          <ListItemButton>
            <ListItemIcon>
              <PersonAddAltIcon style={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/signup/0" className={style.mobileNavBar__link}>
              <ListItemText primary="Sign Up" />
            </Link>
          </ListItemButton>
        </>
      )}
    </Box>
  );

  return (
    <div className={style.navBarMobile}>
      <div>
        <img
          alt="onorio logo"
          src={require("../../assets/onorio-logo-white 1.png")}
          style={{ width: "auto", height: "40px" }}
        />
      </div>
      <div>
        <Button onClick={toggleDrawer(true)}>
          <AiOutlineMenu size={25} color="white" />
        </Button>
        <SwipeableDrawer
          anchor="bottom"
          open={show}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </div>
    </div>
  );
}

export default MobileNavidationBar;
