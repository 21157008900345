import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";

const BeliefRow = ({ imageSource, content, direction, withoutSpace }) => {
  return (
    <>
      {direction ? (
        <Row
          sx={{
            alignItems: "center",
            //marginTop: { md: 0, xs: "2vh" },
            marginBottom: { md: 0, xs: "4vh" },
            alignSelf: { xs: "center", md: "auto" },
          }}
        >
          {withoutSpace ? null : <Grid item xs />}
          <Grid>
            <img src={imageSource} alt="Belief" />
          </Grid>
          <Row
            item
            xs
            sx={{ alignItems: "center", marginLeft: { md: 0, xs: "2vw" } }}
          >
            {withoutSpace ? null : <DividerHorizontalGrey />}
            <Text sx={{ width: { md: "30vw", xs: "70vw" } }}>{content}</Text>
          </Row>
        </Row>
      ) : (
        <RowReversed sx={{ alignItems: "center" }}>
          <Grid item xs />
          <Grid>
            <img src={imageSource} alt="Belief" />
          </Grid>
          <RowReversed item xs sx={{ alignItems: "center" }}>
            <DividerHorizontalGrey />
            <Text sx={{ width: "30vw" }}>{content}</Text>
          </RowReversed>
        </RowReversed>
      )}
    </>
  );
};

export default BeliefRow;

const Row = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});
const RowReversed = styled(Grid)({
  display: "flex",
  flexDirection: "row-reverse",
});

const Text = styled(Typography)({
  color: "#6C6C6C",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "23px",
  textAlign: "left",
  padding: "1% 0%",
});

const DividerHorizontalGrey = styled(Box)({
  backgroundColor: "#BBBBBB",
  height: "1px",
  marginLeft: "4%",
  marginRight: "4%",
  width: "5vw",
});
