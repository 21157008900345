import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Style from './style.module.css';

function SaveModal({ handleSave, children, setShow}) {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setShow(false)
  };
  const handleShow = () => {
    setShowModal(true)
  };

  const onSave = () => {
    handleSave();
    setShowModal(false);
  }

  return (
    <>
      <button variant="primary" onClick={handleShow} className={Style.form_body_button}>
        {children}
      </button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>Are you confirm your update</Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className={Style.saveModal_button}>
            Close
          </button>
          <button onClick={onSave} className={Style.saveModal_button}>
            Save 
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SaveModal