import React from "react";
import { Button } from "react-bootstrap";
import Style from "./Style.module.css";

function Article2({ title, content, img }) {
  return (
    <>
      <div className={Style.articleTwoBox}>
        <div
          style={{
            width: "347.28px",
            height: "1px",
            background: "#E2E0D9",
            borderRadius: "5px",
            marginBottom: "10%",
          }}
        >
          <div
            style={{
              width: "41.6px",
              height: "1px",
              float: "left",
              background: "#B78400",
              borderRadius: "5px",
            }}
          ></div>
        </div>
        <p className={Style.articleTwoTitle}>{title}</p>
        <p className={Style.articleTwoContent}>{content}</p>
        <Button
          variant=" "
          style={{
            backgroundColor: "#6D6D6D",
            fontFamily: "Poppins",
            fontStyle: "normal",
            color: "#B78400",
            float: "right",
            marginRight: "2%",
          }}
        >
          Read More{" >"}
        </Button>
      </div>
      <img className={Style.articleTwo} src={img} alt="OCM" />
    </>
  );
}

export default Article2;
