import React, { useEffect, useState } from "react";
import Header from "./header/Header";
import Article1 from "./articles/Article1";
import Article3 from "./articles/Article3";
import Article2 from "./articles/Article2";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";

function Body() {
  const [press, setPress] = useState([]);

  async function getData() {
    try {
      const res = await axios.get("/api/pressrelease");
      if (res.status === 200) {
        setPress(res.data.data);
      }
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Row style={{ marginTop: "-15%", textAlign: "left" }}>
          <Col>
            <p
              style={{
                fontFamily: "Publico Text",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "48px",
                lineHeight: "70px",
                color: "#1C3D47",
              }}
            >
              Articles
            </p>
          </Col>
          <Col>
            <p
              style={{
                marginTop: "3%",
                fontFamily: "Publico Text",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "35px",
                marginLeft: "-77%",
                /* identical to box height */

                color: " #8D6500",
              }}
            >
              By Onório Cutane
            </p>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              width: "1394px",
              height: "1px",
              background: "#E2E0D9",
              borderRadius: "5px",
              marginBottom: "10%",
            }}
          >
            <div
              style={{
                width: "167px",
                height: "1px",
                float: "left",
                background: "#B78400",
                borderRadius: "5px",
              }}
            ></div>
          </div>
        </Row>
      </Container>
      {press.map((pre, index) => {
        if ((index + 1) % 3 === 0) {
          return (
            <Article3
              title={pre.title}
              content={pre.description}
              img={pre.photo}
            />
          );
        } else if ((index + 2) % 3 === 0) {
          return (
            <Article2
              title={pre.title}
              content={pre.description}
              img={pre.photo}
            />
          );
        } else {
          return (
            <Article1
              title={pre.title}
              content={pre.description}
              img={pre.photo}
            />
          );
        }
      })}
    </>
  );
}

export default Body;
