import React from "react";
import BasicInfo from "./info/BasicInfo";
import Church from './info/Church';
import Security from "./info/Security";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../js/actions/userActions";

function body() {
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const onSave = async(data) => {
    dispatch(updateUser(data));
  };

  return (
    <div style={{ marginTop: "10vh" }}>
      {/* basic info */}
      <BasicInfo user={user} onSave={onSave} />
      {/* church */}
      <Church user={user} onSave={onSave} />
      {/* security */}
      <Security user={user} />
    </div>
  );
}

export default body;
