import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";


const HeaderMobile = ({ selectedTab, setSelectedTab }) => {
  return (
    <>
      <Container>
        {selectedTab === "onorio" && (
          <>
            <img
              style={{ width: "100%", height: "auto" }}
              alt="onorio"
              src="https://storage.googleapis.com/public_ocm/cover-about-page-1.png"
            />
            <Box
              sx={{
                width: { md: "45vw", xs: "90vw" },
                marginBottom: { md: "5vh", sm: "2vh", xs: "0" },
                marginLeft: { md: "15vw", xs: 0 },
                padding: { md: 0, xs: "4vw" },
              }}
            >
              <HeaderText
                sx={{ fontSize: { md: "24px", sm: "20px", xs: "16px" } }}
              >
                About us
              </HeaderText>
              <HeaderText2
                sx={{ fontSize: { md: "40px", sm: "30px", xs: "20px" } }}
              >
                Onório Cutane
              </HeaderText2>
              <Text sx={{ fontSize: { md: "12px", sm: "11px", xs: "10px" } }}>
                Apostle Onório Cutane is a prolific gospel writer and an
                international minister of the Gospel. He runs a global ministry
                with churches in Europe, America, and Africa. His passion is to
                see people free from the plights of the devil and enjoy life
                eternal life in the kingdom of God.
              </Text>
            </Box>
          </>
        )}
        {selectedTab === "janifer" && (
          <>
            <img
              style={{ width: "100%", height: "auto" }}
              alt="onorio"
              src="https://storage.googleapis.com/public_ocm/cover-about-page-2.png"
            />
            <Box
              sx={{
                width: { md: "45vw", xs: "90vw" },
                marginBottom: { md: "5vh", sm: "2vh", xs: "0" },
                marginLeft: { md: "15vw", xs: 0 },
                padding: { md: 0, xs: "4vw" },
              }}
            >
              <HeaderText
                sx={{ fontSize: { md: "24px", sm: "20px", xs: "16px" } }}
              >
                About
              </HeaderText>
              <HeaderText2
                sx={{ fontSize: { md: "40px", sm: "30px", xs: "20px" } }}
              >
                Janifer Cutane
              </HeaderText2>
              <Text sx={{ fontSize: { md: "12px", sm: "11px", xs: "10px" } }}>
                Janifer Cutane is a Mozambican gospel artist. She is a pastor, a
                worshipper, and an intercessor. Moreover, she is a women’s
                leader, marriage counselor, and life coach.
              </Text>
            </Box>
          </>
        )}
        {selectedTab === "ministries" && (
          <>
            <img
              style={{ width: "100%", height: "auto" }}
              alt="onorio"
              src="https://storage.googleapis.com/public_ocm/cover-about-page-1.png"
            />
            <Box
              sx={{
                width: { md: "45vw", xs: "90vw" },
                marginBottom: { md: "7vh", sm: "5vh", xs: "3vh" },
                marginLeft: { md: "15vw", xs: 0 },
                padding: { md: 0, xs: "4vw" },
              }}
            >
              <HeaderText
                sx={{ fontSize: { md: "24px", sm: "20px", xs: "16px" } }}
              >
                About
              </HeaderText>
              <HeaderText2
                sx={{ fontSize: { md: "40px", sm: "30px", xs: "20px" } }}
              >
                Onório Cutane
              </HeaderText2>

              <HeaderText2
                sx={{ fontSize: { md: "40px", sm: "30px", xs: "20px" } }}
              >
                MINISTRIES
              </HeaderText2>
            </Box>
          </>
        )}
        <TabRow
          sx={{
            //justifyContent: "space-between",
            width: { md: "75vw", xs: "100vw" },
          }}
          container
        >
          <StyledButton
            item
            xs={4}
            sx={{
              flexGrow: 1,
              borderRadius: "5px 0px 0px 0px",
              borderBottom:
                selectedTab === "onorio" ? "5px solid #B48200" : null,
            }}
            onClick={() => {
              setSelectedTab("onorio");
            }}
          >
            <TabText sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
              Onório Cutane
            </TabText>
          </StyledButton>
          <StyledButton
            item
            xs={4}
            sx={{
              //width: { md: "25vw", xs: "30vw" },
              flexGrow: 1,
              borderBottom:
                selectedTab === "janifer" ? "5px solid #B48200" : null,
            }}
            onClick={() => {
              setSelectedTab("janifer");
            }}
          >
            <TabText sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
              Janifer Cutane
            </TabText>
          </StyledButton>
          <StyledButton
            item
            xs={4}
            sx={{
              flexGrow: window.screen.width > 370 ? 0.5 : 1,
              borderRadius: "0px 5px 0px 0px",
              borderBottom:
                selectedTab === "ministries" ? "5px solid #B48200" : null,
            }}
            onClick={() => {
              setSelectedTab("ministries");
            }}
          >
            <TabText sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
              Onório Cutane Ministries
            </TabText>
            {/* <Text sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" } }}>
              Ministries
            </Text> */}
          </StyledButton>
        </TabRow>
      </Container>
    </>
  );
};

export default HeaderMobile;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  //height: "70vh",
  //   backgroundImage: `url(${coverOnorio})`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundSize: "100% 100%",
  //   backgroundPosition: "center",
  backgroundColor: "#6f4028",
  justifyContent: "flex-end",
  alignItems: "center",
});
const HeaderText = styled(Typography)({
  color: "#fff",
  fontFamily: "Abril Fatface",
  fontWeight: "400",
  fontSize: "24px",
  //lineHeight: "24px",
  textAlign: "left",
  //marginLeft: "5px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  //padding: "1% 0",
});
const HeaderText2 = styled(Typography)({
  color: "#280801",
  fontFamily: "Abril Fatface",
  fontWeight: "400",
  fontSize: "40px",
  //lineHeight: "48px",
  textAlign: "left",
  //marginLeft: "5px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  //padding: "1% 0",
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "left",
  padding: "3% 0%",
});
const TabRow = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  background:
    "linear-gradient(90.41deg,rgba(194, 194, 194, 0.2) 6.22%,rgba(234, 234, 234, 0.1) 99.85%)",
});
const TabText = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "400",
  fontSize: "18px",

  textAlign: "center",

  whiteSpace: "normal",
});
const StyledButton = styled("button")({
  //borderRadius: "5px 5px 0px 0px",
  background: "transparent",
  border: "1px solid rgba(227, 227, 227, 0.12)",
  "&:hover": {
    //backgroundColor: "grey",
    borderBottom: "5px solid #B48200",
  },
  alignItems: "center",
  height: "70px",
});
