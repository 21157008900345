import React, { useState } from "react";
import OneCard from "../OneCard";
import Style from "./Style.module.css";
import { Grid } from "@mui/material";
import helpCover from "../../../assets/cover-donation-type.png";
import { useEffect } from "react";
//work
function OfferingBody() {
  const [offerType, setOfferType] = useState("Offering");
  const offerPrice = "one";
  const [price, setPrice] = useState();
  const offer = "";
  let position = React.useRef();

  const data = {
    Offering: {
      //works
      one: {
        priceId: "https://buy.stripe.com/8wM3dYdFJ7W6e1G14e",
        price: "Voluntary seed, as god blesses you.",
      },
      week: {
        priceId: "https://buy.stripe.com/8wM3dYdFJ7W6e1G14e",
        price: "Only One Time Offering",
      },
      month: {
        priceId: "https://buy.stripe.com/8wM3dYdFJ7W6e1G14e",
        price: "Only One Time Offering",
      },
    },
    //works
    Tithing: {
      one: {
        priceId: "https://buy.stripe.com/8wM01MdFJekucXC009",
        price: "10% of income, as god blesses you.",
      },
      week: {
        priceId: "https://buy.stripe.com/8wM01MdFJekucXC009",
        price: "Only One Time Tihing",
      },
      month: {
        priceId: "https://buy.stripe.com/8wM01MdFJekucXC009",
        price: "Only One Time Tihing",
      },
    },
    Charitable: {
      //works
      one: {
        priceId: "https://buy.stripe.com/9AQcOyeJN5NY3n228b",
        price: "Help the needy, as god blesses you.",
      },
      week: {
        priceId: "https://buy.stripe.com/4gwbKueJNb8i4r6eUY",
        price: "20$",
      },
      month: {
        priceId: "https://buy.stripe.com/fZe29UdFJeku4r68wB",
        price: "100$",
      },
    },
    Generous: {
      //works
      one: {
        priceId: "https://buy.stripe.com/eVa01M1X13FQ6zefZ4",
        price: "Demonstrate generosity, as god blesses you.",
      },
      week: {
        priceId: "https://buy.stripe.com/fZeeWG59d90a5va8wD",
        price: "100$",
      },
      month: {
        priceId: "https://buy.stripe.com/cN2bKu7hlekuf5K9AI",
        price: "250$",
      },
    },
  };

  const onUpdateOffer = (value) => {
    position.current.scrollIntoView({ behavior: "smooth" });
    setOfferType(value);
  };

  const updateGiving = () => {
    setPrice(data[offerType][offerPrice].price);
  };

  const handleSubmit = async () => {
    if (offerType === "Offering") {
      window.open(
        "https://buy.stripe.com/6oEaGq0SXccm7DicMX",
        "_blank",
        "noopener noreferrer"
      );
    } else if (offerType === "Charitable") {
      window.open(
        "https://buy.stripe.com/dR67ueeJNfoy3n28wI",
        "_blank",
        "noopener noreferrer"
      );
    } else if (offerType === "Generous") {
      window.open(
        "https://buy.stripe.com/6oEdSC0SX2BM5va3cp",
        "_blank",
        "noopener noreferrer"
      );
    } else if (offerType === "Tithing") {
      window.open(
        "https://buy.stripe.com/8wM01MdFJekucXC009",
        "_blank",
        "noopener noreferrer"
      );
    } else {
      window.location.replace("/offering");
    }
  };

  useEffect(() => {
    updateGiving();
  }, [offerType]);

  return (
    <div className={Style.body}>
      <div style={{}}>
        <div className={Style.title_container}>
          <div className={Style.goldLine}></div>
          <div className={Style.gold_container}>
            <p className={Style.textGold}>
              Do you know you can be God´s outreched hand to help your fellow
              human beings with your donation ?
            </p>
            <p className={Style.descriptionGold}>
              Jesus said: "It is more blessed to give than to receive" (Acts
              20:35). When you give you become God´s tool in helping lift up
              those children and families who have no shelter, nothing to eat or
              clothe. By doing so, you help demonstrate God´s love in a more
              practical way to your neighbor. Thus, you become a channel through
              which God´s blessings flow to those who desperately need help and
              you will never run dry in life. You are fulfilling the law of Love
              and activating God´s endless blessings.
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              // flexDirection: { md: "row", xs: "column" },
              justifyContent: { xs: "center", sm: "center", md: "flex-start" },
              alignItems: "center",
              width: "88vw",
            }}
            container
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <OneCard
                img="https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/offering.svg"
                url={"Offering"}
                handleClick={onUpdateOffer}
                clicked={offer}
                id={Style["firstCard"]}
              >
                Your offering is a voluntary seed that Helps spread the gospel
                of the Lord Jesus Christ, the Gospel of love, hope, and faith to
                the nations of the world. It is an overflow of a generous and
                grateful heart to God.
              </OneCard>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <OneCard
                img="https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/tithing.svg"
                url={"Tithing"}
                handleClick={onUpdateOffer}
                clicked={offer}
              >
                Tithing is 10% of every income that God blesses us with. It is
                our duty therefore, to honor the Lord and return it to the Lord.
                By this, we help in the maintenance of the Service of God and
                provide means for sustainability of the church missions as well
                as catering for the bills of water, electricity, and other
                administrative responsibilities.
              </OneCard>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <OneCard
                img="https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/cover-donation-type.png"
                url={"Charitable"}
                handleClick={onUpdateOffer}
                clicked={offer}
              >
                Doing charity is God´s call to every one of us to demonstrate
                our love for the suffering and the most vulnerable groups in
                society with the blessings God has entrusted us. Your donation
                will help us take care of orphans, feed the hungry, shelter the
                homeless and clothe the needy in the most troubled parts of
                Africa and the world.
              </OneCard>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <OneCard
                img="https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/generous.svg"
                url={"Generous"}
                handleClick={onUpdateOffer}
                clicked={offer}
                id={Style["forthCard"]}
              >
                Your generous giving is a free and willing seed that helps us
                fund the outreach missions and the means through which the good
                news of the Kingdom of God will be spread and win souls for
                Christ.
              </OneCard>
            </Grid>
          </Grid>
        </div>
        <div className={Style.help} ref={position}>
          <div className={Style.help_container}>
            <div
              className={Style.helpImg}
              style={{
                backgroundImage: `url(${helpCover})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className={Style.helpContent}>
                <div className={Style.goldBar}></div>
                <div className={Style.helpContainer_text}>
                  <p className={Style.helpTitle}>How can you help?</p>
                  <p className={Style.helpDescription}>
                    Thank you for visiting; we are a non-denominational church
                    that strives to serve the LORD in our community and nations
                    through our members and visitors. Online giving is safe,
                    convenient, and profoundly appreciated as they help us
                    spread the gospel worldwide on tv, the web, and streaming
                    technologies. Moreover, your donations are indispensable in
                    helping the most impoverished communities worldwide with
                    food, shelter, and education.
                  </p>
                </div>
              </div>
            </div>
            <div className={Style.helpCard}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3vh",
                  }}
                >
                  <div className={Style.helpCard_container}>
                    <div className={Style.helpCard_title}>
                      <p className={Style.giving}>
                        Once you choose a type of Giving, Click on "Donate"
                      </p>
                      <div className={Style.vGold}></div>
                    </div>
                    {/* <div className={Style.buttons_container}>
                      <button
                        id={Style["oneTimeButton"]}
                        className={Style.button_text}
                        onClick={() => {
                          onUpdatePrice("one");
                        }}
                      >
                        One Time
                      </button>

                      <button
                        id={Style["WeeklyButton"]}
                        className={Style.button_text}
                        onClick={() => {
                          onUpdatePrice("week");
                        }}
                      >Weekly
                      </button>
                      <button
                        id={Style["monthlyButton"]}
                        className={Style.button_text}
                        onClick={() => {
                          onUpdatePrice("month");
                        }}
                      >
                        Monthly
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className={Style.helpCard_checkOut}>
                  <div className={Style.helpCard_checkOut_text}>
                    <p className={Style.offer}>{offerType + ": " + price}</p>
                  </div>
                  <div className={Style.checkout}>
                    <button
                      id={Style["checkoutButton"]}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Donate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferingBody;
