import axios from "axios";
import React, { useEffect, useState } from "react";
import Card from "./Card";
import Style from "./Style.module.css";
import { Grid } from "@mui/material";

function Locations() {
  const [locations, setLocations] = useState([]);

  async function getData() {
    try {
      const res = await axios.get("/api/locations");
      setLocations(res.data.data);
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {/* title */}
      <div className={Style.title}>
        <p>My Locations</p>
        <img
          alt="gold horizontal line"
          src={require("../../../assets/Rectangle 66.png")}
        />
      </div>

      {/* name */}
      <p className={Style.onorio}>
        {" "}
        Nations for Christ Ministral Church : Find the nearest church branch in
        your city or country.{" "}
      </p>

      <Grid
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          justifyContent: { xs: "center", sm: "center", md: "flex-start" },
          alignItems: "center",
          width: "88vw",
          marginLeft: "5vw",
        }}
        container
      >
        {locations &&
          locations.map((location) => {
            return (
              <Grid
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                item
                sx={{
                  alignSelf: "center",
                }}
                key={location._id}
              >
                <Card
                  title={location.church_name}
                  address={location.adress}
                  contact={location.main_contact}
                  photo={
                    "https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/cover%20church%20(1).png"
                  }
                  email={location.email}
                  key={location._id}
                />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}

export default Locations;
