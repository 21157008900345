import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCommunity } from "../js/actions/communityActions";
import { clearErrors } from "../js/actions/userActions";

function useNotification() {
  const dispatch = useDispatch();
  const [notif, setNotif] = useState(null);
  const error = useSelector((state) => state.userReducer.error);

  const failCommunity = useSelector(
    (state) => state.communityReducer.failCommunity
  );

  const addCommunity = useSelector(
    (state) => state.communityReducer.addCommunity
  );

  useEffect(() => {
    if (error && error.response) {
      setNotif(getError());
      setTimeout(() => {
        dispatch(clearErrors());
        setNotif(null);
      }, 5000);
    }
  }, [error]);

  const getError = () => {
    switch (error.response.status) {
      case 400:
        return {
          message: "Incorrect email or password, please try again.",
          type: "error",
        };
      case 401:
        return { message: "Unauthorized", type: "error" };
      case 409:
        return {
          message:
            "This email already exists, please try again with a different email.",
          type: "error",
        };
      default:
        return {
          message: "Oops, there is a problem, please try again later.",
          type: "error",
        };
    }
  };

  const communityNotif = () => {
    if (addCommunity) {
      setNotif({
        message: "Your post has been sent and will be published soon",
        type: "success",
      });
    } else if (failCommunity) {
      setNotif({
        message: "We couldn't publish your post right now, please try again later",
        type: "warning",
      });
    }
    setTimeout(() => {
      dispatch(clearCommunity());
      setNotif(null);
    }, 6000);
  };

  useEffect(() => {
    communityNotif();
  }, [addCommunity, failCommunity]);

  return {
    notif,
  };
}

export default useNotification;
