import {
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
} from "@mui/material";

import React from "react";
import { styled } from "@mui/material/styles";
import policiesIcon from "../../assets/icons/policies-icon.png";
const Policies = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grid>
        <Box sx={{ display: { sm: "block", xs: "none" } }}>
          <img
            style={{ width: "100%", height: "auto" }}
            alt="onorio"
            src="https://storage.googleapis.com/public_ocm/cover-policies.png"
          />
        </Box>
        <Box sx={{ display: { sm: "none", xs: "block" } }}>
          <img
            style={{ width: "100%", height: "15vh" }}
            alt="onorio"
            src="https://storage.googleapis.com/public_ocm/cover-policies.png"
          />
        </Box>
        <Column
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Column sx={{ width: "80vw" }}>
            <Row sx={{ justifyContent: "flex-start", alignItems: "center" }}>
              <img
                src={policiesIcon}
                alt="ocm policy"
                style={{ height: "50px", marginRight: "30px" }}
              />
              <HeaderText>Privacy Policy</HeaderText>
            </Row>
            <Divider />
            <Title>Introduction</Title>
            <Text>
              Please read these terms of use ("terms of use", "Agreement")
              carefully before using the onoriocutane.org website (“website”,
              "service") operated by Nations for Christ Ministerial Church and
              Onório Cutane Ministries ("us", 'we", "our"). We reserve the right
              to change this policy at any given time, of which you will be
              promptly updated. If you want to make sure that you are up to date
              with the latest changes, we advise you to frequently visit this
              page.
            </Text>
            <Title>1. Conditions of use</Title>

            <Text>
              By using this website, you certify that you have read and reviewed
              this Agreement and that you agree to comply with its terms. If you
              do not want to be bound by the terms of this Agreement, you are
              advised to leave the website accordingly. Nations for Christ
              Ministerial Church only grants use and access to this website, its
              products, and its services to those who have accepted its terms.
            </Text>
            <Title>2. Privacy policy</Title>
            <Text>
              Before you continue using our website, we advise you to read our
              privacy policy onoriocutane.org/privacy regarding our user data
              collection. It will help you better understand our practices.
            </Text>

            <Title>3. Age restriction</Title>
            <Text>
              You must be at least 18 (eighteen) years of age before you can use
              this website. By using this website, you warrant that you are at
              least 18 years of age, and you may legally adhere to this
              Agreement. Nations for Christ Ministerial Church assumes no
              responsibility for liabilities related to age misrepresentation.
            </Text>
            <Title>4. Intellectual property</Title>
            <Text>
              You agree that all materials, products, and services provided on
              this website are the property of The Nations for Christ
              Ministerial Church its affiliates, directors, officers, employees,
              agents, suppliers, or licensors including all copyrights, trade
              secrets, trademarks, patents, and other intellectual property. You
              also agree that you will not reproduce or redistribute the
              [name]’s intellectual property in any way, including electronic,
              digital, or new trademark registrations. You grant Nations for
              Christ Ministerial Church a royalty-free and non-exclusive license
              to display, use, copy, transmit, and broadcast the content you
              upload and publish. For issues regarding intellectual property
              claims, you should contact the company in order to come to an
              agreement.
            </Text>
            <Title>5. User accounts</Title>
            <Text>
              As a user of this website, you may be asked to register with us
              and provide private information. You are responsible for ensuring
              the accuracy of this information, and you are responsible for
              maintaining the safety and security of your identifying
              information. You are also responsible for all activities that
              occur under your account or password. If you think there are any
              possible issues regarding the security of your account on the
              website, inform us immediately so we may address it accordingly.
              We reserve all rights to terminate accounts, edit or remove
              content and cancel orders at their sole discretion.
            </Text>
            <Title>6. Applicable law</Title>
            <Text>
              By visiting this website, you agree that the laws of the State of
              Delaware without regard to principles of conflict laws will govern
              these terms and conditions, or any dispute of any sort that might
              come between Nations for Christ Ministerial Church and you, or its
              business partners and associates.
            </Text>
            <Title>7. Disputes</Title>
            <Text>
              Any dispute relating in any way to your visit to this website or
              to products you purchase from us shall be arbitrated by a state or
              federal court in Delaware and you consent to exclusive
              jurisdiction and venue of such courts. Indemnification You agree
              to indemnify Nations for Christ Ministerial Church and its
              affiliates and hold Nations for Christ Ministerial Church harmless
              against legal claims and demands that may arise from your use or
              misuse of our services. We reserve the right to select our own
              legal counsel.
            </Text>
            <Title>8. Limitation on liability</Title>
            <Text>
              The Nations for Christ Ministerial Church is not liable for any
              damages that may occur to you as a result of your misuse of our
              website. Nations for Christ Ministerial Church reserves the right
              to edit, modify, and change this Agreement at any time. We shall
              let our users know of these changes through electronic mail. This
              Agreement is an understanding between Nations for Christ
              Ministerial Church and the user, and this supersedes and replaces
              all prior agreements regarding the use of this website.
            </Text>
            <Title>9. Changes to Terms</Title>
            <Text>
              The Nations for Christ Ministerial Church reserves the right, in
              its sole discretion, to change the terms under which
              onoriocutane.org is offered. The most current version of the Terms
              will supersede all previous versions. The Nations for Christ
              Ministerial Church encourages you to periodically review the Terms
              to stay informed of our updates.
            </Text>
            <Title>10. User Data We Collect</Title>
            <Text>
              When you visit the website, we may collect the following data:
              <ul>
                <li>Your IP address.</li>
                <li>Your contact information and email address.</li>
                <li>Other information such as interests and preferences.</li>
                <li>
                  Data profile regarding your online behavior on our website.
                </li>
              </ul>
            </Text>
            <Title>11. User Data We Collect</Title>
            <Text>
              We are collecting your data for several reasons:
              <ul>
                <li>To better understand your needs.</li>
                <li>To improve our services and products.</li>
                <li>
                  To send you promotional emails containing the information we
                  think you will find interesting.
                </li>
                <li>
                  To contact you to fill out surveys and participate in other
                  types of market research.
                </li>
                <li>
                  To customize our website according to your online behavior and
                  personal preferences.
                </li>
              </ul>
            </Text>
            <Title>12. Safeguarding and Securing the Data</Title>
            <Text>
              OCM is committed to securing your data and keeping it
              confidential. OCM has done all in its power to prevent data theft,
              unauthorized access, and disclosure by implementing the latest
              technologies and software, which help us safeguard all the
              information we collect online.
            </Text>
            <Title>13. Our Cookie Policy</Title>
            <Text>
              Once you agree to allow our website to use cookies, you also agree
              to use the data it collects regarding your online behavior
              (analyze web traffic, web pages you spend the most time on, and
              websites you visit). The data we collect by using cookies is used
              to customize our website to your needs. After we use the data for
              statistical analysis, the data is completely removed from our
              systems. Please note that cookies don't allow us to gain control
              of your computer in any way. They are strictly used to monitor
              which pages you find useful and which you do not so that we can
              provide a better experience for you. If you want to disable
              cookies, you can do it by accessing the settings of your internet
              browser. You can visit www.onoriocutane.org/cookies, which
              contains comprehensive information on how to do this on a wide
              variety of browsers and devices.
            </Text>
            <Title>14. Links to Other Websites</Title>
            <Text>
              Our website contains links that lead to other websites. If you
              click on these links onoriocutane.org is not held responsible for
              your data and privacy protection. Visiting those websites is not
              governed by this privacy policy agreement. Make sure to read the
              privacy policy documentation of the website you go to from our
              website.
            </Text>
            <Title>15. Restricting the Collection of your Personal Data</Title>
            <Text>
              At some point, you might wish to restrict the use and collection
              of your personal data. You can achieve this by doing the
              following: When you are filling out the forms on the website, make
              sure to check if there is a box that you can leave unchecked, if
              you don't want to disclose your personal information. If you have
              already agreed to share your information with us, feel free to
              contact us via email and we will be more than happy to change this
              for you. The Nations for Christ Ministerial Church Inc. will not
              lease, sell or distribute your personal information to any third
              parties unless we have your permission. We might do so if the law
              forces us. Your personal information will be used when we need to
              send you promotional materials if you agree to this privacy
              policy.
            </Text>
            <Title>16. Contact Us</Title>
            <Text>
              The Nations for Christ Ministerial Church welcomes your questions
              or comments regarding the terms:
            </Text>
            <Text>1011 Centre Road</Text>
            <Text>Wilmington, DE 19805</Text>
            <Text>United States of America</Text>
            <Text>admin@onoriocutane.org</Text>
          </Column>
        </Column>
      </Grid>
    </ThemeProvider>
  );
};

export default Policies;

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
});

const Text = styled(Typography)({
  color: "#000000",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "30px",
  textAlign: "left",
  padding: "0 1%",
});
const Title = styled(Typography)({
  color: "#000000",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "27px",
  textAlign: "left",
  textTransform: "uppercase",
  padding: "2% 0",
});
const HeaderText = styled(Typography)({
  color: "#000000",
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "36px",
  textAlign: "left",
  padding: "2% 0",
});
const Row = styled(Box)({
  display: "flex",
  flexDirection: "row",
});
const Column = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const Divider = styled(Box)({
  backgroundColor: "#000000",
  height: "1px",
  width: "80vw",
});
