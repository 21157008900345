import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box, Card } from "@mui/material";
const GoalCard = ({ content, title, iconSource }) => {
  return (
    <Column
      item
      xl={4}
      lg={4}
      md={12}
      sm={12}
      xs={12}
      sx={
        {
          //marginLeft: { md: "-40px", xs: 0 },
          //width: { md: "50vw", xs: "90vw" },
        }
      }
    >
      <Column sx={{ alignItems: "center" }}>
        <Box
          sx={{
            alignSelf: "flex-end",
            marginBottom: { sm: "-50px", xs: "-50px" },
            marginRight: "20px",
            zIndex: 1,
            display: { md: "none", xs: "block" },
          }}
        >
          <img
            src={iconSource}
            style={{
              height: "50px",
            }}
            alt="OCM"
          />
        </Box>
        <Box
          sx={{
            alignSelf: "flex-end",
            marginBottom: { sm: "-50px", xs: "-50px" },
            marginRight: "20px",
            zIndex: 1,
            display: { md: "block", xs: "none" },
          }}
        >
          <img
            src={iconSource}
            style={{
              height: "60px",
            }}
            alt="OCM"
          />
        </Box>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 1,
            margin: { md: "2vh 2vh", xs: "2vh 2vh" },
            padding: { md: "6%", sm: "8%", xs: "6%" },
            height: {
              xl: "22vh",
              lg: "25vh",
              md: "26vh",
              sm: "auto",
              xs: "auto",
            },
          }}
        >
          <TitleText sx={{ fontSize: { lg: "14px", xs: "12px" } }}>
            {title}
          </TitleText>
          <ContentText
            sx={{
              fontSize: { lg: "12px", xs: "12px" },
              lineHeight: { lg: "22px", md: "22px", xs: "22px" },
            }}
          >
            {content}
          </ContentText>
        </Card>
      </Column>
    </Column>
  );
};

export default GoalCard;
const Column = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});

const TitleText = styled(Typography)({
  color: "#B78400",
  fontWeight: "500",
  fontSize: "14px",
  marginBottom: "10px",
  textAlign: "left",
});
const ContentText = styled(Typography)({
  color: "#666666",
  fontWeight: "500",
  fontSize: "12px",
  textAlign: "left",
});
