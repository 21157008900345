import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
  Button,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import ocmLogo from "../../assets/icons/ocm-logo.svg";
import ocmLogoBlack from "../../assets/icons/ocm-logo-black.svg";
const formSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Invalid email")
    .required("Required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password must be at least 8 charcters")
    .required("Required"),
  firstname: yup.string().required("Required"),
  lastname: yup.string().required("Required"),
  birth_date: yup
    .date()
    .min(new Date("1910-01-01"), "Birth date must be lower than 1909")
    .max(new Date("2004-01-01"), "Birth date must be lower than 2004")
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});
const SignupScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.slice(
    location.pathname.indexOf("/signup/") + 8
  );
  useEffect(() => {
    if (path.length > 1) {
      const id = path.substring(0, path.indexOf("$"));
      const getUser = async () => {
        try {
          await axios.get("/socialuser/" + id);
        } catch (error) {
          //console.log(error);
        }
      };
      getUser();
    } else {
      //console.log("normal signup");
    }
  }, []);

  const handleOnSubmit = (values) => {
    navigate("/nextSignup", { state: { ...values } });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      birth_date: new Date("1980-01-01"),
      confirmPassword: "",
    },
    validationSchema: formSchema,
    //enableReinitialize: true,

    onSubmit: handleOnSubmit,
  });
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Grid sx={{ display: "flex", flexDirection: "row" }}>
          <Grid
            sx={{
              backgroundImage: `url(https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/generous.svg)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              //height: { md: "94vh", xs: "100vh" },
              flexDirection: "column",
              justifyContent: "space-between",
              //alignItems: "center",
              padding: "5vh",
              width: "50vw",
              display: { md: "flex", xs: "none" },
            }}
          >
            <Grid
              //item
              //xs={1}
              sx={{ alignSelf: "flex-start" }}
            >
              <img src={ocmLogo} alt="ocm-logo" />
            </Grid>
            {/* Welcome */}
            <Grid
              //container
              //item
              //xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                //marginLeft: "5vw",
                alignSelf: "center",
                //alignSelf: "center",
                //backgroundColor: "blue",
                //alignItems: "flex-end",
                //justifyContent: "flex-end",
              }}
            >
              <Text
                sx={{
                  width: "35vw",
                  marginBottom: "3vh",
                  lineHeight: "26px",
                  fontSize: "20px",
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                Welcome Back !
              </Text>
              <Text
                sx={{ width: "35vw", marginBottom: "3vh", textAlign: "center" }}
              >
                Nations for Christ.
                <br />
                Reaching the nations of the world with the gospel of love, peace
                and hope. Demonstrating the love and the power of God to
                humanity.
              </Text>
            </Grid>
            <Grid
              //item
              //xs={5}
              style={{ flexDirection: "row" }}
              sx={{
                display: "flex",
                alignSelf: "flex-end",
                //alignItems: "flex-end",
                //justifyContent: "flex-end",
                //width: "90vw",
                //backgroundColor: "red",
              }}
            >
              <Text>Already have an account ?&nbsp;</Text>
              <Link to="/login">
                <Grid style={{ flexDirection: "row" }} sx={{ display: "flex" }}>
                  <Text sx={{ textDecoration: "underline", color: "#926900" }}>
                    Log in&nbsp;
                  </Text>
                  <Text sx={{ textDecoration: "underline" }}>here</Text>
                </Grid>
              </Link>
            </Grid>
          </Grid>
          <Grid
            sx={{
              padding: "5vh",
              alignItems: "center",
              //justifyContent: "center",
              backgroundColor: "#F5F5F5",
              height: { md: "100vh", xs: "100%" },
              width: { md: "50vw", xs: "100vw" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* back to home */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { md: "row-reverse", xs: "row" },
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  //width: "10%",
                  alignSelf: "flex-start",
                  display: { md: "none", xs: "flex" },
                }}
              >
                <img
                  src={ocmLogoBlack}
                  alt="ocm-logo"
                  // height={"100%"}
                  width={"50%"}
                />
              </Grid>
              <Link to="/">
                <Box
                  style={{ flexDirection: "row" }}
                  sx={{
                    display: "flex",
                    alignSelf: "flex-end",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowBackIosIcon sx={{ color: "black", fontSize: 15 }} />
                  <Text
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "16px",
                      color: "black",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    Back To Home
                  </Text>
                </Box>
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "5vh",
              }}
            >
              <Text
                sx={{
                  fontSize: { md: "20px", xs: "15px" },
                  lineHeight: "30px",
                  background:
                    "linear-gradient(95.52deg, #00454b 8.71%, #b78400 100%)",
                  marginBottom: "2vh",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Sign up
              </Text>
              <Text
                sx={{
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#1C3D47",
                }}
              >
                Create your account and Became a member of the Nations
              </Text>
              <Text
                sx={{
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#1C3D47",
                }}
              >
                For Christ Ministerial Church
              </Text>
            </Box>
            {/* form */}
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: { md: "row", xs: "column" },
                    alignItems: { md: "flex-start", xs: "center" },
                    marginTop: "5vh",
                  }}
                >
                  <Grid sx={{ marginRight: { md: "2vw", xs: "0vw" } }}>
                    <Text sx={{ color: "#000210" }}>First Name</Text>
                    <StyledTextField
                      id="firstname"
                      name="firstname"
                      margin="dense"
                      placeholder="Enter Your First Name"
                      type={"text"}
                      hiddenLabel
                      variant="outlined"
                      size="normal"
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "2vh",
                      }}
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstname &&
                        Boolean(formik.errors.firstname)
                      }
                      helperText={
                        formik.touched.firstname && formik.errors.firstname
                      }
                    />
                    <Text sx={{ color: "#000210" }}>Last Name</Text>
                    <StyledTextField
                      id="lastname"
                      name="lastname"
                      margin="dense"
                      placeholder="Enter Your Last Name"
                      type={"text"}
                      hiddenLabel
                      variant="outlined"
                      size="normal"
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "2vh",
                      }}
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                    />

                    <Text sx={{ color: "#000210" }}>Birth Date</Text>
                    <StyledTextField
                      id="birth_date"
                      name="birth_date"
                      margin="dense"
                      placeholder="Enter Your Birth Date"
                      type={"date"}
                      hiddenLabel
                      variant="outlined"
                      size="normal"
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "2vh",
                        color: "black",
                      }}
                      defaultValue={new Date("1980-01-01")}
                      value={formik.values.birth_date}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.birth_date &&
                        Boolean(formik.errors.birth_date)
                      }
                      helperText={
                        formik.touched.birth_date && formik.errors.birth_date
                      }
                    />
                  </Grid>
                  <Grid sx={{ marginLeft: { md: "2vw", xs: "0vw" } }}>
                    <Text sx={{ color: "#000210" }}>Email</Text>
                    <StyledTextField
                      id="email"
                      name="email"
                      margin="dense"
                      placeholder="Enter Your Email"
                      type={"email"}
                      hiddenLabel
                      variant="outlined"
                      size="normal"
                      sx={{
                        width: { lg: "15vw", md: "18vw", xs: "70vw" },
                        marginBottom: "2vh",
                      }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <Text sx={{ color: "#000210" }}>Password</Text>
                    <StyledTextField
                      id="password"
                      name="password"
                      margin="dense"
                      placeholder="Enter Your Password"
                      type="password"
                      hiddenLabel
                      variant="outlined"
                      size="normal"
                      sx={{
                        width: {
                          lg: "15vw",
                          md: "18vw",
                          xs: "70vw",
                          marginBottom: "2vh",
                        },
                      }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                    <Text sx={{ color: "#000210" }}>Confirm Password</Text>
                    <StyledTextField
                      id="confirmPassword"
                      name="confirmPassword"
                      margin="dense"
                      placeholder="Enter Your Password"
                      type="password"
                      hiddenLabel
                      variant="outlined"
                      size="normal"
                      sx={{ width: { lg: "15vw", md: "18vw", xs: "70vw" } }}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                  </Grid>
                </Grid>

                {/* next button */}

                <ConfirmButton
                  type="submit"
                  sx={{
                    width: { md: "20vw", xs: "50vw" },
                    marginTop: "5vh",
                    alignSelf: "center",
                  }}
                >
                  Next
                  <ArrowForwardIosIcon sx={{ color: "white", fontSize: 15 }} />
                </ConfirmButton>
              </Box>
            </form>
            <Grid
              sx={{
                display: { md: "none", xs: "flex" },
                flexDirection: "row",
                alignSelf: "center",
                marginTop: "5vh",
              }}
            >
              <Text sx={{ color: "black" }}>
                Already have an account ?&nbsp;
              </Text>
              <Link to="/login">
                <Grid style={{ flexDirection: "row" }} sx={{ display: "flex" }}>
                  <Text
                    sx={{
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    Login here
                  </Text>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default SignupScreen;

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "left",
});
const StyledTextField = styled(TextField)({
  //backgroundColor: "#fff",
  fontSize: "12px",
  input: {
    fontSize: "12px",
    backgroundColor: "#fff",
  },
  "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
});
const ConfirmButton = styled(Button)({
  background: "linear-gradient(92.1deg, #293b56 34.28%, #694c00 101.52%)",
  borderRadius: "8px",
  color: "white",
  textTransform: "capitalize",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "#969696",
  },
});
