import React from "react";
import Header from './header/Header';
import Body from './body/Body';

function profile() {
  
  return (
    <div>
      <Header />
      <Body />
    </div>
  );
}

export default profile;
